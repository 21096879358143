import React, { useState, useEffect } from "react";
// import { convertFromHTML, convertFromRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  ContentState,
  convertToRaw,
  // htmlToDraft,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { useHistory } from "react-router-dom";
import "./styles/style.css";
import {
  Button,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Text,
  makeStyles,
  shorthands,
  typographyStyles,
  Label,
  Switch,
  Tab,
  TabList,
  Spinner,
  Field,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import { Icon } from "@fluentui/react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import ThemeSelector from "./ThemeSelector.js";

import ExampleFormFirstPage from "./ExampleFormFirstPage.js";
import ExampleFormThirdPage from "./ExampleFormThirdPage.js";

import ExampleFormSecondPage from "./ExampleFormSecondPage.js";
import { useTranslation } from "react-i18next";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../authConfig.js";
import ManagementQrCode from "./ManagementQrCode.js"
import {
  Toaster,
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
  Divider,
} from "@fluentui/react-components";
// import NotSubscribed from "./NotSubscribed.js";
const API_URL_FORM = process.env.REACT_APP_REQUEST_FORM_API_URL;
const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("20px"),
    maxWidth: "400px",
    "> div": {
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      // need padding to see the background color for filled variants
      ...shorthands.padding("5px", "20px", "10px"),
    },
  },
  title: { ...typographyStyles.subtitle1, textAlign: "center" },
  languagechange: typographyStyles.subtitle2,

  tabTheme: {
    display: "flex",
    flexDirection: "column",

    ...shorthands.padding("10px", "20px"),
    marginLeft: "-30px",
  },
});

const ManagementForm = () => {
  // const [savedData, setSavedData] = useState("default");
  // const [editorState, setEditorState] = useState(() => {
  //   const content = ContentState.createFromText(savedData);
  //   return EditorState.createWithContent(content);
  // });
  const localization = {
    locale: "en-us",
    translations: {
    "generic.add": "Adding",
    "generic.cancel": "Cancel",

    // BlockType
    "components.controls.blocktype.h1": "H1",
    "components.controls.blocktype.h2": "H2",
    "components.controls.blocktype.h3": "H3",
    "components.controls.blocktype.h4": "H4",
    "components.controls.blocktype.h5": "H5",
    "components.controls.blocktype.h6": "H6",
    "components.controls.blocktype.blockquote": "Blockquote",
    "components.controls.blocktype.code": "Código",
    "components.controls.blocktype.blocktype": "Bloque",
    "components.controls.blocktype.normal": "Normal",

    // Color Picker
    "components.controls.colorpicker.colorpicker": "Selector de color",
    "components.controls.colorpicker.text": "Texto",
    "components.controls.colorpicker.background": "Resaltar",

    // Embedded
    "components.controls.embedded.embedded": "Embedded",
    "components.controls.embedded.embeddedlink": "Enlace Embedded",
    "components.controls.embedded.enterlink": "Introduzca enlace",

    // Emoji
    "components.controls.emoji.emoji": "Emoji",

    // FontFamily
    "components.controls.fontfamily.fontfamily": "Fuente",

    // FontSize
    "components.controls.fontsize.fontsize": "Tamaño de fuente",

    // History
    "components.controls.history.history": "Historial",
    "components.controls.history.undo": "Deshacer",
    "components.controls.history.redo": "Rehacer",

    // Image
    "components.controls.image.image": "Imagen",
    "components.controls.image.fileUpload": "Subida de fichero",
    "components.controls.image.byURL": "URL",
    "components.controls.image.dropFileText":
      "Arrastre el archivo o haz clic para subir",

    // Inline
    "components.controls.inline.bold": "Negrita",
    "components.controls.inline.italic": "Cursiva",
    "components.controls.inline.underline": "Subrallado",
    "components.controls.inline.strikethrough": "Tachado",
    "components.controls.inline.monospace": "Mono espacio",
    "components.controls.inline.superscript": "Superscript",
    "components.controls.inline.subscript": "Subscript",

    // Link
    "components.controls.link.linkTitle": "Título del enlace",
    "components.controls.link.linkTarget": "Dirección del enlace",
    "components.controls.link.linkTargetOption":
      "Abre el enlace en una ventana nueva",
    "components.controls.link.link": "Enlazar",
    "components.controls.link.unlink": "Desenlazar",

    // List
    "components.controls.list.list": "Lista",
    "components.controls.list.unordered": "Bullet",
    "components.controls.list.ordered": "Numérica",
    "components.controls.list.indent": "Identar",
    "components.controls.list.outdent": "Desidentar",

    // Remove
    // "components.controls.remove.remove": "Eliminar",

    // TextAlign
    "components.controls.textalign.textalign": "Alinear texto",
    "components.controls.textalign.left": "Izquerda",
    "components.controls.textalign.center": "Centro",
    "components.controls.textalign.right": "Derecha",
    "components.controls.textalign.justify": "Justificado",
  },
};

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  // const [accountData, setAccountData] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [companyDetailData, setCompanyDetailData] = useState(null);
// const companyId = sessionStorage.getItem('companyId') || '';
  const history = useHistory();
  async function fetchCompanyData() {
    // console.log('userEffect retrieve company and accounts data!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log("tokenResponse: ", tokenResponse);

        axios
          .get(API_URL + "/etax/companies", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log("response /etax/companies", response.data);

              if (response.data.length === 0) {
              } else {
                let _cid = "";
                if (
                  sessionStorage.getItem("companyId") &&
                  response.data.find(
                    (_company) =>
                      _company.id === sessionStorage.getItem("companyId")
                  )
                ) {
                  const _company = response.data.find(
                    (_company) =>
                      _company.id === sessionStorage.getItem("companyId")
                  );
                  setCompanyData(_company);
                  console.log("companyData", companyData);
                  // console.log(_company)

                  // sessionStorage.setItem("companyId", response.data[0].id);
                  _cid = sessionStorage.getItem("companyId");
                } else {
                  const _company = response.data[0];

                  setCompanyData(_company);
                  sessionStorage.setItem("companyId", _company.id);

                  _cid = _company.id;
                }

                axios
                  // .get(API_URL + '/companies-details/' + response.data[0].id, {
                  .get(API_URL + "/companies-details", {
                    headers: {
                      Authorization: "Bearer " + tokenResponse.accessToken,
                      "cid": _cid,
                    },
                  })
                  .then((companiesDetailsResponse) => {
                    // console.log('companiesDetailsResponse: ', companiesDetailsResponse);
                    if (companiesDetailsResponse.data) {
                      setCompanyDetailData(companiesDetailsResponse.data);
                    }
                    // console.log("CompanyDetail", companiesDetailsResponse.data);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + "/accounts", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              // console.log(response);

              if (response.data) {
                // setAccountData(response.data);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  }

  useEffect(() => {
    fetchCompanyData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const companyId = sessionStorage.getItem("companyId") || "";
  const billingsTypeStorage = sessionStorage.getItem("billingsType") || "";

  useEffect(() => {
    console.log("billingsTypeStorage", billingsTypeStorage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [formId, setFormId] = useState(null);

  const getFormId = async () => {
    setIsLoadComplete(false);

    try {
      const response = await axios.get(`${API_URL_FORM}/forms/`, {
        headers: {
          "cid": `${companyId}`,
        },
      });

      console.log("formId", response.data);
      // setFormId(response.data); // Assuming the form ID is returned directly in the response data
      setSavedData(response.data.message);
      setFormId(response.data.id);
      
      if(formId !== null){
        setUrlID(response.data.urlName);
      } 
    
      if (isFormEnabled === null) {
        setIsFormEnabled(response.data.isFormEnabled);
      }
    } catch (error) {
      console.error("Error fetching form ID:", error);
      if (error.response.data.statusCode === 404) {
        console.log("CreatesFormsByCid");
        CreatesFormsByCid();
      }
    } finally {
      setIsLoadComplete(true);
    }
  };
  useEffect(() => {
    getFormId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CreatesFormsByCid = async () => {
    try {
      const response = await axios.post(`${API_URL_FORM}/forms/`, {
        headers: {
          "cid": `${companyId}`,
        },
      });

      console.log("response", response);
      if (response.data.statusCode === 200) {
        console.log("สร้าง", response.data.messsage);
        getFormId();
      }
    } catch (error) {
      console.error("Error fetching form ID:", error);
    } finally {
      setIsLoadComplete(true);
    }
  };

  // useEffect(() => {
  //   getFormId();
  // });

  // const formId = "bec1ef6c-b423-4ab2-a05d-27bdd9a5b277"; //account 1
  // const formId = "e5727180-e525-4b0b-ae0d-0b268f04c6a3"; //account 2
  // const formId = "615344a4-8e21-4f7f-ac70-8f9334f7308e"; //account 3
  // const formId = "daf4af60-dcdf-498a-9b9b-061c5965c684"; //account 4

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);

  const EditConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormAdminFormEditor.saveAlready")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );


  const CopySuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
           {t("requestFormAdminFormEditor.copyLinkAlready")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
             {t("requestFormAdminFormEditor.saveUnsucess")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );

  const styles = useStyles();

  const { t } = useTranslation();
  const [savedData, setSavedData] = useState(null);
  // const [billingsType, setBillingsType] = useState("prepaid");

  // const [editorState, setEditorState] = React.useState(() => {
  //   if (initialValue) {
  //     const blocksFromHTML = htmlToDraft(initialValue);
  //     const contentState = ContentState.createFromBlockArray(blocksFromHTML);
  //     return EditorState.createWithContent(contentState);
  //   }
  //   return EditorState.createEmpty();
  // });

  const myBlockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === "blockquote") {
      return "public-DraftStyleDefault";
    }
  };

  // class EditorWithFancyBlockquotes extends React.Component {
  //   render() {
  //     return <Editor blockStyleFn={myBlockStyleFn} />;
  //   }
  // }

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  // const [editorState, setEditorState] = React.useState(() => {
  //   if (savedData !== null) {
  //     const blocksFromHTML = htmlToDraft(savedData.previewText);
  //     const contentState = ContentState.createFromBlockArray(blocksFromHTML);
  //     return EditorState.createWithContent(contentState);
  //   }
  //   return EditorState.createEmpty();
  // });

  const [contentState, setContentState] = useState(null);
  const [editorStateSecend, setEditorStateSecend] = useState(
    EditorState.createEmpty()
  );
  const [contentStateSecend, setContentStateSecend] = useState(null);

  const [editorStateThird, setEditorStateThird] = useState(
    EditorState.createEmpty()
  );
  const [contentStateThird, setContentStateThird] = useState(null);

  // const [firstRender, setFirstRender] = useState(true);

  // const setLikeDatabase = () => {
  //   setContentState(savedData.previewText);
  //   console.log("editorState on Focus", editorState);
  //   console.log("contentState on Focus", contentState);
  // };

  const handleContentStateChange = (contentState) => {
    setContentState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  // useEffect(() => {
  //   console.log("editorState  from useEFFECT", editorState);
  //   console.log("contentState  from useEFFECT", contentState);
  // }, [editorState, contentState]);

  // useEffect(() => {
  //   console.log("firstRender !!", firstRender);
  // }, [firstRender]);

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
    // let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    // setContentState(
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );

    // let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    // setEditorState(currentContentAsHTML);
    // setContentState(currentContentAsHTML);

    // console.log(
    //   "draft-js: EditorHandleState",
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // );
    // console.log("editorState", editorState);
  };

  // onEditorStateChange: Function = (editorState) => {
  //   this.setState({
  //     editorState,
  //   });
  // };

  // const handleContentStateChangeSecend = (contentState) => {
  //   if (contentState.blocks) {
  //     setContentStateSecend(draftToHtml(contentState));
  //   }
  // };

  const handleEditorStateChangeSecend = (editorState) => {
    setEditorStateSecend(editorState);
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContentStateSecend(value);
  };

  // const [convertedContent, setConvertedContent] = useState(null);
  // const convertContentToHTML = () => {
  //   let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   setConvertedContent(currentContentAsHTML);
  // };

  // useEffect(() => {
  //   console.log("convertedContent", convertedContent);
  // }, [convertedContent]);

  // const handleContentStateChangeThird = (contentState) => {
  //   if (contentState.blocks) {
  //     setContentStateThird(draftToHtml(contentState));
  //   }
  // };

  const handleEditorStateChangeThird = (editorState) => {
    setEditorStateThird(editorState);
    let value = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setContentStateThird(value);
  };

  const [isLoadComplete, setIsLoadComplete] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.log("account data ข้อมูลปัจจุบัน", companyDetailData);

    if (companyDetailData && companyDetailData.billings) {
      switch (companyDetailData.billings.type) {
        case "subscription":
          setCurrentCustom("contactList");
          break;
        case "prepaid":
          // setCurrentCustom("notSubscribe");
          history.push({
            pathname: `/request-forms/not-subscribed`,
          });
          break;
        default:
          // Handle other cases if necessary
          // setBillingsType("prepaid");
          history.push({
            pathname: `/request-forms/not-subscribed`,
          });
          // setCurrentCustom("notSubscribe");
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetailData]);

  const [currentCustom, setCurrentCustom] = useState("contactList");
  const renderTabs = () => {
    return (
      <>
         <Tab
          value="tab1"
          onClick={() => setCurrentCustom("contactList")}
          disabled={currentCustom === "notSubscribe" || !isLoadComplete}
        >
          {" "}
          <Text
            style={{ color: "black" }}
            size={400}
            disabled={currentCustom === "notSubscribe"}
          >
            {t("requestFormAdminFormEditor.printQrCode")}
          </Text>
        </Tab>
        <Tab
          value="tab2"
          onClick={() => setCurrentCustom("pickTheme")}
          disabled={currentCustom === "notSubscribe" || !isLoadComplete}
        >
          {" "}
          <Text style={{ color: "black" }} size={400}>
            {t("requestFormAdminFormEditor.selectTheme")}
          </Text>
        </Tab>
          <Tab
          value="tab3"
          onClick={() => setCurrentCustom("addTextForm")}
          disabled={currentCustom === "notSubscribe" || !isLoadComplete}

        >
          <Text style={{ color: "black" }} size={400}>
            {t("requestFormAdminFormEditor.addingTextToForm")}
          </Text>
        </Tab>
       
      </>
    );
  };
  const [urlID, setUrlID] = useState("");
  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       // const response = await axios.get(
  //       //   `http://localhost:5004/api/forms/${formId}`
  //       // );

  //       const response = await axios.get(`${API_URL}/forms/${formId}`);
  //       console.log("API_URL", API_URL);
  //       console.log("เทส azure api", response);
  //       setUrlID(response.data.urlName);
  //     } catch (error) {
  //       console.error("Error fetching data:", error.message);
  //     }
  //   }

  //   fetchData();
  // }, []);

  const handleSaveData = async () => {
    console.log("contentState from handle Save data", contentState);

    try {
      const response = await axios.patch(`${API_URL_FORM}/forms/${formId}`, {
        // Ensure the request payload is structured correctly

        "replace": "/message",
        "value": {
          "previewText": contentState,

          "previewTextSecond": contentStateSecend,
          "previewTextThird": contentStateThird,
        },
      });

      if (response.status === 200) {
        console.log("Data saved successfully");
        EditConfirm();
        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      EditUnsuccess();
      // Handle error: show error message to the user or retry saving
    }
  };

  // useEffect(() => {
  //   console.log("content State", contentState);
  // }, [contentState]);

  useEffect(() => {
    if (savedData && savedData.previewText !== null) {
      // const blocksFromHTML = convertFromHTML(savedData.previewText);
      // const contentState = ContentState.createFromBlockArray(
      //   blocksFromHTML.contentBlocks,
      //   blocksFromHTML.entityMap
      // );
      // let contentState = stateFromHTML(savedData.previewText);
      // console.log("contentState ", contentState);
      // setEditorState(EditorState.createWithContent(contentState));
      // setTimeout(() => {
      //   setContentState(savedData.previewText);
      //   console.log("time out!!");
      //   console.log("saved Data preview", savedData.previewText);
      // }, 2000);

      // โค้ดเก่า orignal ก่อนแก้ไข
      // const blocksFromHTML = convertFromHTML(savedData.previewText);
      // const contentState = ContentState.createFromBlockArray(
      //   blocksFromHTML.contentBlocks,
      //   blocksFromHTML.entityMap
      // );
      // const editorState = EditorState.createWithContent(contentState);

      // console.log("saved Data preview", savedData.previewText);

      // console.log("previewTextEditorState", savedData.previewTextEditorState);

      // let editorStateFromServer = savedData.previewTextEditorState;
      // let htmlContent = convertFromHTML(savedData.previewText);
      // let html = stateFromHTML(savedData.previewText);

      // console.log("htmlContent", htmlContent);
      // console.log("html", html);
      // let editorState = EditorState.createWithContent(
      //   ContentState.createFromBlockArray(
      //     htmlContent.contentBlocks,
      //     htmlContent.entityMap
      //   )
      // );

      // console.log("editorState now", editorState);
      // // console.log("editorState preview", editorState);
      // setInitialValue(editorState);
      // setContentInitialValue(savedData.previewText);
      // setEditorState(editorState);
      // setContentState(savedData.previewText);

      // let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());

      // console.log("currentContentAsHTML", currentContentAsHTML);

      // console.log("currentContent", currentContentAsHTML);
      // convertContentToHTML();
      // console.log("convertedContent", currentContentAsHTML);

      // console.log("blockFrom HTML ", blocksFromHTML);
      // console.log("contentState  ", contentState);

      const html = savedData.previewText;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        setContentState(savedData.previewText);
      }
    }
    if (savedData && savedData.previewTextSecond !== null) {
      // const blocksFromHTMLSecend = convertFromHTML(savedData.previewTextSecond);
      // const contentStateSecend = ContentState.createFromBlockArray(
      //   blocksFromHTMLSecend.contentBlocks,
      //   blocksFromHTMLSecend.entityMap
      // );
      // const editorStateSecend =
      //   EditorState.createWithContent(contentStateSecend);

      // setInitialValueSecend(savedData.previewTextSecond);
      // setEditorStateSecend(editorStateSecend);
      // setContentStateSecend(savedData.previewTextSecond);

      const html = savedData.previewTextSecond;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorStateSecend(editorState);
        setContentStateSecend(savedData.previewTextSecond);
      }
    }

    if (savedData && savedData.previewTextThird !== null) {
      const html = savedData.previewTextThird;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        setEditorStateThird(editorState);
        setContentStateThird(savedData.previewTextThird);
      }
    }
  }, [savedData]);

  const handleSavePreviewFirst = () => {
    handleSaveData();
  };

  // function uploadImageCallBack(file) {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();
  //     xhr.open("POST", "https://api.imgur.com/3/image");
  //     xhr.setRequestHeader("Authorization", "Client-ID XXXXX");
  //     const data = new FormData();
  //     data.append("image", file);
  //     xhr.send(data);
  //     xhr.addEventListener("load", () => {
  //       const response = JSON.parse(xhr.responseText);
  //       resolve(response);
  //     });
  //     xhr.addEventListener("error", () => {
  //       const error = JSON.parse(xhr.responseText);
  //       reject(error);
  //     });
  //   });
  // }

  // class EditorContainer extends Component {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       editorState: EditorState.createEmpty(),
  //     };
  //   }

  //   onEditorStateChange = (editorState) => {
  //     // console.log(editorState)

  //     this.setState({
  //       editorState,
  //     });
  //     handleEditorStateChange(editorState);
  //     handleContentStateChange(editorState);
  //   };

  //   render() {
  //     const { editorState } = this.state;
  //     return (
  //       <div className="editor">
  //         <Editor
  //           editorState={editorState}
  //           onEditorStateChange={this.onEditorStateChange}
  //           localization={{
  //             locale: "es",
  //             translations: es,
  //           }}
  //           toolbar={{
  //             inline: { inDropdown: true },
  //             list: { inDropdown: true },
  //             textAlign: { inDropdown: true },
  //             link: { inDropdown: true },
  //             history: { inDropdown: true },
  //             image: {
  //               uploadCallback: uploadImageCallBack,
  //               alt: { present: true, mandatory: true },
  //             },
  //           }}
  //           // onEditorStateChange={handleEditorStateChange}
  //           onContentStateChange={handleContentStateChange}
  //         />
  //       </div>
  //     );
  //   }
  // }

  // useEffect(() => {
  //   if (EditorTextRefFirst.current && EditorTextRefFirst.current.editor) {
  //     const editorElement = EditorTextRefFirst.current.editor.editor;
  //     editorElement.focus();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (EditorTextRefSecond.current && EditorTextRefSecond.current.editor) {
  //     const editorElement = EditorTextRefSecond.current.editor.editor;
  //     editorElement.focus();
  //   }
  // }, []);

  // useEffect(() => {
  //   handleEditorStateChange(editorState);
  //   handleContentStateChange(editorState);
  // }, [editorState]);

  const [ThemeForm, setThemeForm] = useState({
    name: "ชายหาด",
    backgroundColor: "#F9E4B7",
    primaryColor: "#2E8BC0",
    secondaryColor: "#56C9D8",
    accentColor1: "#82E0AA",
  });

  const [isFormEnabled, setIsFormEnabled] = useState(null);

  // Function to toggle the form state
  const toggleFormState = async () => {
    try {
      // Make a POST request to the backend to toggle the form state
      setIsLoadComplete(false);
      // const response = await axios.post(
      //   "http://localhost:7071/api/FormCloseOpen  "

      const response = await axios.patch(`${API_URL_FORM}/forms/${formId}`, {
        "replace": "/isFormEnabled",
        "value": !isFormEnabled,
      });

      if (response.data.statusCode === 200) {
        console.log("Form Status changed successfully");
        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to Changed");
      }
    } catch (error) {
      console.error("Error toggling form state:", error);
    } finally {
      setIsFormEnabled((prevState) => !prevState);
      setIsLoadComplete(true);
    }
  };

  // Function to fetch initial form state when component mounts

  return (
    <div
      style={{
        width: "100%",
        height: "1400px",

        fontFamily: "Segoe UI, sans-serif",
        flexDirection: "column",

        backgroundColor: "#f8f8f8",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "98%",
          minheight: "1100px",
          margin: "auto",
          fontFamily: "Segoe UI, sans-serif",
          flexDirection: "column",

          backgroundColor: "#f8f8f8",
          alignContent: "center",
        }}
      >
        <Toaster toasterId={toasterId} />
        <div
          style={{
            display: "flex",
            height: "250px",
            width: "100%",
            backgroundColor: "#f8f8f8",
            justifyContent: "flex-start",
            flexDirection: "column",

            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              textAlign: "left",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",

                width: "98%",

                position: "relative",
                justifyContent: "space-between",
                paddingBottom: "20px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",

                    width: "600px",
                    textAlign: "center",
                  }}
                >
                  <h2
                    className={styles.title}
                    style={{
                      color: "black",
                      textAlign: "left",
                      marginLeft: "10px",
                    }}
                  >
                    <Icon
                      iconName="ServerProcesses"
                      style={{ color: "#106ebe", marginRight: "2px" }}
                    />
                    {t("requestFormAdminFormEditor.menuEditForm")}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    textAlign: "left",
                    width: "600px",
                    marginLeft: "10px",
                  }}
                >
                  <div className={styles.tabTheme}>
                    <TabList
                      defaultSelectedValue="tab1"
                      selectTabOnFocus={true}
                    >
                      {renderTabs()}
                    </TabList>
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignContent: "flex-start",
                  alignItems: "end",
                  height: "80px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text
                    style={{
                      marginLeft: "10px",
                      color: "black",
                      fontWeight: "bolder",
                      textAlign: "right",
                    }}
                    size={400}
                  >
                    {t("requestFormAdminFormEditor.closeForm")}
                  </Text>

                  {isLoadComplete ? (
                    <div style={{ display: "flex" }}>
                      <div>
                        <Switch
                          defaultChecked={isFormEnabled} // Set the initial checked state
                          onClick={() => {
                            toggleFormState();
                          }} // Call the toggleFormState function on click
                          style={{
                            color: "#16263D",
                            fontStyle: "#16263D",
                          }}
                          disabled={currentCustom === "notSubscribe"}
                        />
                      </div>

                      <div style={{ marginTop: "5px" }}>
                        {isFormEnabled ? (
                          <Field
                            validationMessage={
                              <Text
                                style={{
                                  color: "green",
                                }}
                              >
                                {t("requestFormAdminFormEditor.avaliable")}
                              </Text>
                            }
                            validationState="none"
                          ></Field>
                        ) : (
                          <Field
                            validationMessage={
                              <Text
                                style={{
                                  color: "black",
                                }}
                              >
                                {t("requestFormAdminFormEditor.disabled")}
                              </Text>
                            }
                            validationState="none"
                          ></Field>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div>
                        {/* <Switch
                        defaultChecked={isFormEnabled} // Set the initial checked state
                        onClick={() => {
                          toggleFormState();
                        }} // Call the toggleFormState function on click
                        style={{
                          color: "#16263D",
                          fontStyle: "#16263D",
                        }}
                      /> */}
                      </div>
                      <div
                        style={{
                          marginTop: "5px",
                          display: "flex",
                          color: "black",
                        }}
                      >
                        <div style={{ marginRight: "10px", marginTop: "5px" }}>
                          <Spinner size="tiny"></Spinner>
                        </div>
                        <div>
                          {" "}
                          <Text color="black">{t("requestFormAdminFormEditor.loading")}</Text>
                        </div>
                      </div>
                    </div>
                  )}

         
                </div>
              </div>

         
            </div>
          </div>

          <Divider></Divider>

          {/* <div
            style={{
              marginTop: "20px",
              position: "absolute",
              top: "120px",
              left: "50px",
              display: "flex",
            }}
          >
            <ThemeSelector />
            <ExampleForm />
          </div> */}

          <div className="container">
            <div className="row-pickup">
              {currentCustom === "pickTheme" && (
                <>
                  <div className="column">
                    {/* <hr></hr> */}
                    <div
                      style={{
                        width: "900px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignContent: "flex-start",
                        textAlign: "center",
                        height: "80px",
                      }}
                    >
                      {" "}
                      <h2 style={{ color: "black" }}>
                        {t("requestFormAdminFormEditor.selectThemeForForm")}
                      </h2>
                    </div>
                    <ThemeSelector
                      ThemeForm={ThemeForm}
                      setThemeForm={setThemeForm}
                      EditConfirm={EditConfirm}
                      EditUnsuccess={EditUnsuccess}
                      formId={formId}
                      loading={loading}
                      setLoading={setLoading}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",

                      textAlign: "center",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    {/* <div>
                    <ExampleForm />
                  </div> */}
                    <div
                      style={{
                        width: "900px",
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignContent: "flex-start",
                        textAlign: "center",
                        height: "80px",
                      }}
                    >
                      {" "}
                      <Label className={styles.title}>
                        {t("requestFormAdminFormEditor.exampleThemeForForm")}
                      </Label>
                    </div>
                    {!loading ? (
                      <div
                        style={{
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          backgroundColor: "white",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                          width: "830px",
                        }}
                      >
                        <div>
                          <ExampleFormFirstPage contentState={contentState} />
                        </div>
                        <div>
                          <ExampleFormSecondPage contentState={contentState} />
                        </div>
                        <div>
                          <ExampleFormThirdPage
                            contentStateSecend={contentStateSecend}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          paddingLeft: "2rem",
                          paddingRight: "2rem",
                          paddingTop: "1rem",
                          paddingBottom: "1rem",
                          backgroundColor: "white",
                
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",

                          width: "830px",
                          justifyContent: "space-between",
                        }}
                      >
                        <Skeleton>
                          <SkeletonItem
                            style={{
                              maxWidth: "380px",
                              height: "300px",
                            }}
                          >
                            <div
                              style={{
                                width: "270px",
                                height: "50px",
                              }}
                            ></div>
                          </SkeletonItem>{" "}
                        </Skeleton>
                        <Skeleton>
                          <SkeletonItem
                            style={{
                              maxWidth: "380px",
                              height: "300px",
                            }}
                          >
                            <div
                              style={{
                                width: "270px",
                                height: "50px",
                              }}
                            ></div>
                          </SkeletonItem>{" "}
                        </Skeleton>
                        <Skeleton>
                          <SkeletonItem
                            style={{
                              maxWidth: "380px",
                              height: "300px",
                            }}
                          >
                            <div
                              style={{
                                width: "270px",
                                height: "50px",
                              }}
                            ></div>
                          </SkeletonItem>{" "}
                        </Skeleton>
                      </div>
                    )}
                  </div>
                </>
              )}
              {currentCustom === "contactList" && (
                <div className="column" style={{ color: "black" }}>
                  {/* <hr></hr> */}
                  <div
                    style={{
                      width: "900px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      textAlign: "center",
                      height: "80px",
                    }}
                  >
                    {" "}
                    <h2>{t("requestFormAdminFormEditor.printQrCode")}</h2>
                  </div>

                  <ManagementQrCode
                    urlID={urlID}
                    EditConfirm={EditConfirm}
                    EditUnsuccess={EditUnsuccess}
                    formId={formId}
                    CopySuccess={CopySuccess}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="container-form">
            {currentCustom === "addTextForm" && (
              <div className="row">
                <div className="column">
                  {/* <hr></hr> */}

                  <div
                    style={{
                      width: "900px",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignContent: "flex-start",
                      textAlign: "center",
                      height: "80px",
                    }}
                  >
                    {" "}
                    <h2 style={{ color: "black" }}>
                      {t("requestFormAdminFormEditor.addingTextToForm")}
                    </h2>
                  </div>
                  {isLoadComplete ? (
                    <div>
                      <div className="stack-background">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Text
                            className={styles.title}
                            style={{ color: "black" }}
                          >
                            {t("requestFormAdminFormEditor.addingFirstPage")}
                          </Text>

                          <div
                            // className="fieldtextbackground"
                            style={{
                              backgroundColor: "white",
                              marginTop: "10px",
                            }}
                          >
                            <Editor
                              editorState={editorState}
                              toolbarClassName="editor-toolbar"
                              wrapperClassName="editor-wrapper"
                              editorClassName="editor"
                              onEditorStateChange={handleEditorStateChange}
                              // onEditorStateChange={this.onEditorStateChange}
                              onContentStateChange={handleContentStateChange}
                              // toolbar={toolbar}
                              customBlockRenderFunc={myBlockStyleFn}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },

                                // image: {
                                //   uploadCallback: uploadImageCallBack,
                                //   alt: { present: true, mandatory: true },
                                // },
                                fontSize: {
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                  ],

                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  defaultFontSize: 96,
                                },
                              }}
                              localization={localization}
                              // localization={{
                              //   locale: "es",
                              //   translations: es,
                              // }}
                              spellCheck
                              // ref={EditorTextRefFirst}
                              // toolbarCustomButtons={[<CustomComponent />]}
                              // onFocus={() => {
                              //   handleContentStateChange(contentInitialValue);
                              //   handleEditorStateChange(initialValue);
                              // }}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "50px",
                          }}
                        >
                          <Text
                            className={styles.title}
                            style={{ color: "black" }}
                          >
                            {t("requestFormAdminFormEditor.addingLastPage")}
                          </Text>

                          <div
                            // className="fieldtextbackground"
                            style={{
                              backgroundColor: "white",
                              marginTop: "10px",
                            }}
                          >
                            <Editor
                              editorState={editorStateSecend}
                              toolbarClassName="editor-toolbar"
                              wrapperClassName="editor-wrapper"
                              editorClassName="editor"
                              onEditorStateChange={
                                handleEditorStateChangeSecend
                              }
                              // onContentStateChange={
                              //   handleContentStateChangeSecend
                              // }
                              // toolbar={toolbar}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                // image: {
                                //   uploadCallback: uploadImageCallBack,
                                //   alt: { present: true, mandatory: true },
                                // },
                                fontSize: {
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                  ],

                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  defaultFontSize: 96,
                                },
                              }}
                              localization={localization}
                              // localization={{
                              //   locale: "es",
                              //   translations: es,
                              // }}
                              spellCheck
                              // stripPastedStyles
                              // ref={EditorTextRefSecond}
                              // toolbarCustomButtons={[<CustomComponent />]}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "50px",
                          }}
                        >
                          <Text
                            className={styles.title}
                            style={{ color: "black" }}
                          >
                            {t("requestFormAdminFormEditor.addingCloseForm")}
                          </Text>

                          <div
                            style={{
                              backgroundColor: "white",
                              marginTop: "10px",
                            }}
                          >
                            <Editor
                              editorState={editorStateThird}
                              toolbarClassName="editor-toolbar"
                              wrapperClassName="editor-wrapper"
                              editorClassName="editor"
                              onEditorStateChange={handleEditorStateChangeThird}
                              // onContentStateChange={handleContentStateChangeThird}
                              // toolbar={toolbar}
                              toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },

                                fontSize: {
                                  options: [
                                    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36,
                                  ],

                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  defaultFontSize: 96,
                                },
                                // image: {
                                //   uploadCallback: uploadImageCallBack,
                                //   alt: { present: true, mandatory: true },
                                // },
                              }}
                              localization={localization}
                              // localization={{
                              //   locale: "es",
                              //   translations: es,
                              // }}
                              spellCheck
                              // stripPastedStyles
                              // ref={EditorTextRefSecond}
                              // toolbarCustomButtons={[<CustomComponent />]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="stack-background-for-loading">
                      {/* <Spinner style={{ justifySelf: "center" }}>
                      <Text color="black" style={{ color: "black" }}>
                        กำลังโหลด ...
                      </Text>
                    </Spinner> */}

                      <div
                        style={{
                          display: "flex",
                          width: "830px",
                          justifyContent: "center",
                        }}
                      >
                        <div style={{ marginRight: "10px", marginTop: "-3px" }}>
                          <Spinner size="extra-large"></Spinner>
                        </div>
                        <div>
                          {" "}
                          <Text color="black" style={{ fontSize: "20px" }}>
                            {t("requestFormAdminFormEditor.loading")}
                          </Text>
                        </div>
                      </div>
                    </div>
                  )}

                  {isLoadComplete && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Dialog modalType="modal">
                        <DialogTrigger disableButtonEnhancement>
                          <Button
                            appearance="primary"
                            style={{ marginRight: "10px" }}
                            disabled={!isLoadComplete}
                          >
                            {t("requestFormAdminFormEditor.save")}
                          </Button>
                        </DialogTrigger>

                        <DialogSurface className="fluentui-dialog">
                          <DialogBody className="dialog-body">
                            <DialogTitle>
                              {" "}
                              {t("requestFormAdminFormEditor.sentData")}
                            </DialogTitle>
                            <DialogContent>
                              {" "}
                              {t("requestFormAdminFormEditor.confirmToSave")}
                            </DialogContent>
                            <DialogActions>
                              <DialogTrigger disableButtonEnhancement>
                                <Button
                                  onClick={handleSavePreviewFirst}
                                  appearance="primary"
                                  className="button-dialog"
                                >
                                  {t("requestFormAdminFormEditor.confirm")}
                                </Button>
                              </DialogTrigger>

                              <DialogTrigger disableButtonEnhancement>
                                <Button
                                  appearance="secondary"
                                  className="button-dialog"
                                >
                                  {t("requestFormAdminFormEditor.cancel")}
                                </Button>
                              </DialogTrigger>
                            </DialogActions>
                          </DialogBody>
                        </DialogSurface>
                      </Dialog>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className="container-form"></div> */}
      </div>
    </div>
  );
};

export default ManagementForm;
