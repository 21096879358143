import React from "react";

import  "./ThemeSelector.css"; // Import CSS module
import { useTranslation } from "react-i18next";
import { Skeleton, SkeletonItem } from "@fluentui/react-components";
import { themeQR } from "./ThemeColorsSetting.js";
const ThemeSelectorForQrCode= ({
  applyPalette,
  selectedTheme,
  themeloading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="wrap-paper">
      <h3 style={{ color: "black" }}>
        {t("requestFormAdminFormEditor.selectBackground")}
      </h3>
      <p style={{ color: "grey" }}>
        {" "}
        {selectedTheme
          ? t(`requestFormThemeQrColor.${selectedTheme}`)
          : t(`requestFormThemeQrColor.${selectedTheme}`)}{" "}
      </p>

      {themeloading ? (
        <div id="palettes" className="palette-container-qr">
          {[...Array(16)].map((_, index) => (
            <Skeleton key={index} className="palette-qr-loading">
              <SkeletonItem
                key={index}
                className="palette-color-qr-loading"
              ></SkeletonItem>
            </Skeleton>
          ))}
        </div>
      ) : (
        <div id="palettes" className="palette-container-qr">
          {themeQR.map((palette, index) => (
            <div
              key={index}
              className="palette-qr"
              style={{ backgroundColor: palette.firstshade }}
              onClick={(event) => applyPalette(palette.id, palette)}
            >
              <div className="palette-Inner-wrap-qr">
                {/* <div
                  className={`${styles.palette-color-qr} ${styles.firstshade}`}
                  style={{ backgroundColor: palette.firstshade }}
                ></div> */}
                  <div
                  className="palette-color-qr"
                  style={{ backgroundColor: palette.firstshade }}
                ></div>
                <div
                  // className={`${styles.palette-color-qr} ${styles.secondshade}`}
                    className="palette-color-qr"
                  style={{ backgroundColor: palette.secondshade }}
                ></div>
                <div
                  // className={`${styles.palette-color-qr} ${styles.thirdshade}`}
                    className="palette-color-qr"
                  style={{ backgroundColor: palette.thirdshade }}
                ></div>
                <div
                  // className={`${styles.palette-color-qr} ${styles.fourthshade}`}
                    className="palette-color-qr"
                  style={{ backgroundColor: palette.fourthshade }}
                ></div>
                {/* <Label> {palette.name}</Label> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThemeSelectorForQrCode;
