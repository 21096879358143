import React, { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useState } from "react";

import EditContacts from "./EditContacts";
import {
  useToastController,
  ToastTitle,
  ToastTrigger,
  Toast,
  useId,
  Link,
} from "@fluentui/react-components";

// const useStyles = makeStyles({
//   title: typographyStyles.title3,
//   languagechange: typographyStyles.subtitle2,

//   fieldWrapper: {
//     ...shorthands.padding(
//       tokens.spacingVerticalMNudge,
//       tokens.spacingHorizontalMNudge
//     ),
//   },
// });

export default function ContactsPersonal() {
  const { t } = useTranslation();

  const [isLoadComplete, setIsLoadComplete] = useState(false);
  // const [isSearchComplete, setIsSearchComplete] = useState(false);

  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const EditConfirm = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {t("requestFormContactsList.saveAlready")}
        </ToastTitle>
      </Toast>,
      { intent: "success" }
    );

  const EditUnsuccess = () =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
           {t("requestFormContactsList.saveUnsucess")}
        </ToastTitle>
      </Toast>,
      { intent: "error" }
    );
  // const fetchData = async () => {
  //   try {
  //     setIsLoadComplete(false);

  //     const response = await axios.get(
  //       "http://localhost:5004/api/RequestsList?from=2024-01-01&to=2024-12-31&offset=2&limit=10"
  //     );

  //     // setFilteredData(response.data.requests);
  //     console.log("API Response:", response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   } finally {
  //     setIsLoadComplete(true);
  //   }
  // };

  useEffect(() => {
    setIsLoadComplete(true);
  }, []);

  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps
  const totalSteps = 3;

  const handleNextStep = () => {
    if (step < totalSteps) {
      setStep(step + 1);
      setCompletedSteps([...completedSteps, step]); // Mark current step as completed
    }
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      // Remove the completed step when navigating back
      setCompletedSteps(
        completedSteps.filter((completedStep) => completedStep !== step - 1)
      );
    }
  };

  const isStepCompleted = (stepNumber) => {
    return completedSteps.includes(stepNumber);
  };

  const [universaldata, setUniversaldata] = useState(false);

  return (
    <>
      <section style={{width:"1100px",height:"450px",marginTop:"10px",display:"flex",flexDirection:"column"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
            paddingTop: "20px",
            marginTop: "20px",
          }}
        >
          <div style={{ position: "absolute", left: "230px", top: "-31px" }}>
            <h2> {t("requestFormContactsList.editInformation")}</h2>
          </div>{" "}
        
        </div>
        {/* <div style={{ position: "absolute", right: "-28px", top: "-60px" }}>
            <Field className={styles.fieldWrapper}>
              <SearchBox
                size="medium"
                placeholder="Search..."
                onChange={(e) => handleSearch(e.currentTarget.value)}
              />
            </Field>
          </div> */}

        <div
          style={{
            backgroundColor: "#f8f8f8",
            // borderRadius: "8px",
            // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",

            height: `auto`,
            position: "relative",
          }}
        >
          <div
            style={{
              width: "100%",

              display: "flex",
              justifyContent: "center",
              minHeight: "auto",
            }}
          >
            {" "}
            <EditContacts
              step={step}
              setStep={setStep}
              handleNextStep={handleNextStep}
              handlePrevStep={handlePrevStep}
              isStepCompleted={isStepCompleted}
              universaldata={universaldata}
              setUniversaldata={setUniversaldata}
              EditConfirm={EditConfirm}
              EditUnsuccess={EditUnsuccess}
              isLoadComplete={isLoadComplete}
              setIsLoadComplete={setIsLoadComplete}
            />
          </div>
        </div>
      </section>
    </>
  );
}
