import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { silentRequest } from '../../../authConfig';
import { useMsal, useAccount } from '@azure/msal-react';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  PrimaryButton,
  DefaultButton,
  SearchBox,
  TextField,
  ContextualMenu,
  CheckboxVisibility,
  MessageBar,
  MessageBarType,
  Icon,
  Calendar,
  DateRangeType,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';
import axios from 'axios';

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

function Zoho() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const companyId = sessionStorage.getItem('companyId') || '';

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const [state] = useState(location.state);

  const [access_token] = useState(sessionStorage.getItem('zoho-access-token') || state.access_token);
  const [refresh_token] = useState(sessionStorage.getItem('zoho-refresh-token') || state.refresh_token);
  const [organizations, setOrganizations] = useState(JSON.parse(sessionStorage.getItem('zoho-organizations')) || []);

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);

  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const [documents, setDocuments] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);

  const [pageData, setPageData] = useState({});
  const [pageSize, setPageSize] = useState(sessionStorage.getItem('zoho-pageSize') * 1 || 10);
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('zoho-currentPage') * 1 || 1);

  //CommandBars Items
  const [organization, setOrganization] = useState(
    sessionStorage.getItem('zoho-organization-key')
      ? {
          key: sessionStorage.getItem('zoho-organization-key'),
          text: sessionStorage.getItem('zoho-organization-text'),
        }
      : {
          key: '',
          text: t('zoho.loading'),
        }
  );

  const [orderType, setOrderType] = useState(
    (sessionStorage.getItem('zoho-orderType-key') && {
      key: sessionStorage.getItem('zoho-orderType-key'),
      text: sessionStorage.getItem('zoho-orderType-text'),
    }) || {
      key: 'paid',
      text: 'PAID',
    }
  );

  const [allDocs, setAllDocs] = useState([]);

  const [search, setSearch] = useState('');

  const [includeVat, setIncludeVat] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [registerVat, setRegisterVat] = useState(true);

  const [showMessageBarError, setShowMessageBarError] = useState(false);

  const isCompactMode = false;
  const [isCreateDocument, setIsCreateDocument] = useState(false);
  const [isCreateDocumentComplete, setIsCreateDocumentComplete] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };

  const [prefixNumber, setPrefixNumber] = useState('');
  const [countingNumber, setCountingNumber] = useState('');

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('zoho-primarySort') || 'documentDate');
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(() => {
    if (sessionStorage.getItem('zoho-sort-documentDate') === 'false') {
      return false;
    } else {
      return true;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('zoho-sort-number')) {
      if (sessionStorage.getItem('zoho-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('zoho-sort-customerName')) {
      if (sessionStorage.getItem('zoho-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('zoho-sort-grandTotal')) {
      if (sessionStorage.getItem('zoho-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const DayPickerStrings = {
    months: [
      t('zoho.months01'),
      t('zoho.months02'),
      t('zoho.months03'),
      t('zoho.months04'),
      t('zoho.months05'),
      t('zoho.months06'),
      t('zoho.months07'),
      t('zoho.months08'),
      t('zoho.months09'),
      t('zoho.months10'),
      t('zoho.months11'),
      t('zoho.months12'),
    ],

    shortMonths: [
      t('zoho.shortMonths01'),
      t('zoho.shortMonths02'),
      t('zoho.shortMonths03'),
      t('zoho.shortMonths04'),
      t('zoho.shortMonths05'),
      t('zoho.shortMonths06'),
      t('zoho.shortMonths07'),
      t('zoho.shortMonths08'),
      t('zoho.shortMonths09'),
      t('zoho.shortMonths10'),
      t('zoho.shortMonths11'),
      t('zoho.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('zoho.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('zoho.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createDocument = async (documentType, item) => {
    setIsLoading(true);

    console.log(item);

    let documentPath = '/etax/documents/receipts/confirm';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices/confirm';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoices-abb/confirm';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts/confirm';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices/confirm';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices/confirm';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices/confirm';
        break;
      default:
        break;
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/zoho/invoices/' + organization.key + '/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then((response) => {
            console.log(response, true);
            if (response.data.error === 45) {
              setShowMessageBarError(true);
            } else {
              let invoice_detail = response.data;

              let d = item.documentDate.split('-');

              let _date = new Date(d[1] + '/' + d[2] + '/' + d[0]);

              let _dateBE =
                `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
              let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

              let d_due = item.dueDate.split('-');

              let _dueDate = new Date(d_due[1] + '/' + d_due[2] + '/' + d_due[0]);

              let _dueDateBE =
                `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + (_dueDate.getFullYear() + 543);
              let _dueDateCE =
                `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

              let _taxId = '';
              let _taxNumberType = '';
              let _branchNumber = '';
              let _branchText = '';

              _taxNumberType = 'OTHR';
              _taxId = 'N/A';

              let _customer = {
                addressLineOne: item.customerAddress,
                addressLineTwo: '',
                branchNumber: _branchNumber,
                branchText: _branchText,
                email: item.customerEmail,
                name: item.customerName,
                phone: item.customerPhone !== '' ? item.customerPhone : '',
                phoneCountryCode: '+66',
                postcode: item.customerPostcode,
                taxId: _taxId,
                taxNumberType: _taxNumberType,
                language: 'th',
                unstructure: true,
              };

              let _items = [];
              let _line_items = invoice_detail.line_items;

              for (let i = 0; i < _line_items.length; i++) {
                let _quantity = _line_items[i].quantity * 1;
                let _price = Math.round(_line_items[i].rate * 100) / 100;
                let _discount = Math.round(_line_items[i].discount * 100) / 100;

                let _percentVat = percentVat;
                let _percentVatText = percentVatText;

                if (_line_items[i].tax_percentage === 0) {
                  _percentVat = zeroVat;
                  _percentVatText = zeroVatText;
                }

                let _total = Math.round(_price * _quantity * 100) / 100 - _discount;

                _items.push({
                  number: i + 1 + '',
                  description: _line_items[i].name,
                  quantity: _quantity,
                  unitCode: '',
                  unitName: '-',
                  price: _price,
                  discount: 0,
                  total: _total,
                  percentVat: _percentVat,
                  percentVatText: _percentVatText,
                });
              }

              let _vatTotal = invoice_detail.tax_total;
              let _amountTotal = invoice_detail.total - _vatTotal;
              let _discount_total = invoice_detail.discount_total;

              invoice_detail.shipping_charge !== 0 &&
                _items.push({
                  number: _items.length + 1,
                  description: 'ค่าขนส่ง',
                  quantity: 1,
                  unitCode: '',
                  unitName: '-',
                  price: invoice_detail.shipping_charge * 1,
                  discount: 0,
                  percentVat: invoice_detail.shipping_charge_tax !== 0 ? percentVat : zeroVat,
                  percentVatText: invoice_detail.shipping_charge_tax !== 0 ? percentVatText : zeroVatText,
                  total: invoice_detail.shipping_charge * 1,
                });

              let _vatPriceTotal = 0;
              let _zeroVatPriceTotal = 0;
              let _nonVatPriceTotal = 0;

              _items.forEach((item) => {
                if (item.percentVat === percentVat) {
                  _vatPriceTotal = _vatPriceTotal + item.total;
                } else if (item.percentVat === zeroVat) {
                  _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                } else if (item.percentVat === nonVat) {
                  _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                }
              });

              console.log('_customer: ', _customer);
              console.log(_items);

              history.push({
                pathname: documentPath,
                state: {
                  company: {
                    ...company,
                    includeVat: invoice_detail.is_inclusive_tax,
                  },
                  customer: _customer,
                  date: _date,
                  dateBE: _dateBE,
                  dateCE: _dateCE,
                  dueDate: _dueDate,
                  dueDateBE: _dueDateBE,
                  dueDateCE: _dueDateCE,
                  discount: _discount_total,
                  grandTotal: item.grandTotal,
                  amountTotal: _amountTotal,
                  nonVatPriceTotal: _nonVatPriceTotal,
                  zeroVatPriceTotal: _zeroVatPriceTotal,
                  vatPriceTotal: _vatPriceTotal,
                  vatTotal: _vatTotal,
                  items: _items,
                  note: invoice_detail.notes,
                  reference: item.invoiceNumber,
                  percentVat: percentVat,
                  receivedBy: 'Zoho',
                  reIssue: false,
                  backUrl: '/etax/sources/zoho',
                  includeVat: invoice_detail.is_inclusive_tax,
                },
              });
            }
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  let columns = [
    {
      key: 'column1',
      name: t('zoho.documentDate'),
      fieldName: 'documentDate',
      minWidth: 70,
      maxWidth: 110,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: !isSortedDescendingDocumentDate,
      isSortedAscending: isSortedDescendingDocumentDate,
      onColumnClick: () => {
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        sessionStorage.setItem('zoho-primarySort', 'documentDate');
        sessionStorage.setItem('zoho-sort-documentDate', !isSortedDescendingDocumentDate === false ? 'false' : 'true');

        sessionStorage.removeItem('zoho-sort-number');
        sessionStorage.removeItem('zoho-sort-customerName');
        sessionStorage.removeItem('zoho-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('zoho.number'),
      fieldName: 'invoiceNumber',
      minWidth: 100,
      maxWidth: 120,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('zoho-primarySort', 'number');
        sessionStorage.setItem('zoho-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('zoho-sort-documentDate');
        sessionStorage.removeItem('zoho-sort-customerName');
        sessionStorage.removeItem('zoho-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.invoiceNumber}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('zoho.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        handleSort('customerName', !isSortedDescendingCustomerName);

        sessionStorage.setItem('zoho-primarySort', 'customerName');
        sessionStorage.setItem('zoho-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('zoho-sort-documentDate');
        sessionStorage.removeItem('zoho-sort-number');
        sessionStorage.removeItem('zoho-sort-grandTotal');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('zoho.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 120,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('zoho.phone'),
      fieldName: 'customerPhone',
      minWidth: 80,
      maxWidth: 80,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerPhone}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('zoho.email'),
      fieldName: 'customerEmail',
      minWidth: 140,
      maxWidth: 140,
      isCollapsible: true,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerEmail}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: registerVat ? t('zoho.grandTotal') : t('zoho.total'),
      fieldName: 'grandTotal',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort document grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        handleSort('grandTotal', !isSortedDescendingGrandTotal);

        sessionStorage.setItem('zoho-primarySort', 'grandTotal');
        sessionStorage.setItem('zoho-sort-grandTotal', !isSortedDescendingGrandTotal === false ? 'false' : 'true');

        sessionStorage.removeItem('zoho-sort-documentDate');
        sessionStorage.removeItem('zoho-sort-customerName');
        sessionStorage.removeItem('zoho-sort-number');

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('zoho.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('zoho.processing')} labelPosition="right" style={{ marginLeft: '0px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('zoho.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('zoho.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('zoho.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('zoho.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('zoho.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('zoho.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('zoho.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('zoho.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('zoho.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const getMonth = (goBack) => {
    let monthNames = [
      t('zoho.months01'),
      t('zoho.months02'),
      t('zoho.months03'),
      t('zoho.months04'),
      t('zoho.months05'),
      t('zoho.months06'),
      t('zoho.months07'),
      t('zoho.months08'),
      t('zoho.months09'),
      t('zoho.months10'),
      t('zoho.months11'),
      t('zoho.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('zoho-queryMonth-key') && {
      key: sessionStorage.getItem('zoho-queryMonth-key'),
      text: sessionStorage.getItem('zoho-queryMonth-text'),
    }) || { key: getMonth(0).year + '' + getMonth(0).key, text: t('zoho.thisMonth') }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('zoho-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('zoho-to') || getTo(getMonth(0).month, getMonth(0).year));

  const [date, setDate] = useState(sessionStorage.getItem('zoho-date') ? new Date(sessionStorage.getItem('zoho-date')) : new Date());

  const [pageDropdown, setPageDropdown] = useState([]);

  const history = useHistory();

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('zoho.thisMonth');

    let monthNames = [
      t('zoho.months01'),
      t('zoho.months02'),
      t('zoho.months03'),
      t('zoho.months04'),
      t('zoho.months05'),
      t('zoho.months06'),
      t('zoho.months07'),
      t('zoho.months08'),
      t('zoho.months09'),
      t('zoho.months10'),
      t('zoho.months11'),
      t('zoho.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: organization.key,
      text: organization.text,
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'CityNext2' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: organizations.map((org) => {
          return {
            key: org.key,
            text: org.text,
            iconProps: { iconName: 'CityNext2' },
            onClick: () => {
              setOrganization(org);

              sessionStorage.setItem('zoho-organization-key', org.key);
              sessionStorage.setItem('zoho-organization-text', org.text);

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          };
        }),
      },
    },
    {
      key: orderType.key,
      text: orderType.text,
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ProductList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'sent',
            text: 'SENT',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'sent', text: 'SENT' });

              sessionStorage.setItem('zoho-orderType-key', 'sent');
              sessionStorage.setItem('zoho-orderType-text', 'SENT');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'draft',
            text: 'DRAFT',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'draft', text: 'DRAFT' });

              sessionStorage.setItem('zoho-orderType-key', 'draft');
              sessionStorage.setItem('zoho-orderType-text', 'DRAFT');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'overdue',
            text: 'OVERDUE',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'overdue', text: 'OVERDUE' });

              sessionStorage.setItem('zoho-orderType-key', 'overdue');
              sessionStorage.setItem('zoho-orderType-text', 'OVERDUE');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'paid',
            text: 'PAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'paid', text: 'PAID' });

              sessionStorage.setItem('zoho-orderType-key', 'paid');
              sessionStorage.setItem('zoho-orderType-text', 'PAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'void',
            text: 'VOID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'void', text: 'VOID' });

              sessionStorage.setItem('zoho-orderType-key', 'void');
              sessionStorage.setItem('zoho-orderType-text', 'VOID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'unpaid',
            text: 'UNPAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'unpaid', text: 'UNPAID' });

              sessionStorage.setItem('zoho-orderType-key', 'unpaid');
              sessionStorage.setItem('zoho-orderType-text', 'UNPAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'partially_paid',
            text: 'PARTIALLY_PAID',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({
                key: 'partially_paid',
                text: 'PARTIALLY_PAID',
              });

              sessionStorage.setItem('zoho-orderType-key', 'partially_paid');
              sessionStorage.setItem('zoho-orderType-text', 'PARTIALLY_PAID');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
          {
            key: 'viewed',
            text: 'VIEWED',
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('เอกสารทุกประเภท');
              setOrderType({ key: 'viewed', text: 'VIEWED' });

              sessionStorage.setItem('zoho-orderType-key', 'viewed');
              sessionStorage.setItem('zoho-orderType-text', 'VIEWED');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setSearch('');

              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('zoho.months01'),
                    t('zoho.months02'),
                    t('zoho.months03'),
                    t('zoho.months04'),
                    t('zoho.months05'),
                    t('zoho.months06'),
                    t('zoho.months07'),
                    t('zoho.months08'),
                    t('zoho.months09'),
                    t('zoho.months10'),
                    t('zoho.months11'),
                    t('zoho.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('zoho-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem('zoho-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  sessionStorage.setItem('zoho-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('zoho-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('zoho-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setSearch('');

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'pageSize ' + pageSize,
      text: pageSize + ' ' + t('zoho.documentPerPage'),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'NumberedList' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: [
          {
            key: 'pageSize ' + 10,
            text: '10 ' + t('zoho.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('10 ออเดอร์/หน้า');
              setPageSize(10);
              sessionStorage.setItem('zoho-pageSize', 10);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 20,
            text: '20 ' + t('zoho.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('20 ออเดอร์/หน้า');
              setPageSize(20);
              sessionStorage.setItem('zoho-pageSize', 20);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 30,
            text: '30 ' + t('zoho.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('30 ออเดอร์/หน้า');
              setPageSize(30);
              sessionStorage.setItem('zoho-pageSize', 30);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 40,
            text: '40 ' + t('zoho.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('40 ออเดอร์/หน้า');
              setPageSize(40);
              sessionStorage.setItem('zoho-pageSize', 40);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
          {
            key: 'pageSize ' + 50,
            text: '50 ' + t('zoho.documentPerPage'),
            iconProps: { iconName: 'NumberedList' },
            onClick: () => {
              console.log('50 ออเดอร์/หน้า');
              setPageSize(50);
              sessionStorage.setItem('zoho-pageSize', 50);

              setSearch('');

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setCurrentPage(1);
            },
          },
        ],
      },
    },
    {
      key: currentPage,
      text: t('zoho.page') + ' ' + currentPage,
      iconProps: { iconName: 'Page' },
      disabled: !loadDataComplete || isCreateDocument || search,
      subMenuProps: {
        items: pageDropdown,
      },
    },
    {
      key: 'refresh',
      text: t('zoho.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete || isCreateDocument || search,
      onClick: () => {
        console.log('Refresh');

        setSearch('');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setCurrentPage(1);

        setIsCreateDocumentComplete(false);
        setSelectedItems([]);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('zoho.disconnect'),
      onClick: () => {
        console.log('zoho disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('zoho.disconnect'),
          message: t('zoho.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const padLeadingZeros = (num, size) => {
    var s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  };

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'zoho',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'cid': companyId,
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/zoho/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const createSelectedDocuments = async (documentType) => {
    let _items = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      let _countingNumberLength = countingNumber.length;
      let _countingNumber = Number(countingNumber);

      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _items.length; j++) {
              if (_items[j].key === selectedItems[i].key) {
                _items[j].status = 'processing';

                setItems([..._items]);

                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                setCountingNumber(_countingNumber);

                await axios
                  .get(API_URL + '/sources/zoho/invoices/' + organization.key + '/' + selectedItems[i].key, {
                    headers: {
                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                      'cid': companyId,
                    },
                  })
                  .then(async (response) => {
                    console.log(response, true);

                    if (response.data.error === 45) {
                      setShowMessageBarError(true);

                      _items[j].status = 'fail';
                      setItems([..._items]);
                    } else {
                      let invoice_detail = response.data;

                      let d = selectedItems[i].documentDate.split('-');

                      let _date = new Date(d[1] + '/' + d[2] + '/' + d[0]);

                      let _dateBE =
                        `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                      let _dateCE =
                        `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                      let d_due = selectedItems[i].dueDate.split('-');

                      let _dueDate = new Date(d_due[1] + '/' + d_due[2] + '/' + d_due[0]);

                      let _dueDateBE =
                        `${_dueDate.getDate()}`.padStart(2, '0') +
                        '/' +
                        `${_dueDate.getMonth() + 1}`.padStart(2, '0') +
                        '/' +
                        (_dueDate.getFullYear() + 543);
                      let _dueDateCE =
                        `${_dueDate.getDate()}`.padStart(2, '0') + '/' + `${_dueDate.getMonth() + 1}`.padStart(2, '0') + '/' + _dueDate.getFullYear();

                      let _taxId = '';
                      let _taxNumberType = '';
                      let _branchNumber = '';
                      let _branchText = '';

                      _taxNumberType = 'OTHR';
                      _taxId = 'N/A';

                      let _customer = {
                        addressLineOne: selectedItems[i].customerAddress,
                        addressLineTwo: '',
                        branchNumber: _branchNumber,
                        branchText: _branchText,
                        email: selectedItems[i].customerEmail,
                        name: selectedItems[i].customerName,
                        phone: selectedItems[i].customerPhone !== '' ? selectedItems[i].customerPhone : '',
                        phoneCountryCode: '+66',
                        postcode: selectedItems[i].customerPostcode,
                        taxId: _taxId,
                        taxNumberType: _taxNumberType,
                        language: 'th',
                        unstructure: true,
                      };

                      let _itemsList = [];
                      let _line_items = invoice_detail.line_items;
                      let _discountPercent = invoice_detail.discount_percent;

                      for (let i = 0; i < _line_items.length; i++) {
                        let _quantity = _line_items[i].quantity * 1;
                        let _price = Math.round(_line_items[i].rate * 100) / 100;
                        let _discount = Math.round(_line_items[i].discount * 100) / 100;

                        let _anotherDiscount = Math.round(_price * (_discountPercent / 100) * 100) / 100;
                        _discount = _discount + _anotherDiscount;

                        let _percentVat = percentVat;
                        let _percentVatText = percentVatText;

                        if (_line_items[i].tax_percentage === 0) {
                          _percentVat = zeroVat;
                          _percentVatText = zeroVatText;
                        }

                        let _total = Math.round(_price * _quantity * 100) / 100 - _discount;

                        _itemsList.push({
                          number: i + 1 + '',
                          description: _line_items[i].name,
                          quantity: _quantity,
                          unitCode: '',
                          unitName: '-',
                          price: _price,
                          discount: 0,
                          total: _total,
                          percentVat: _percentVat,
                          percentVatText: _percentVatText,
                        });
                      }

                      invoice_detail.shipping_charge !== 0 &&
                        _itemsList.push({
                          number: _itemsList.length + 1,
                          description: 'ค่าขนส่ง',
                          quantity: 1,
                          unitCode: '',
                          unitName: '-',
                          price: invoice_detail.shipping_charge * 1,
                          discount: 0,
                          percentVat: invoice_detail.shipping_charge_tax ? percentVat : zeroVat,
                          percentVatText: invoice_detail.shipping_charge_tax ? percentVatText : zeroVatText,
                          total: invoice_detail.shipping_charge * 1,
                        });

                      let _vatTotal = invoice_detail.tax_total;
                      let _amountTotal = invoice_detail.total - _vatTotal;

                      let _vatPriceTotal = 0;
                      let _zeroVatPriceTotal = 0;
                      let _nonVatPriceTotal = 0;

                      _itemsList.forEach((item) => {
                        if (item.percentVat === percentVat) {
                          _vatPriceTotal = _vatPriceTotal + item.total;
                        } else if (item.percentVat === zeroVat) {
                          _zeroVatPriceTotal = _zeroVatPriceTotal + item.total;
                        } else if (item.percentVat === nonVat) {
                          _nonVatPriceTotal = _nonVatPriceTotal + item.total;
                        }
                      });

                      const promise_PDF_XML = await new Promise(async (resolve) => {
                        await axios
                          .post(
                            postUrl,
                            {
                              amountTotal: _amountTotal,
                              company: {
                                ...company,
                                includeVat: includeVat,
                              },
                              customer: _customer,
                              date: _date,
                              dateBE: _dateBE,
                              dateCE: _dateCE,
                              dueDate: _dueDate,
                              dueDateBE: _dueDateBE,
                              dueDateCE: _dueDateCE,
                              grandTotal: selectedItems[i].grandTotal,
                              nonVatPriceTotal: _nonVatPriceTotal,
                              zeroVatPriceTotal: _zeroVatPriceTotal,
                              vatPriceTotal: _vatPriceTotal,
                              items: _itemsList,
                              percentVat: percentVat,
                              receivedBy: '',
                              vatTotal: _vatTotal,
                              reIssue: false,
                              discount: 0,
                              number: _number,
                              includeVat: invoice_detail.is_inclusive_tax,
                              note: invoice_detail.notes,
                              reference: selectedItems[i].invoiceNumber,
                            },
                            {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'cid': companyId,
                              },
                            }
                          )
                          .then(
                            async (response) => {
                              console.log('Create document response: ', response);

                              let count = 0;

                              let interval = setInterval(async () => {
                                await axios
                                  .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                                    headers: {
                                      Authorization: 'Bearer ' + tokenResponse.accessToken,
                                      'cid': companyId,
                                      'Content-Type': 'application/json',
                                    },
                                  })
                                  .then((jobsResponse) => {
                                    count += 1;
                                    console.log('Jobs response: ', j, jobsResponse.data.status, count);

                                    if (jobsResponse.data.status === 'complete') {
                                      _items[j].status = 'successful';
                                      _items[j].etaxCreated = true;

                                      setItems([..._items]);

                                      clearInterval(interval);

                                      resolve(true);
                                    } else if (count === 300) {
                                      _items[j].status = 'fail';

                                      setItems([..._items]);

                                      clearInterval(interval);

                                      resolve(false);
                                    }
                                  });
                              }, 1000);
                            },
                            (error) => {
                              console.log('Error Create document response: ', error);
                              _items[j].status = 'fail';
                              setItems([..._items]);
                              resolve(false);
                            }
                          );
                      }); //promise

                      Promise.all([promise_PDF_XML]).then((value) => {
                        console.log(i, value);
                      });
                    }
                  });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setIsCreateDocumentComplete(true);
      setIsCreateDocument(false);
    }

    setIsCreateDocumentComplete(true);
    setIsCreateDocument(false);
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    setIsLoading(true);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  setCompany(_company);

                  if (_company.registerVat) {
                    setIncludeVat(true);
                    setRegisterVat(true);
                  } else {
                    setIncludeVat(false);
                    setRegisterVat(false);
                  }
                } else {
                  setCompany(response.data[0]);
  
                  if (response.data[0].registerVat) {
                    setIncludeVat(true);
                    setRegisterVat(true);
                  } else {
                    setIncludeVat(false);
                    setRegisterVat(false);
                  }

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/sources/zoho/organizations', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response_org) => {
              if (response_org.data.organizations[0] != null) {
                let organizationsList = [];

                response_org.data.organizations.forEach((organization) => {
                  organizationsList.push({
                    key: organization.organization_id,
                    text: organization.name,
                  });
                });

                setOrganizations(organizationsList);
                setOrganization({
                  key: organizationsList[0].key,
                  text: organizationsList[0].text,
                });

                setGetNewDocuments(true);
              }

              setIsLoading(false);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getNewDocuments && organization.key) {
      if (search) {
        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then(async (tokenResponse) => {
            // await axios
            //   .get(API_URL + '/sources/zoho/invoices/' + organization.key, {
            //     headers: {
            //       Authorization: 'Bearer ' + tokenResponse.accessToken,
            //     },
            //     params: {
            //       search: search,
            //     },
            //   })
            //   .then(
            //     async function (response) {
            const [response, responseAllDocs] = await Promise.all([
              await axios
                .get(API_URL + '/sources/zoho/invoices/' + organization.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                  },
                  params: {
                    search: search,
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log(response, true);

            sessionStorage.setItem('zoho-access-token', access_token);

            sessionStorage.setItem('zoho-refresh-token', refresh_token);

            sessionStorage.setItem('zoho-organizations', JSON.stringify(organizations));

            sessionStorage.setItem('zoho-organization-key', organization.key);
            sessionStorage.setItem('zoho-organization-text', organization.text);
            let invoices = response.data;

            if (primarySort === 'documentDate') {
              if (isSortedDescendingDocumentDate) {
                invoices.sort(function (a, b) {
                  var aa = a.date.split('-').reverse().join('-').split('-').reverse().join(),
                    bb = b.date.split('-').reverse().join('-').split('-').reverse().join();
                  return aa < bb ? 1 : aa > bb ? -1 : 0;
                });
              } else {
                invoices.sort(function (a, b) {
                  var aa = a.date.split('-').reverse().join('-').split('-').reverse().join(),
                    bb = b.date.split('-').reverse().join('-').split('-').reverse().join();
                  return aa < bb ? -1 : aa > bb ? 1 : 0;
                });
              }
            } else if (primarySort === 'number') {
              if (isSortedDescendingNumber) {
                invoices.sort((a, b) => {
                  if (b.invoice_number < a.invoice_number) {
                    return -1;
                  }
                  if (b.invoice_number > a.invoice_number) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                invoices.sort((a, b) => {
                  if (a.invoice_number < b.invoice_number) {
                    return -1;
                  }
                  if (a.invoice_number > b.invoice_number) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'customerName') {
              if (isSortedDescendingCustomerName) {
                invoices.sort((a, b) => {
                  let name_a = '';
                  if (a.company_name != null) {
                    name_a = a.company_name;
                  } else {
                    name_a = a.customer_name;
                  }

                  let name_b = '';
                  if (b.company_name != null) {
                    name_b = b.company_name;
                  } else {
                    name_b = b.customer_name;
                  }

                  if (name_b < name_a) {
                    return -1;
                  }
                  if (name_b > name_a) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                invoices.sort((a, b) => {
                  let name_a = '';
                  if (a.company_name != null) {
                    name_a = a.company_name;
                  } else {
                    name_a = a.customer_name;
                  }

                  let name_b = '';
                  if (b.company_name != null) {
                    name_b = b.company_name;
                  } else {
                    name_b = b.customer_name;
                  }

                  if (name_a < name_b) {
                    return -1;
                  }
                  if (name_a > name_b) {
                    return 1;
                  }
                  return 0;
                });
              }
            } else if (primarySort === 'grandTotal') {
              if (isSortedDescendingGrandTotal) {
                invoices.sort(function (a, b) {
                  return b.total - a.total;
                });
              } else {
                invoices.sort(function (a, b) {
                  return a.total - b.total;
                });
              }
            }

            //Page dropdown
            let _pageDropdown = [];

            for (let i = 1; i <= Math.ceil(invoices.length / pageSize); i++) {
              _pageDropdown.push({
                key: i,
                text: 'หน้า ' + i,
                iconProps: { iconName: 'Page' },
                onClick: () => {
                  console.log('Selected page: ' + i);
                  setCurrentPage(i);
                },
              });
            }

            setPageDropdown(_pageDropdown);

            if (currentPage > Math.ceil(invoices.length / pageSize)) {
              setCurrentPage(1);
            }

            setPageData(paginate(invoices.length, 1, pageSize, 10));
            setDocuments(invoices);

            const _docs = [];

            if (invoices.length > 0) {
              invoices.forEach((invoice) => {
                let _invoiceNumber = invoice.invoice_number;
                let _reference = '';
                if (invoice.reference_number) {
                  _reference = invoice.reference_number;
                }

                let _customerName = '';
                if (invoice.company_name != null) {
                  _customerName = invoice.company_name;
                } else {
                  _customerName = invoice.customer_name;
                }

                let _customerEmail = '';
                if (invoice.email != null) {
                  _customerEmail = invoice.email;
                }

                let _createdBy = '';
                if (invoice.created_by != null) {
                  _createdBy = invoice.created_by;
                }

                let _documentDate = invoice.date;
                let _dueDate = invoice.due_date;

                let _customerPhone = '';
                let _customerPostcode = '';

                let _address = '';

                if (invoice.phone != null) {
                  _customerPhone = invoice.phone;
                }

                if (invoice.billing_address != null) {
                  let billing_address = invoice.billing_address;

                  if (billing_address.address != null) {
                    _address = _address + billing_address.address;
                  }

                  if (billing_address.street2 != null) {
                    _address = _address + ' ' + billing_address.street2;
                  }

                  if (billing_address.city != null) {
                    _address = _address + ' ' + billing_address.city;
                  }

                  if (billing_address.state != null) {
                    _address = _address + ' ' + billing_address.state;
                  }

                  if (billing_address.country != null) {
                    _address = _address + ' ' + billing_address.country;
                  }

                  if (billing_address.zipcode != null) {
                    _customerPostcode = billing_address.zipcode;
                  } else {
                    _customerPostcode = '00000';
                  }
                }

                let _grandTotal = invoice.total;

                _docs.push({
                  key: invoice.invoice_id,
                  invoiceNumber: _invoiceNumber,
                  reference: _reference,
                  documentDate: _documentDate,
                  dueDate: _dueDate,
                  customerName: _customerName,
                  customerAddress: _address,
                  customerPostcode: _customerPostcode,
                  customerPhone: _customerPhone,
                  customerEmail: _customerEmail,
                  createdBy: _createdBy,
                  grandTotal: _grandTotal,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === _invoiceNumber),
                });
              });
            }
            setItems(_docs);
            setLoadDataComplete(true);
            setGetNewDocuments(false);
            //   },
            //   (error) => {
            //     console.log(error);

            //     setItems([]);
            //     setLoadDataComplete(true);
            //     setGetNewDocuments(false);
            //   }
            // );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      } else {
        console.log('getNewOrder Running!!!');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then(async (tokenResponse) => {
            // await axios
            //   .get(API_URL + '/sources/zoho/invoices', {
            //     headers: {
            //       Authorization: 'Bearer ' + tokenResponse.accessToken,
            //     },
            //     params: {
            //       date_start: from,
            //       date_end: to,
            //       status: orderType.key,
            //       organizationid: organization.key,
            //     },
            //   })
            //   .then(
            //     async function (response) {

            const [response, responseAllDocs] = await Promise.all([
              axios
                .get(API_URL + '/sources/zoho/invoices', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                  },
                  params: {
                    date_start: from,
                    date_end: to,
                    status: orderType.key,
                    organizationid: organization.key,
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                    'cid': companyId,
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log(response, true);
            if (response.data.error === 45) {
              setShowMessageBarError(true);

              setItems([]);
              setLoadDataComplete(true);
              setGetNewDocuments(false);
            } else {
              let invoices = response.data;

              setAllDocs(responseAllDocs.data);

              sessionStorage.setItem('zoho-access-token', access_token);

              sessionStorage.setItem('zoho-refresh-token', refresh_token);

              sessionStorage.setItem('zoho-organizations', JSON.stringify(organizations));

              sessionStorage.setItem('zoho-organization-key', organization.key);
              sessionStorage.setItem('zoho-organization-text', organization.text);

              if (primarySort === 'documentDate') {
                if (isSortedDescendingDocumentDate) {
                  invoices.sort(function (a, b) {
                    var aa = a.date.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.date.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? 1 : aa > bb ? -1 : 0;
                  });
                } else {
                  invoices.sort(function (a, b) {
                    var aa = a.date.split('-').reverse().join('-').split('-').reverse().join(),
                      bb = b.date.split('-').reverse().join('-').split('-').reverse().join();
                    return aa < bb ? -1 : aa > bb ? 1 : 0;
                  });
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  invoices.sort((a, b) => {
                    if (b.invoice_number < a.invoice_number) {
                      return -1;
                    }
                    if (b.invoice_number > a.invoice_number) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoices.sort((a, b) => {
                    if (a.invoice_number < b.invoice_number) {
                      return -1;
                    }
                    if (a.invoice_number > b.invoice_number) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  invoices.sort((a, b) => {
                    let name_a = '';
                    if (a.company_name != null) {
                      name_a = a.company_name;
                    } else {
                      name_a = a.customer_name;
                    }

                    let name_b = '';
                    if (b.company_name != null) {
                      name_b = b.company_name;
                    } else {
                      name_b = b.customer_name;
                    }

                    if (name_b < name_a) {
                      return -1;
                    }
                    if (name_b > name_a) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  invoices.sort((a, b) => {
                    let name_a = '';
                    if (a.company_name != null) {
                      name_a = a.company_name;
                    } else {
                      name_a = a.customer_name;
                    }

                    let name_b = '';
                    if (b.company_name != null) {
                      name_b = b.company_name;
                    } else {
                      name_b = b.customer_name;
                    }

                    if (name_a < name_b) {
                      return -1;
                    }
                    if (name_a > name_b) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  invoices.sort(function (a, b) {
                    return b.total - a.total;
                  });
                } else {
                  invoices.sort(function (a, b) {
                    return a.total - b.total;
                  });
                }
              }

              //Page dropdown
              let _pageDropdown = [];

              for (let i = 1; i <= Math.ceil(invoices.length / pageSize); i++) {
                _pageDropdown.push({
                  key: i,
                  text: 'หน้า ' + i,
                  iconProps: { iconName: 'Page' },
                  onClick: () => {
                    console.log('Selected page: ' + i);
                    setCurrentPage(i);
                  },
                });
              }

              setPageDropdown(_pageDropdown);

              if (currentPage > Math.ceil(invoices.length / pageSize)) {
                setCurrentPage(1);
              }

              setPageData(paginate(invoices.length, 1, pageSize, 10));
              setDocuments(invoices);

              const _docs = [];

              if (invoices.length > 0) {
                invoices.forEach((invoice) => {
                  let _invoiceNumber = invoice.invoice_number;
                  let _reference = '';
                  if (invoice.reference_number) {
                    _reference = invoice.reference_number;
                  }

                  let _customerName = '';
                  if (invoice.company_name != null) {
                    _customerName = invoice.company_name;
                  } else {
                    _customerName = invoice.customer_name;
                  }

                  let _customerEmail = '';
                  if (invoice.email != null) {
                    _customerEmail = invoice.email;
                  }

                  let _createdBy = '';
                  if (invoice.created_by != null) {
                    _createdBy = invoice.created_by;
                  }

                  let _documentDate = invoice.date;
                  let _dueDate = invoice.due_date;

                  let _customerPhone = '';
                  let _customerPostcode = '';

                  let _address = '';

                  if (invoice.phone != null) {
                    _customerPhone = invoice.phone;
                  }

                  if (invoice.billing_address != null) {
                    let billing_address = invoice.billing_address;

                    if (billing_address.address != null) {
                      _address = _address + billing_address.address;
                    }

                    if (billing_address.street2 != null) {
                      _address = _address + ' ' + billing_address.street2;
                    }

                    if (billing_address.city != null) {
                      _address = _address + ' ' + billing_address.city;
                    }

                    if (billing_address.state != null) {
                      _address = _address + ' ' + billing_address.state;
                    }

                    if (billing_address.country != null) {
                      _address = _address + ' ' + billing_address.country;
                    }

                    if (billing_address.zipcode != null) {
                      _customerPostcode = billing_address.zipcode;
                    } else {
                      _customerPostcode = '00000';
                    }
                  }

                  let _grandTotal = invoice.total;

                  _docs.push({
                    key: invoice.invoice_id,
                    invoiceNumber: _invoiceNumber,
                    reference: _reference,
                    documentDate: _documentDate,
                    dueDate: _dueDate,
                    customerName: _customerName,
                    customerAddress: _address,
                    customerPostcode: _customerPostcode,
                    customerPhone: _customerPhone,
                    customerEmail: _customerEmail,
                    createdBy: _createdBy,
                    grandTotal: _grandTotal,
                    etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === _invoiceNumber),
                  });
                });
              }
              setItems(_docs);
              setLoadDataComplete(true);
              setGetNewDocuments(false);
            }
            //   },
            //   (error) => {
            //     console.log(error);

            //     setItems([]);
            //     setLoadDataComplete(true);
            //     setGetNewDocuments(false);
            //   }
            // );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    sessionStorage.setItem('zoho-currentPage', currentPage);

    if (!getNewDocuments) {
      setLoadDataComplete(false);

      const _docs = [];

      if (documents.length > 0) {
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
          let _invoiceNumber = documents[i].invoice_number;
          let _reference = '';
          if (documents[i].reference_number) {
            _reference = documents[i].reference_number;
          }

          let _customerName = '';
          if (documents[i].company_name != null) {
            _customerName = documents[i].company_name;
          } else {
            _customerName = documents[i].customer_name;
          }

          let _customerEmail = '';
          if (documents[i].email != null) {
            _customerEmail = documents[i].email;
          }

          let _createdBy = '';
          if (documents[i].created_by != null) {
            _createdBy = documents[i].created_by;
          }

          let _documentDate = documents[i].date;
          let _dueDate = documents[i].due_date;

          let _customerPhone = '';
          let _customerPostcode = '';

          let _address = '';

          if (documents[i].phone != null) {
            _customerPhone = documents[i].phone;
          }

          if (documents[i].billing_address != null) {
            let billing_address = documents[i].billing_address;

            if (billing_address.address != null) {
              _address = _address + billing_address.address;
            }

            if (billing_address.street2 != null) {
              _address = _address + ' ' + billing_address.street2;
            }

            if (billing_address.city != null) {
              _address = _address + ' ' + billing_address.city;
            }

            if (billing_address.state != null) {
              _address = _address + ' ' + billing_address.state;
            }

            if (billing_address.country != null) {
              _address = _address + ' ' + billing_address.country;
            }

            if (billing_address.zipcode != null) {
              _customerPostcode = billing_address.zipcode;
            } else {
              _customerPostcode = '00000';
            }
          }

          let _grandTotal = documents[i].total;

          _docs.push({
            key: documents[i].invoice_id,
            invoiceNumber: _invoiceNumber,
            reference: _reference,
            documentDate: _documentDate,
            dueDate: _dueDate,
            customerName: _customerName,
            customerAddress: _address,
            customerPostcode: _customerPostcode,
            customerPhone: _customerPhone,
            customerEmail: _customerEmail,
            createdBy: _createdBy,
            grandTotal: _grandTotal,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.reference === _invoiceNumber),
          });
        }

        setItems(_docs);
        setLoadDataComplete(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setIsCreateDocumentComplete(false);

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const handleSort = (primary, isSorted) => {
    if (items.length > 0) {
      let _items = selection.getItems();
      let _selectedItems = selection.getSelection();
      console.log('selection: ', selection.getSelection());

      for (let i = 0; i < _selectedItems.length; i++) {
        console.log('select item key: ', _selectedItems[i].key);
        _selectedItems[i].status = '';

        for (let j = 0; j < _items.length; j++) {
          if (_items[j].key === _selectedItems[i].key) {
            console.log('item key: ', _items[j].key);
            _items[j].status = '';
            _selectedItems[i].status = '';
          }
        }
      }

      selection.setAllSelected(false);

      if (primary === 'customerName') {
        if (isSorted) {
          _items.sort((a, b) => {
            if (b.customerName < a.customerName) {
              return -1;
            }
            if (b.customerName > a.customerName) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        } else {
          _items.sort((a, b) => {
            if (a.customerName < b.customerName) {
              return -1;
            }
            if (a.customerName > b.customerName) {
              return 1;
            }
            return 0;
          });

          setItems(_items);
        }
      } else if (primary === 'vatTotal') {
        if (isSorted) {
          _items.sort(function (a, b) {
            return b.vatTotal - a.vatTotal;
          });

          setItems(_items);
        } else {
          _items.sort(function (a, b) {
            return a.vatTotal - b.vatTotal;
          });

          setItems(_items);
        }
      } else if (primary === 'grandTotal') {
        if (isSorted) {
          _items.sort(function (a, b) {
            return b.grandTotal - a.grandTotal;
          });

          setItems(_items);
        } else {
          _items.sort(function (a, b) {
            return a.grandTotal - b.grandTotal;
          });

          setItems(_items);
        }
      }
    }
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          {showMessageBarError && (
            <MessageBar
              messageBarType={MessageBarType.error}
              isMultiline={false}
              onDismiss={() => setShowMessageBarError(false)}
              dismissButtonAriaLabel="Close"
              style={{
                fontSize: 14,
              }}
            >
              {t('zoho.messageBar')}
            </MessageBar>
          )}

          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('zoho.header')}</h2>
              {!loadDataComplete && (
                <div>
                  <Spinner
                    label={t('zoho.loading')}
                    labelPosition="right"
                    style={{
                      marginTop: '21px',
                      marginBottom: '2px',
                    }}
                  />
                  <br />
                </div>
              )}
            </center>
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <h3>{t('zoho.subHeader')}</h3>
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="prefixNumber"
                  label={t('zoho.prefixNumber')}
                  description={t('zoho.prefixNumberDescription')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setPrefixNumber(e.target.value);
                  }}
                  value={prefixNumber}
                  disabled={isCreateDocument}
                />
                <TextField
                  name="countingNumber"
                  label={t('zoho.countingNumber')}
                  description={countingNumber.length === 0 ? '' : t('zoho.countingNumberDesciption')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    if (!/\D/.test(e.target.value)) {
                      setCountingNumber(e.target.value);
                    }
                  }}
                  value={countingNumber}
                  errorMessage={countingNumber.length === 0 ? t('zoho.countingNumberErrorMessage') : ''}
                  disabled={isCreateDocument}
                  required
                />
              </Stack>

              {countingNumber.length > 0 ? (
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                  {!isCreateDocument ? (
                    <h4>
                      {t('zoho.startDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  ) : (
                    <h4>
                      {t('zoho.endDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  )}
                </Stack>
              ) : (
                <br />
              )}
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('zoho.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('zoho.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('zoho.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('zoho.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('RECEIPT');
                        }
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('zoho.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('INVOICE-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('zoho.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('zoho.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async () => {
                        if (countingNumber.length > 0) {
                          setIsCreateDocument(true);
                          await createSelectedDocuments('TAXINVOICE');
                        }
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('zoho.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('zoho.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('zoho.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <CommandBar
                  items={commandBarItems}
                  overflowItems={overflowItems}
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                />
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        width: 250,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('zoho.searchBoxPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value.replace(/\s/g, '') : '')}
                    onClear={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    onSearch={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                    value={search}
                  />
                  <PrimaryButton
                    disabled={!loadDataComplete || !search}
                    styles={{
                      root: {
                        marginTop: 6,
                        marginRight: 8,
                        width: 90,
                      },
                    }}
                    text={t('zoho.search')}
                    onClick={() => {
                      setLoadDataComplete(false);
                      setDocuments([]);
                      setItems([]);
                      setGetNewDocuments(true);

                      setCurrentPage(1);
                    }}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(documents.length === 0 || documents.length < pageSize) && !(!loadDataComplete || isCreateDocument) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{
                  iconName: 'DoubleChevronLeft',
                }}
                previousPageIconProps={{
                  iconName: 'ChevronLeft',
                }}
                nextPageIconProps={{
                  iconName: 'ChevronRight',
                }}
                lastPageIconProps={{
                  iconName: 'DoubleChevronRight',
                }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('zoho.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default Zoho;
