import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import PdfAllPages from '../etax/documents/pdf/view/PdfAllPages';
import axios from 'axios';
import { useMsal } from '@azure/msal-react';
import { silentRequest } from '../../authConfig';

import paginate from 'jw-paginate';

import {
  Selection,
  CheckboxVisibility,
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Calendar,
  DateRangeType,
  SelectionMode,
  mergeStyleSets,
  ActionButton,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
  mergeStyles,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  DocumentCardType,
  Text,
  ContextualMenu,
  ContextualMenuItemType,
  IconButton,
} from '@fluentui/react';

// import { getFileTypeIconProps } from '@uifabric/file-type-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';

import html2canvas from 'html2canvas';
import { KTBTextFile } from './ExportKTB';
var JSZip = require('jszip');

const API_URL = process.env.REACT_APP_API_URL;

const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

//Dialog Style
// Success dialog style
const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: '0 60px',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
  blue: [
    {
      color: '#0078D4',
    },
    iconClass,
  ],
});

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '30%',
      minWidth: '315px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

function formatDateTime(date) {
  let d = new Date(date.toLocaleString('en-GB', { timeZone: 'Asia/Bangkok' })),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' + d.getHours(),
    minute = '' + d.getMinutes(),
    second = '' + d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (minute.length < 2) minute = '0' + minute;
  if (second.length < 2) second = '0' + second;

  return [year, month, day, hour, minute, second].join('');
}

const AllExpenses = () => {
  console.log('Home render!!!');

  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { instance } = useMsal();
  const account = instance.getAllAccounts()[0];

  const companyId = sessionStorage.getItem('companyId') || '';

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(true);
  const totalProcessingDocuments = 0
  const [pageData, setPageData] = useState({});

  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(sessionStorage.getItem('expenseDocument-currentPage') * 1 || 1);

  const printRef = useRef();
  const [fileName, setFileName] = useState('');
  const [url, setUrl] = useState('');

  //Display Summary
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [date, setDate] = useState(
    sessionStorage.getItem('expenseDocument-date') ? new Date(sessionStorage.getItem('expenseDocument-date')) : new Date()
  );

  const [primarySort, setPrimarySort] = useState('createdTime');
  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(true);
  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(false);
  const [isSortedDescendingDocumentDate, setIsSortedDescendingDocumentDate] = useState(false);
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(false);
  const [isSortedDescendingVatTotal, setIsSortedDescendingVatTotal] = useState(false);
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('expenseDocuments.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('expenseDocuments.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const getMonth = (goBack) => {
    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);
    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState(
    (sessionStorage.getItem('expenseDocument-queryMonth-key') && {
      key: sessionStorage.getItem('expenseDocument-queryMonth-key'),
      text: sessionStorage.getItem('expenseDocument-queryMonth-text'),
    }) || {
      key: getMonth(0).key,
      text: t('documents.thisMonth'),
    }
  );

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);
    console.log('First day: ', formatDate(_firstDay));

    return formatDate(_firstDay);
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);
    console.log('Last day: ', formatDate(_lastDay));

    return formatDate(_lastDay);
  };

  const formatDate = (d) => {
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  const [from, setFrom] = useState(sessionStorage.getItem('expenseDocument-from') || getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(sessionStorage.getItem('expenseDocument-to') || getTo(getMonth(0).month, getMonth(0).year));

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('documents.thisMonth');

    let monthNames = [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      disabled: !loadDataComplete,
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('documents.months01'),
                    t('documents.months02'),
                    t('documents.months03'),
                    t('documents.months04'),
                    t('documents.months05'),
                    t('documents.months06'),
                    t('documents.months07'),
                    t('documents.months08'),
                    t('documents.months09'),
                    t('documents.months10'),
                    t('documents.months11'),
                    t('documents.months12'),
                  ];

                  setDate(_date);

                  sessionStorage.setItem('expenseDocument-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  sessionStorage.setItem(
                    'expenseDocument-queryMonth-key',
                    adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0')
                  );
                  sessionStorage.setItem('expenseDocument-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  sessionStorage.setItem('expenseDocument-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  sessionStorage.setItem('expenseDocument-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setDocuments([]);
                  setItems([]);
                  setGetNewDocuments(true);

                  setTotalDocuments(0);
                  setTotalVat(0);
                  setTotalGrand(0);

                  setCurrentPage(1);

                  setPrimarySort('createdTime');
                  setIsSortedDescendingCreatedTime(true);
                  setIsSortedDescendingDocumentDate(false);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'e-withholding-tax',
      text: t('expenseDocuments.eWithholdingTaxDownload'),
      iconProps: { iconName: 'TextDocument' },
      disabled: !loadDataComplete || items.length <= 0 || !selectedItems.length,
      subMenuProps: {
        items: [
          {
            key: 'e-withholding-tax-ktb',
            text: 'KTB Universal Data Entry (.txt)',
            iconProps: { iconName: 'TextDocument' },
            //   preferMenuTargetAsEventTarget: true,
            onClick: () => {
              if (selectedItems.length) {
                KTBTextFile(selectedItems);
              }
            },
          },
        ],
      },
    },
    {
      key: 'refresh',
      text: t('documents.refresh'),
      iconProps: { iconName: 'Refresh' },
      disabled: !loadDataComplete,
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(true);
        setIsSortedDescendingDocumentDate(false);
        setIsSortedDescendingNumber(false);
        setIsSortedDescendingCustomerName(false);
        setIsSortedDescendingVatTotal(false);
        setIsSortedDescendingGrandTotal(false);
      },
    },
  ];

  const toThaiDateString = (isoDateTime) => {
    let date = '';
    date = new Date(isoDateTime);

    let year = date.getFullYear() + 543;
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let numOfDay = date.getDate().toString().padStart(2, '0');

    let hour = date.getHours().toString().padStart(2, '0');
    let minutes = date.getMinutes().toString().padStart(2, '0');
    let second = date.getSeconds().toString().padStart(2, '0');

    return `${numOfDay}/${month}/${year} ${hour}:${minutes}:${second} น.`;
  };

  // const sendEmail = (fileId, email, copyEmail) => {
  //   const re =
  //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //   if (email !== '' && email !== null && email !== undefined && re.test(String(email).toLowerCase())) {
  //     if (
  //       (isClickCopyEmail && copyEmail !== '' && copyEmail !== null && copyEmail !== undefined && re.test(String(copyEmail).toLowerCase())) ||
  //       !isClickCopyEmail
  //     ) {
  //       setIsClickEmail(false);
  //       setErrorMessageEmail('');

  //       setIsClickCopyEmail(false);
  //       setErrorMessageCopyEmail('');

  //       instance
  //         .acquireTokenSilent({ ...silentRequest, account: account })
  //         .then((tokenResponse) => {
  //           axios
  //             .post(
  //               API_URL + '/emails/send',
  //               {
  //                 fileId: fileId,
  //                 email: email,
  //                 copyEmail: copyEmail,
  //               },
  //               {
  //                 headers: {
  //                   Authorization: 'Bearer ' + tokenResponse.accessToken,
  //                   'Content-Type': 'application/json',
  //                 },
  //               }
  //             )
  //             .then(
  //               (response) => {
  //                 console.log(response);
  //                 setIsClickEmail(false);
  //                 window.scrollTo(0, 0);
  //               },
  //               (error) => {
  //                 console.log(error);
  //                 setIsClickEmail(false);
  //                 window.scrollTo(0, 0);
  //               }
  //             );
  //         })
  //         .catch((error) => {
  //           //Acquire token silent failure, and send an interactive request
  //           console.log(error);
  //           instance.acquireTokenRedirect(silentRequest);
  //         });
  //     } else {
  //       setErrorMessageCopyEmail('กรุณากรอกอีเมลที่ถูกต้อง');
  //     }
  //   } else {
  //     setErrorMessageEmail('กรุณากรอกอีเมลที่ถูกต้อง');
  //   }
  // };

  // const sendSms = (fileId, mobile) => {
  //   const re = /^0[1-9]{1}[0-9]{8}/;

  //   if (mobile !== '' && mobile !== null && mobile !== undefined && re.test(String(mobile))) {
  //     setIsClickSms(false);
  //     setErrorMessageSms('');

  //     instance
  //       .acquireTokenSilent({ ...silentRequest, account: account })
  //       .then((tokenResponse) => {
  //         axios
  //           .post(
  //             API_URL + '/etax/sms/',
  //             {
  //               fileId: fileId,
  //               mobile: mobile,
  //             },
  //             {
  //               headers: {
  //                 Authorization: 'Bearer ' + tokenResponse.accessToken,
  //                 'Content-Type': 'application/json',
  //               },
  //             }
  //           )
  //           .then(
  //             (response) => {
  //               console.log(response);
  //               setIsClickSms(false);
  //               window.scrollTo(0, 0);
  //             },
  //             (error) => {
  //               console.log(error);
  //               setIsClickSms(false);
  //               window.scrollTo(0, 0);
  //             }
  //           );
  //       })
  //       .catch((error) => {
  //         //Acquire token silent failure, and send an interactive request
  //         console.log(error);
  //         instance.acquireTokenRedirect(silentRequest);
  //       });
  //   } else {
  //     setErrorMessageSms('กรุณากรอกเบอร์มือถือที่ถูกต้อง');
  //   }
  // };

  useEffect(() => {
    console.log('userEffect getNewDocuments Call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        // Do something with the tokenResponse
        console.log('Token response: ', tokenResponse);

        const [responseExpenseNote] = await Promise.all([
          axios
            .get(API_URL + '/expenses/expense-notes?from=' + from + '&to=' + to, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'cid': companyId,
              },
            })
            .catch((error) => console.log(error)),
        ]);

        console.log('response expense note', responseExpenseNote);

        let expenseNotes = responseExpenseNote.data;

        console.log('Docs number: ', expenseNotes.length);

        setTotalDocuments(expenseNotes.length);

        //Sorting
        if (expenseNotes && expenseNotes.length > 0) {
          if (primarySort === 'createdTime') {
            if (isSortedDescendingCreatedTime) {
              expenseNotes.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.createdTime) - new Date(a.createdTime);
              });
            } else {
              expenseNotes.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.createdTime) - new Date(b.createdTime);
              });
            }
          } else if (primarySort === 'number') {
            if (isSortedDescendingNumber) {
              expenseNotes.sort((a, b) => {
                return (
                  /[A-Za-z]/.test(b.data.number) - /[A-Za-z]/.test(a.data.number) ||
                  (b.data.number.toUpperCase() < a.data.number.toUpperCase() ? -1 : b.data.number.toUpperCase() > a.data.number.toUpperCase() ? 1 : 0)
                );
              });
            } else {
              expenseNotes.sort((a, b) => {
                return (
                  /[A-Za-z]/.test(a.data.number) - /[A-Za-z]/.test(b.data.number) ||
                  (a.data.number.toUpperCase() < b.data.number.toUpperCase() ? -1 : a.data.number.toUpperCase() > b.data.number.toUpperCase() ? 1 : 0)
                );
              });
            }
          } else if (primarySort === 'customerName') {
            if (isSortedDescendingCustomerName) {
              expenseNotes.sort((a, b) => {
                if (b.data.customer.name < a.data.customer.name) {
                  return -1;
                }
                if (b.data.customer.name > a.data.customer.name) {
                  return 1;
                }
                return 0;
              });
            } else {
              expenseNotes.sort((a, b) => {
                if (a.data.customer.name < b.data.customer.name) {
                  return -1;
                }
                if (a.data.customer.name > b.data.customer.name) {
                  return 1;
                }
                return 0;
              });
            }
          } else if (primarySort === 'documentDate') {
            if (isSortedDescendingDocumentDate) {
              expenseNotes.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(b.data.date) - new Date(a.data.date);
              });
            } else {
              expenseNotes.sort(function (a, b) {
                // Turn your strings into dates, and then subtract them
                // to get a value that is either negative, positive, or zero.
                return new Date(a.data.date) - new Date(b.data.date);
              });
            }
          } else if (primarySort === 'vatTotal') {
            if (isSortedDescendingVatTotal) {
              expenseNotes.sort(function (a, b) {
                return b.data.vatTotal - a.data.vatTotal;
              });
            } else {
              expenseNotes.sort(function (a, b) {
                return a.data.vatTotal - b.data.vatTotal;
              });
            }
          } else if (primarySort === 'grandTotal') {
            if (isSortedDescendingGrandTotal) {
              expenseNotes.sort(function (a, b) {
                return b.data.grandTotal - a.data.grandTotal;
              });
            } else {
              expenseNotes.sort(function (a, b) {
                return a.data.grandTotal - b.data.grandTotal;
              });
            }
          }
        }

        //set page
        setPageData(paginate(expenseNotes.length, currentPage, pageSize, 10));

        console.log('expenseNote_documents', expenseNotes);

        setDocuments(expenseNotes);

        const _docs = [];

        if (expenseNotes.length > 0) {
          for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, expenseNotes.length); i++) {
            let createdTime = toThaiDateString(expenseNotes[i].createdTime);

            let _companyEmail = '';

            let _customerEmail = '';
            let _customerPhone = '';
            let _amountTotal = 0;
            let _vatTotal = 0;
            let _grandTotal = 0;

            if (
              expenseNotes[i].data.company &&
              expenseNotes[i].data.company.email &&
              expenseNotes[i].data.company.email !== null &&
              expenseNotes[i].data.company.email !== undefined
            ) {
              _companyEmail = expenseNotes[i].data.company.email;
            }

            if (
              expenseNotes[i].data.customer &&
              expenseNotes[i].data.customer.email &&
              expenseNotes[i].data.customer.email !== null &&
              expenseNotes[i].data.customer.email !== undefined
            ) {
              _customerEmail = expenseNotes[i].data.customer.email;
            }

            if (
              expenseNotes[i].data.customer &&
              expenseNotes[i].data.customer.phone &&
              expenseNotes[i].data.customer.phone !== null &&
              expenseNotes[i].data.customer.phone !== undefined
            ) {
              _customerPhone = expenseNotes[i].data.customer.phone;
            }

            if (expenseNotes[i].type === 'WITHHOLDING-TAX') {
              _amountTotal = '-';
              _vatTotal = '-';
              _grandTotal = '-';
            } else {
              if (expenseNotes[i].data.amountTotal) {
                _amountTotal = expenseNotes[i].data.amountTotal;
              }

              if (expenseNotes[i].data.vatTotal) {
                _vatTotal = expenseNotes[i].data.vatTotal;
              }

              if (expenseNotes[i].data.grandTotal) {
                _grandTotal = expenseNotes[i].data.grandTotal;
              }
            }

            _docs.push({
              key: expenseNotes[i].id,
              name: expenseNotes[i].type,
              createdBy: expenseNotes[i].createdBy,
              createdTime: createdTime,
              createdTimeValue: expenseNotes[i].createdTime,
              type: expenseNotes[i].type,
              product: expenseNotes[i].product,
              number: expenseNotes[i].data.number,
              companyEmail: _companyEmail,
              customerName: expenseNotes[i].data.customer.name,
              customerEmail: _customerEmail,
              customerPhone: _customerPhone,
              amountTotal: _amountTotal,
              vatTotal: _vatTotal,
              grandTotal: _grandTotal,
              data: expenseNotes[i].data,
              documentDate: expenseNotes[i].data.dateBE,
              whtTotal: expenseNotes[i].data.whtTotal || 0,
            });
          }

          let _totalVat = 0;
          let _totalGrand = 0;

          for (let i = 0; i < expenseNotes.length; i++) {
            _totalVat = _totalVat + expenseNotes[i].data.vatTotal;
            _totalGrand = _totalGrand + expenseNotes[i].data.grandTotal;
          }

          setTotalVat(_totalVat);
          setTotalGrand(_totalGrand);
        }

        console.log('Docs data', _docs);
        setItems(_docs);

        setCurrentPage(1);
        setLoadDataComplete(true);
        setGetNewDocuments(false);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let createdTime = toThaiDateString(documents[i].createdTime);

        let _customerEmail = '';
        let _customerPhone = '';
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;

        if (
          documents[i].data.customer &&
          documents[i].data.customer.email &&
          documents[i].data.customer.email !== null &&
          documents[i].data.customer.email !== undefined
        ) {
          _customerEmail = documents[i].data.customer.email;
        }

        if (
          documents[i].data.customer &&
          documents[i].data.customer.phone &&
          documents[i].data.customer.phone !== null &&
          documents[i].data.customer.phone !== undefined
        ) {
          _customerPhone = documents[i].data.customer.phone;
        }

        if (documents[i].type === 'WITHHOLDING-TAX') {
          _amountTotal = '-';
          _vatTotal = '-';
          _grandTotal = '-';
        } else {
          if (documents[i].data.amountTotal) {
            _amountTotal = documents[i].data.amountTotal;
          }

          if (documents[i].data.vatTotal) {
            _vatTotal = documents[i].data.vatTotal;
          }

          if (documents[i].data.grandTotal) {
            _grandTotal = documents[i].data.grandTotal;
          }
        }

        _docs.push({
          key: documents[i].id,
          name: documents[i].type,
          createdBy: documents[i].createdBy,
          createdTime: createdTime,
          createdTimeValue: documents[i].createdTime,
          type: documents[i].type,
          product: documents[i].product,
          number: documents[i].data.number,
          customerName: documents[i].data.customer.name,
          customerEmail: _customerEmail,
          customerPhone: _customerPhone,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          data: documents[i].data,
          documentDate: documents[i].data.dateBE,
          whtTotal: documents[i].data.whtTotal || 0,
        });
      }
      console.log('Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const download = (item) => {
    console.log(item);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(API_URL + '/expenses/expense-notes/pdf', item.data, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);
              const time = formatDateTime(item.createdTimeValue);
              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '') + '.pdf';
              console.log('file name: ', fileName);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    if (item.fileExtension === 'pdf') {
      openFile_pdf(item);
    } else if (item.fileExtension === 'xml') {
      openFile_xml(item);
    }
  };

  const openFile_pdf = (item) => {
    history.push({
      pathname: '/expenses/pdf/view',
      state: {
        item: item,
      },
    });
  };

  const downloadPNG = async (item) => {
    console.log('download PNG');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .post(API_URL + '/expenses/expense-notes/pdf', item.data, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
            responseType: 'blob',
          })
          .then(
            (response) => {
              console.log(response.headers);

              const time = formatDateTime(item.createdTimeValue);

              let fileName = time + '-' + item.type + '-' + item.number.replace(/\//g, '');

              setFileName(fileName);

              console.log('file name: ', fileName);

              const url = window.URL.createObjectURL(new Blob([response.data]));
              setUrl(url);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  async function getPNGorZip() {
    setTimeout(async () => {
      try {
        const zip = new JSZip();

        console.log('download...');
        const pdfElements = printRef.current.getElementsByClassName('react-pdf__Page__canvas');

        if (pdfElements) {
          if (pdfElements.length > 1) {
            const promises = [];

            async function getCanvasBlobToPNG(elements, index) {
              const canvas = await html2canvas(elements[index]);

              return new Promise(function (resolve, reject) {
                canvas.toBlob(function (blob) {
                  resolve(zip.file(`${index + 1}.png`, blob));
                });
              });
            }

            for (let i = 0; i < pdfElements.length; i++) {
              promises.push(getCanvasBlobToPNG(pdfElements, i));
            }

            Promise.all(promises).then(async () => {
              const blob = await zip.generateAsync({
                type: 'blob',
              });

              const link = document.createElement('a');
              link.setAttribute('href', window.URL.createObjectURL(blob));
              link.setAttribute('download', fileName + '.zip');
              link.style.display = 'none';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            });
          } else {
            const canvas = await html2canvas(pdfElements[0]);

            const data = canvas.toDataURL('image/png');

            const link = document.createElement('a');

            if (typeof link.download === 'string') {
              link.href = data;
              link.download = fileName + '.png';

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              setUrl('');
            } else {
              window.open(data);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }, 1000);
  }

  useEffect(() => {
    if (url) {
      getPNGorZip();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const openFile_xml = (item) => {
    history.push({
      pathname: '/etax/documents/xml/view',
      state: {
        item: item,
      },
    });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const reCreateDocument = async (item) => {
    console.log(item);

    let documentPath = '/expenses/expense-notes';

    switch (item.type) {
      case 'EXPENSE-NOTE':
        documentPath = '/expenses/expense-notes';
        break;
      default:
        break;
    }

    history.push({
      pathname: documentPath,
      //search: '?update=true',  // query string
      state: {
        command: 'reCreate',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        createdTime: item.data.createdTime,
        id: item.data.id,
        name: item.data.name,
        payment: item.data.payment,
        dueDate: item.data.dueDate,
        dueDateBE: item.data.dueDateBE,
        dueDateCE: item.data.dueDateCE,
        reference: item.data.reference,
        taxInvoice: item.data.taxInvoice,
        paymentList: item.data.paymentList,
        backUrl: '/expenses/all',
      },
    });
  };

  const editDocument = async (item) => {
    console.log(item);

    let documentPath = '/expenses/expense-notes';

    switch (item.type) {
      case 'EXPENSE-NOTE':
        documentPath = '/expenses/expense-notes';
        break;
      default:
        break;
    }

    history.push({
      pathname: documentPath,
      //search: '?update=true',  // query string
      state: {
        command: 'editFromDocument',
        company: item.data.company,
        customer: item.data.customer,
        number: item.data.number,
        date: item.data.date,
        dateBE: item.data.dateBE,
        dateCE: item.data.dateCE,
        discount: item.data.discount,
        items: item.data.items,
        amountTotal: item.data.amountTotal,
        vatTotal: item.data.vatTotal,
        grandTotal: item.data.grandTotal,
        note: item.data.note,
        receivedBy: item.data.receivedBy,
        percentVat: item.data.percentVat,
        includeVat: item.data.includeVat,
        createdTime: item.createdTime,
        id: item.key,
        taxInvoice: item.data.taxInvoice,
        name: item.name,
        dueDate: item.data.dueDate,
        dueDateBE: item.data.dueDateBE,
        dueDateCE: item.data.dueDateCE,
        reference: item.data.reference,
        paymentList: item.data.paymentList,
        backUrl: '/expenses/all',
      },
    });
  };

  const deleteFile = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .delete(API_URL + '/expenses/expense-notes/' + item.key, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              'cid': companyId,
            },
          })
          .then(
            (response) => {
              console.log(response);
              setLoadDataComplete(false);
              setGetNewDocuments(true);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        // Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const columns = [
    {
      key: 'column1',
      name: t('expenseDocuments.documentDate'),
      fieldName: 'documentDate',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      isSorted: primarySort === 'documentDate' ? true : false,
      isSortedDescending: isSortedDescendingDocumentDate,
      isSortedAscending: !isSortedDescendingDocumentDate,
      onColumnClick: () => {
        console.log('Sort documentDate!');
        setPrimarySort('documentDate');
        setIsSortedDescendingDocumentDate(!isSortedDescendingDocumentDate);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.documentDate}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('expenseDocuments.number'),
      fieldName: 'number',
      minWidth: 80,
      maxWidth: 150,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <ActionButton
            title="ดาวน์โหลด"
            className={classNames.fileIconImg}
            onClick={() => {
              openFile(item);
            }}
          >
            {item.number}
          </ActionButton>
        );
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('expenseDocuments.customerName'),
      fieldName: 'customerName',
      minWidth: 100,
      maxWidth: 220,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort customerName!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('expenseDocuments.vatColumn'),
      fieldName: 'vatTotal',
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      isSorted: primarySort === 'vatTotal' ? true : false,
      isSortedDescending: isSortedDescendingVatTotal,
      isSortedAscending: !isSortedDescendingVatTotal,
      onColumnClick: () => {
        console.log('Sort vatTotal!');
        setPrimarySort('vatTotal');
        setIsSortedDescendingVatTotal(!isSortedDescendingVatTotal);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('expenseDocuments.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        console.log('Sort grandTotal!');
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('expenseDocuments.whtTotal'),
      fieldName: 'whtTotal',
      minWidth: 50,
      maxWidth: 80,
      isResizable: true,
      isSorted: primarySort === 'whtTotal' ? true : false,
      onColumnClick: () => {
        console.log('Sort whtTotal!');
        setPrimarySort('whtTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {(item?.whtTotal || 0).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('expenseDocuments.paymentTotal'),
      fieldName: 'paymentTotal',
      minWidth: 70,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'paymentTotal' ? true : false,
      onColumnClick: () => {
        console.log('Sort paymentTotal!');
        setPrimarySort('paymentTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {(item.grandTotal - (item?.whtTotal || 0)).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: t('expenseDocuments.file'),
      fieldName: 'file',
      minWidth: 100,
      maxWidth: 100,
      onColumnClick: '',
      onRender: (item) => (
        <Stack horizontal>
          <IconButton
            onClick={() => {
              openFile_pdf(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                extension: 'pdf',
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
          <IconButton
            onClick={() => {
              downloadPNG(item);
            }}
            iconProps={{
              ...getFileTypeIconProps({
                size: 32,
                imageFileType: 'svg',
              }),
            }}
          />
        </Stack>
      ),
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'command',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        return (
          <DefaultButton
            text={t('expenseDocuments.select')}
            menuProps={{
              items: [
                {
                  key: 'open',
                  text: t('expenseDocuments.open'),
                  iconProps: { iconName: 'OpenFile' },
                  onClick: () => {
                    editDocument(item);
                  },
                },
                {
                  key: 'download-expense-note',
                  text: t('expenseDocuments.download'),
                  iconProps: { iconName: 'DownloadDocument' },
                  onClick: () => {
                    download(item);
                  },
                },
                {
                  key: 'divider_1',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'reCreate',
                  text: t('expenseDocuments.reCreateDocument'),
                  iconProps: { iconName: 'PageAdd' },
                  onClick: () => {
                    reCreateDocument(item);
                  },
                },
                {
                  key: 'withholding-taxs',
                  text: t('expenseDocuments.withholdingTaxs'),
                  iconProps: { iconName: 'PageAdd' },
                  onClick: () => {
                    history.push({
                      pathname: '/documents/withholding-taxs',
                      state: {
                        ...item.data,
                        command: 'ExpenseDocuments',
                        backUrl: '/expenses/all',
                      },
                    });
                  },
                },
                {
                  key: 'divider_2',
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: 'delete',
                  text: t('expenseDocuments.delete'),
                  iconProps: { iconName: 'Delete' },
                  onClick: () => {
                    let _itemType = item.name;
                    if (i18n.language === 'en') {
                      _itemType = 'Expense Note';
                    }

                    setDialogConfirm((prev) => ({
                      ...prev,
                      isClick: true,
                      header: t('documents.deleteConfirmHeader'),
                      message: `${t('documents.deleteConfirm1')}${_itemType}${t('documents.deleteConfirm2')}${item.number}${t(
                        'documents.deleteConfirm3'
                      )}`,
                      onConfirm: () => {
                        deleteFile(item);
                        setDialogConfirm((prev) => ({
                          ...prev,
                          isClick: false,
                        }));
                      },
                      onCancel: () =>
                        setDialogConfirm((prev) => ({
                          ...prev,
                          isClick: false,
                        })),
                    }));
                  },
                  // {
                  //   deleteFile(item);
                  // }
                },
              ],
              shouldFocusOnMount: true,
              directionalHintFixed: true,
            }}
            // Optional callback to customize menu rendering
            menuAs={(props) => <ContextualMenu {...props} />}
          />
        );
      },
    },
  ];

  const isCompactMode = false;

  return (
    <div style={homeStyles}>
      <Stack>
        <center>
          <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('expenseDocuments.header')}</h2>
        </center>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
          <DocumentCard aria-label={t('expenseDocuments.totalDocuments')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Documentation' }} title={t('expenseDocuments.totalDocuments')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('expenseDocuments.totalDocuments')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalDocuments} separator="," duration={1} /> {t('expenseDocuments.document')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('expenseDocuments.vat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('expenseDocuments.vat')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('expenseDocuments.vat')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('expenseDocuments.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>

          <DocumentCard aria-label={t('expenseDocuments.expenseAmount')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
            <DocumentCardLogo {...{ logoIcon: 'Money' }} title={t('expenseDocuments.expenseAmount')} />
            <DocumentCardDetails>
              <DocumentCardTitle title={t('expenseDocuments.expenseAmount')} styles={cardStyles.header} />
              <Text styles={cardStyles.amount}>
                <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('expenseDocuments.thb')}
              </Text>
            </DocumentCardDetails>
          </DocumentCard>
        </Stack>
      </Stack>

      <Stack>
        <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
          <div>
            <br />
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              text={t('expenseDocuments.expenseNote')}
              onClick={() => {
                history.push('/expenses/expense-notes');
              }}
            />
          </div>
        </Stack>
      </Stack>

      {totalProcessingDocuments > 0 && (
        <div>
          <br />
          <Spinner label={t('expenseDocuments.signProcessing')} size={SpinnerSize.large} />
        </div>
      )}
      <br />
      <Stack horizontal horizontalAlign="center">
        <Stack
          vertical
          style={{
            width: '98%',
            maxWidth: '1350px',
          }}
        >
          <CommandBar items={commandBarItems} />
          {items.length ? (
            <ShimmeredDetailsList
              items={items || []}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
            />
          ) : (
            <ShimmeredDetailsList
              items={[{}]}
              enableShimmer={!loadDataComplete}
              compact={isCompactMode}
              columns={columns}
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={selection}
              selectionMode={!loadDataComplete ? SelectionMode.none : SelectionMode.multiple}
              checkboxVisibility={CheckboxVisibility.always}
              selectionPreservedOnEmptyClick={true}
              onRenderRow={() => {
                return (
                  <Stack verticalAlign="center" horizontalAlign="center" styles={{ root: { backgroundColor: '#FFFFFF', height: 55 } }}>
                    <Text>{t('documents.noItemFound')}</Text>
                  </Stack>
                );
              }}
            />
          )}
        </Stack>
      </Stack>
      <br />
      <center>
        {!(documents.length === 0 || documents.length < pageSize) && loadDataComplete && (
          <Pagination
            selectedPageIndex={currentPage - 1}
            pageCount={pageData.totalPages}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
          />
        )}

        <br />
        <br />
      </center>
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
      {url ? (
        <div ref={printRef} style={{ position: 'absolute', zIndex: -1 }}>
          <PdfAllPages pdf={url} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AllExpenses;
