import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import { Stack, getTheme, MessageBar, MessageBarType } from "@fluentui/react";
import {
  Field,
  Input,
  makeStyles,
  tokens,
  shorthands,
  useId,
  Label,
  Option,
  Dropdown,
  Textarea,
  Button,
  typographyStyles,
  Text,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
} from "@fluentui/react-components";

import {
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
} from "../../providers/AreaData";

const API_URL = process.env.REACT_APP_API_REQUEST_FORM_URL;
const theme = getTheme();
const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("20px"),
    maxWidth: "400px",
    "> div": {
      display: "grid",
      gridTemplateRows: "repeat(1fr)",
      justifyItems: "start",
      ...shorthands.gap("2px"),
      // need padding to see the background color for filled variants
      ...shorthands.padding("5px", "20px", "10px"),
    },
  },
  base: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "400px",
  },
  field: {
    display: "grid",
    gridRowGap: tokens.spacingVerticalXXS,
    marginTop: tokens.spacingVerticalMNudge,
    ...shorthands.padding(tokens.spacingHorizontalMNudge),
  },
  title: typographyStyles.subtitle2Stronger,
  languagechange: {
    ...typographyStyles.subtitle2,
    color: "var(--secondary-color)",
  },

  cardStyle: {
    width: "600px",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingTop: "20px",
    paddingBottom: "20px",

    marginLeft: "0",
    marginTop: "10px",
    boxShadow: theme.effects.elevation4,
    backgroundColor: "white",
  },
});

// const cardStyle = {
//   paddingLeft: 30,
//   paddingRight: 30,
//   paddingTop: 20,
//   paddingBottom: 30,
//   background: "#ffffff",
//   marginLeft: "0",
//   marginTop: "10px",
//   boxShadow: theme.effects.elevation4,
// };

const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const EditContacts = ({
  setStep,

  isLoadComplete,
  setIsLoadComplete,
}) => {
  const { t } = useTranslation();
  const dropdownId = useId("dropdown");
  const styles = useStyles();

  const outlineId = useId("input-outline");

  const [name, setName] = useState("");

  const [buildingNumber, setBuildingNumber] = useState("");
  const [address, setAddress] = useState("");
  // const [streetPrefix, setStreetPrefix] = useState("");
  const [streetName, setStreetName] = useState("");
  const [provincePrefix, setProvincePrefix] = useState("");
  const [province, setProvince] = useState({ key: "", text: "" });
  // const [provinceKey, setProvinceKey] = useState(null);

  // useEffect(() => {
  //   setProvinceKey(province);
  // }, [province]);
  const [taxNumberType, setTaxNumberType] = useState("");
  const [taxNumberText, setTaxNumberText] = useState("");
  const taxNumberTypeRef = useRef();
  const taxBranchNumberTypeRef = useRef();
  const provinceRef = useRef();
  const districtRef = useRef();
  const subDistrictRef = useRef();
  // const [structure, setStructure] = useState(true);
  // const [lineOne, setLineOne] = useState("");
  // const [lineTwo, setLineTwo] = useState("");
  const countryCode = "TH";
  // const [countryName, setCountryName] = useState("ไทย");
  const [taxBranchNumberType, setTaxBranchNumberType] = useState("");

  // const [contactList, setContactList] = useState([]);

  const [districtPrefix, setDistrictPrefix] = useState("");
  const [district, setDistrict] = useState({ key: "", text: "" });
  const [districtList, setDistrictList] = useState([]);

  const [subDistrictPrefix, setSubDistrictPrefix] = useState("");
  const [subDistrict, setSubDistrict] = useState({ key: "", text: "" });
  const [subDistrictList, setSubDistrictList] = useState([]);

  const [postcode, setPostCode] = useState("");
  const [note, setNote] = useState("");

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  // const [website, setWebsite] = useState("");
  // const [fax, setFax] = useState("");
  const [taxId, setTaxId] = useState("");
  // const [branch, setBranch] = useState("00000");
  const [branchNumber, setBranchNumber] = useState("00000");
  // const [taxBranchNumberText, setBranchNumberText] = useState(
  //   t("requestFormEditContact.Headoffice")
  // );
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState("สำนักงานใหญ่");

  const [branchDisplay, setBranchDisplay] = useState([
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ]);

  // const [branchDisplayTH, setBranchDisplayTH] = useState([
  //   { key: "00000", text: t("requestFormEditContact.Headoffice") },
  //   { key: "branchNumber", text: t("requestFormEditContact.branchNumber") },
  // ]);

  const BranchoptionsTh = [
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ];
  const BranchoptionsEn = [
    { key: "00000", text: "Head office" },
    { key: "branchNumber", text: "Branch Number" },
  ];

  // const [bankList, setBankList] = useState([
  //   { key: "KBANK", text: "ธนาคารกสิกรไทย" },
  //   { key: "SCB", text: "ธนาคารไทยพาณิชย์" },
  //   { key: "KTB", text: "ธนาคารกรุงไทย" },
  //   { key: "TTB", text: "ธนาคารทีทีบี" },
  //   { key: "BBL", text: "ธนาคารกรุงเทพ" },
  //   { key: "BAY", text: "ธนาคารกรุงศรีอยุธยา" },
  //   { key: "UOB", text: "ธนาคารยูโอบี" },
  // ]);
  // const [accountTypeList, setAccountTypeList] = useState([
  //   { key: "SAVING", text: "ออมทรัพย์" },
  //   { key: "CURRENT", text: "กระแสรายวัน" },
  // ]);
  // const [accountName, setAccountName] = useState("");
  // const [banks, setBanks] = useState([
  //   {
  //     bankCode: "",
  //     bankName: "",
  //     branch: "",
  //     typeCode: "",
  //     typeName: "",
  //     accountNumber: "",
  //   },
  // ]);

  const [showValidateTaxID, setShowValidateTaxID] = useState(false);
  const [showValidateTaxIDText, setShowValidateTaxIDText] = useState(false);

  const [showValidateBuildingNumber, setShowValidateBuildingNumber] =
    useState(false);

  const [showValidateBranchNumber, setShowValidateBranchNumber] =
    useState(false);

  const [showValidateAddress, setShowValidateAddress] = useState(false);

  const [showValidateProvince, setShowValidateProvince] = useState(false);

  const [showValidateDistrict, setShowValidateDistrict] = useState(false);
  const [showValidateSubDistrict, setShowValidateSubDistrict] = useState(false);

  const [showValidateEmail, setShowValidateEmail] = useState(false);

  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);
  const [showMessageBarErrorName, setShowMessageBarErrorName] = useState(false);
  // const [showMessageBarErrorPhone, setShowMessageBarErrorPhone] =
  //   useState(false);

  const [showMessageBarErrorPostcode, setShowMessageBarPostcode] =
    useState(false);
  const [showMessageBarRepeatTaxId, setShowMessageBarRepeatTaxId] =
    useState(false);
  const [showMessageBarRepeatOTHR, setShowMessageBarRepeatOTHR] =
    useState(false);

  const [showMessageBarSearchError, setShowMessageBarSearchError] =
    useState(false);
  const [showMessageBarSearchNotFound, setShowMessageBarSearchNotFound] =
    useState(false);
  // const [paymentNote, setPaymentNote] = useState("");

  const language = "th";

  const provinceList = getProvinceList(language);

  // const countryList = getCountryList(language);

  // const [isSearchCompleate, setIsSearchCompleate] = useState(true);
  // const [buttonClick, setButtonClick] = useState(false);

  //Error Message
  const [TypeErrMsg, setTypeErrMsg] = useState("");
  const [branchErrMsg, setBranchErrMsg] = useState("");
  const [provinceErrMsg, setProvinceErrMsg] = useState("");
  const [districtErrMsg, setDistrictErrMsg] = useState("");
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState("");
  const DropdownErrMsg = t("requestFormEditContact.dropdownErrMsg");

  // const phoneRegEx = /(^0[1-9][0-9]{1,13}$|^$)/g;
  // const [phoneNumberError, setPhoneNumberError] = useState(false);

  // const [label, setLabel] = useState({ key: "", text: "" });
  const [branchBlankDisplay, setBranchBlankDisplay] = useState({
    key: "",
    text: "",
  });
  // const companyId = sessionStorage.getItem("companyId") || "";

  const history = useHistory();
  const location = useLocation();
  const { contactId } = useParams(); // Rename 'id' to 'contactId'

  // const databack = location.state;

  const languageCheck = t("requestFormEditContact.en");

  const getDocumentTypeText = (selectedValue, languageCheck) => {
    if (languageCheck.toUpperCase() === "TH") {
      const foundOption = documentOptionswithTextTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : taxNumberText;
    } else {
      const foundOption = documentOptionswithTextEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : taxNumberText;
    }
  };

  const getBranchTypeText = (selectedValue) => {
    if (languageCheck.toUpperCase() === "TH") {
      const foundOption = BranchoptionsTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : null;
    } else {
      const foundOption = BranchoptionsEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : null;
    }
  };

  // const getProvinceFromCode = (selectedValue, language) => {
  //   console.log("ฟังค์ชั่นจังหวัดทำงาน", selectedValue);
  //   console.log("ภาษาทำงาน", language);

  //   // Assuming languageCheck is not required here and language is sufficient to determine the language

  //   const foundOption = getProvince(selectedValue, language);

  //   return foundOption ? foundOption.text : null;
  // };

  const [databack, setDataback] = useState(null);
  // const confirmSuccuss = location.Function.EditConfirm;
  const databyid = location.state.filteredData.find(
    (item) => item.id === contactId
  );

  // useEffect(() => {
  //   autoSetData();
  // }, [databack]);
  const autoSetData = () => {
    setTaxNumberType(databack.taxNumberType);

    const selectedTaxIdOption = options.find(
      (option) => option.key === databack.taxNumberType
    );

    handleonClick(selectedTaxIdOption, language);

    setTaxId(databack.taxId);

    if (databack.branchNumber === "00000") {
      setTaxBranchNumberType("00000");
      handleBranch("00000", language);
    } else {
      setTaxBranchNumberType("branchNumber");
      handleBranch(databack.branchNumber, language);
    }
    setName(databack.name);
    setBuildingNumber(databack.buildingNumber);
    setAddress(databack.address);

    setStreetName(databack.streetName);
    setPostCode(databack.postcode);
    setProvincePrefix(databack.provincePrefix);
    setProvince({
      "key": databack.provinceCode,
      "text": databack.provinceName,
    });
    // setProvinceKey(databack.provinceCode);

    setDistrict({
      "key": databack.districtCode,
      "text": databack.districtName,
    });
    setSubDistrictPrefix(databack.subDistrictPrefix);
    setSubDistrict({
      "key": databack.subDistrictCode,
      "text": databack.subDistrictName,
    });

    setDistrictPrefix(databack.districtPrefix);
    setEmail(databack.email);
    setPhone(
      `${databack.phoneCountryCode === "+66" ? "0" : ""}${
        databack.phone[0] === 0 ? databack.phone.slice(1, 11) : databack.phone
      }`
    );
    setNote(databack.note);
  };
  useEffect(() => {
    setDataback(databyid.data);

    if (databack !== null) {
      autoSetData(); // Call autoSetData when databack has data
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databack]);

  // useEffect(() => {
  //   console.log("ข้อมูลส่งกลับมา", databack);

  //   if (isLoadComplete === true) {
  //     setTaxNumberType(databack.taxNumberType);
  //     // const options = [
  //     //   { key: "TXID", text: t("requestFormEditContact.txid") },
  //     //   { key: "NIDN", text: t("requestFormEditContact.nidn") },
  //     //   { key: "CCPT", text: t("requestFormEditContact.ccpt") },
  //     // ];

  //     // const branchDisplay = [
  //     //   { key: "00000", text: "สำนักงานใหญ่" },
  //     //   { key: "branchNumber", text: "สาขาเลขที่" },
  //     // ];
  //     // const selectedOption = options.find(
  //     //   (option) => option.text === databack.taxNumberType
  //     // );

  //     // // Extract the text from the selected option
  //     // const selectedText = selectedOption ? selectedOption.text : "";
  //     // setTaxNumberType(selectedText);
  //     // setTaxNumberText(selectedText);

  //     // handleonClick(selectedOption, language);

  //     const selectedTaxIdOption = options.find(
  //       (option) => option.key === databack.taxNumberType
  //     );
  //     console.log("ประเภทสาขา", selectedTaxIdOption);
  //     handleonClick(selectedTaxIdOption, language);

  //     setTaxId(databack.taxID);
  //     // handleBranch(databack.branchNumber, language);
  //     // const brachOption = branchDisplay.find(
  //     //   (option) => option.text === databack.branch
  //     // );

  //     // if (!isNaN(databack.branchNumber)) {
  //     //   setBranchlength(databack.branchNumber);

  //     //   if (language === "en") {
  //     //     setBranchText("Branch " + databack.branchNumber);
  //     //   } else {
  //     //     setBranchText("สาขา " + databack.branchNumber);
  //     //   }
  //     // }

  //     // handleBranch(brachOption.key, language);
  //     if (databack.branchNumber === "00000") {
  //       setTaxBranchNumberType("00000");
  //       handleBranch("00000", language);
  //     } else {
  //       setTaxBranchNumberType(databack.taxBranchNumberType);
  //       handleBranch(databack.branchNumber, language);
  //     }

  //     setName(databack.name);
  //     setBuildingNumber(databack.buildingNo);
  //     setAddress(databack.address);
  //     setStreetName(databack.street);
  //     setPostCode(databack.postcode);
  //     setProvincePrefix(databack.provincePrefix);
  //     setProvince(databack.province);
  //     setProvinceKey(databack.province.key);
  //     console.log("คีย์จังหวัด", databack.province.key);
  //     // setSubDistrictList(getSubDistrictList(databack.districtCode, language));
  //     setDistrict(databack.district);
  //     setSubDistrictPrefix(databack.subDistrictPrefix);
  //     setSubDistrict(databack.subdistrict);
  //     // console.log("ตำบลที่เซทหลังกลับ",subDistrict);
  //     // setSubDistrict({
  //     //   key: databack.subDistrict,
  //     //   text: "อำเภอ",
  //     // });

  //     // setDistrictList(getDistrictList(databack.provinceCode, language));
  //     setDistrictPrefix(databack.districtPrefix);
  //     setEmail(databack.email);
  //     setPhone(databack.tel);
  //     setNote(databack.note);
  //     // setDistrict({
  //     //   key: databack.district,
  //     //   text: "ตำบล",
  //     // });
  //   }
  // }, []);

  const handleSubmit = () => {
    let isCorrect = true;
    if (!taxNumberType) {
      isCorrect = false;
      // setShowValidateTaxID(true);
      taxNumberTypeRef.current.focus();
      taxNumberTypeRef.current.blur();
    }

    if (taxNumberType === "TXID" && taxBranchNumberType === "") {
      isCorrect = false;
      taxBranchNumberTypeRef.current?.focus();
      taxBranchNumberTypeRef.current?.blur();
    }

    if (province.key === "") {
      isCorrect = false;

      provinceRef.current?.focus();
      provinceRef.current?.blur();
    }
    if (district.key === "") {
      isCorrect = false;

      districtRef.current?.focus();
      districtRef.current?.blur();
    }

    if (subDistrict.key === "") {
      isCorrect = false;

      subDistrictRef.current?.focus();
      subDistrictRef.current?.blur();
    }

    if (
      province.key === undefined ||
      province.key === null ||
      province.key === ""
    ) {
      isCorrect = false;
    }

    if (
      district.key === undefined ||
      district.key === null ||
      district.key === ""
    ) {
      isCorrect = false;
    }

    if (
      subDistrict.key === undefined ||
      subDistrict.key === null ||
      subDistrict.key === ""
    ) {
      isCorrect = false;
    }

    if (
      taxNumberType === undefined ||
      taxNumberType === null ||
      taxNumberType === ""
    ) {
      isCorrect = false;
    }

    let _branchNumber = branchNumber;

    if (taxNumberType === "TXID") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }

      if (isNaN(branchNumber)) {
        if (
          branchText.toLowerCase() === "head office" ||
          branchText === "สำนักงานใหญ่"
        ) {
          _branchNumber = "00000";
        } else {
          isCorrect = false;
        }
      } else {
        if (branchNumber.length !== 5) {
          if (
            branchText.toLowerCase() === "head office" ||
            branchText === "สำนักงานใหญ่"
          ) {
            _branchNumber = "00000";
          } else {
            isCorrect = false;
          }
        }
      }
    } else if (taxNumberType === "NIDN") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }
    } else if (taxNumberType === "CCPT") {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (taxId === "0000000000000" || Number(taxId) === 0) {
      isCorrect = false;
    }

    if (postcode) {
      if (isNaN(postcode)) {
        isCorrect = false;
      }

      if (postcode.length !== 5) {
        isCorrect = false;
      }
    }

    if (name.length > 140) {
      isCorrect = false;
    }

    if (buildingNumber.length > 16) {
      isCorrect = false;
    }

    if (address.length > 256) {
      isCorrect = false;
    }

    if (streetName.length > 70) {
      isCorrect = false;
    }

    if (postcode.length > 16) {
      isCorrect = false;
    }

    if (taxId.length > 35) {
      isCorrect = false;
    }

    if (_branchNumber.length > 5) {
      isCorrect = false;
    }

    if (phone.length > 35) {
      isCorrect = false;
    }

    if (email.length > 256) {
      isCorrect = false;
    }

    if (note.length > 500) {
      isCorrect = false;
    }

    // const selectedOption = options.find(
    //   (option) => option.key === taxNumberType
    // );

    // // Extract the text from the selected option
    // const selectedText = selectedOption ? selectedOption.text : "";

    // Populate formData with relevant state values
    const formData = {
      taxNumberType: taxNumberType,
      taxID: taxId,
      branch: branchText,
      branchNumber: branchNumber,
      taxBranchNumberType: taxBranchNumberType,
      name,
      buildingNo: buildingNumber,
      address,
      street: streetName,
      provincePrefix,
      province: province,
      districtPrefix,
      district: district,
      subdistrictPrefix: subDistrictPrefix,
      subdistrict: subDistrict,
      postcode,
      email,
      tel: phone, // Assuming phone state is the phone number
      note,
      id: contactId,
    };

    // Redirect to PreviewContacts page with form data
    if (isCorrect) {
      setIsLoadComplete(false);

      editContact(contactId, formData);
      // EditConfirm();
      // history.push({
      //   pathname: "/request-forms/contacts-client-list",
      //   state: { isback: true,editSuccess:true  },
      // });
    }
  };

  const editContact = async (id, formData) => {
    setIsLoadComplete(false);

    const formDatasave = {
      "address": formData.address,
      "branchNumber": formData.branchNumber,
      "branchText": formData.branch,
      "buildingNumber": formData.buildingNo,
      "streetPrefix": formData.streetPrefix,
      "streetName": formData.street,
      "districtPrefix": formData.districtPrefix,
      "districtCode": formData.district.key,
      "districtName": formData.district.text,
      "email": formData.email,
      "name": formData.name,
      "phone": formData.tel,
      "phoneCountryCode": formData.tel[0] !== "0" ? "+66" : "foreign",
      "postcode": formData.postcode,
      "provincePrefix": formData.provincePrefix,
      "provinceCode": formData.province.key,
      "provinceName": formData.province.text,
      "subDistrictPrefix": formData.subdistrictPrefix,
      "subDistrictCode": formData.subdistrict.key,
      "subDistrictName": formData.subdistrict.text,
      "taxId": formData.taxID,
      "taxNumberType": formData.taxNumberType,
      "note": formData.note,
      "id": formData.id,
    };

    try {
      formDatasave.created = new Date();
      // Construct the URL for the Azure Function
      // const url = `http://localhost:7071/api/Editformation`;

      // Send a POST request with formData and id to the backend
      // const response = await axios.post(url, { id, formDatasave });

      const response = await axios.patch(`${API_URL}/requests/${id}`, {
        "replace": "/data",
        "value": {
          "address": formData.address,
          "branchNumber": formData.branchNumber,
          "branchText": formData.branch,
          "buildingNumber": formData.buildingNo,
          "streetPrefix": formData.streetPrefix,
          "streetName": formData.street,
          "districtPrefix": formData.districtPrefix,
          "districtCode": formData.district.key,
          "districtName": formData.district.text,
          "email": formData.email,
          "name": formData.name,
          "phone": formData.tel,
          "phoneCountryCode": formData.tel[0] !== "0" ? "+66" : "foreign",
          "postcode": formData.postcode,
          "provincePrefix": formData.provincePrefix,
          "provinceCode": formData.province.key,
          "provinceName": formData.province.text,
          "subDistrictPrefix": formData.subdistrictPrefix,
          "subDistrictCode": formData.subdistrict.key,
          "subDistrictName": formData.subdistrict.text,
          "taxId": formData.taxID,
          "taxNumberType": formData.taxNumberType,
          "note": formData.note,
          "id": formData.id,
        },
      });

      // If the request is successful, return the response data

      if (response.data.statusCode === 200) {
        history.push({
          pathname: "/request-forms/contacts-client-list",
          state: { isback: true, editSuccess: true },
        });
        return response.data;
      } else {
        return response.data.statusCode;
      }
    } catch (error) {
      // If there's an error, throw it so that it can be caught by the caller
      history.push({
        pathname: "/request-forms/contacts-client-list",
        state: { isback: true, editSuccess: false },
      });
      throw error;
    }
  };

  const handleTaxType = (selectedOption, language) => {
    setTaxNumberType(selectedOption.key);
    setTaxNumberText(selectedOption.text);

    if (selectedOption.key === "TXID") {
      setBranchNumber("00000");
      if (language === "en") {
        setBranchText("Head office");
      } else {
        setBranchText("สำนักงานใหญ่");
      }
    } else {
      setBranchText("");
      setBranchNumber("");
    }

    if (selectedOption.key === "OTHR") {
      setTaxId("N/A");
    } else {
      setTaxId("");
    }
  };

  // const handleStructure = (checked) => {
  //   console.log("Address structure:", checked);

  //   setStructure(checked);
  //   if (checked) {
  //     setBuildingNumber("");
  //     setAddress("");
  //     setStreetPrefix("");
  //     setStreetName("");
  //     setProvincePrefix("");
  //     setProvince({ key: "", text: "" });
  //     setDistrictPrefix("");
  //     setDistrict({ key: "", text: "" });
  //     setSubDistrict("");
  //     setSubDistrict({ key: "", text: "" });

  //     setCountryCode("TH");
  //     setCountryName("ไทย");
  //   } else {
  //     setLineOne("");
  //     setLineTwo("");
  //   }
  // };

  const handleBranch = (branchNum, lang) => {
    if (lang === "en") {
      setBranchDisplay([
        { key: "00000", text: "Head office" },
        { key: "branchNumber", text: "Branch" },
      ]);
    } else if (lang === "th") {
      setBranchDisplay([
        { key: "00000", text: "สำนักงานใหญ่" },
        { key: "branchNumber", text: "สาขาเลขที่" },
      ]);
    }

    if (branchNum === "00000") {
      setShowBranchNumber(false);
      // setBranch("00000");
      setBranchNumber("00000");

      if (lang === "en") {
        setBranchText("Head office");
      } else {
        setBranchText("สำนักงานใหญ่");
      }
    } else {
      if (branchNum !== "") {
        // setBranch("branchNumber");
        if (branchNum === "branchNumber") {
          setBranchNumber("00000");

          if (lang === "en") {
            setBranchText("Branch 00000");
          } else {
            setBranchText("สาขา 00000");
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === "en") {
            setBranchText("Branch " + branchNum);
          } else {
            setBranchText("สาขา " + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }
  };

  // const handleChangeBankName = (index, event, selectedOption) => {
  //   console.log("Change index:", index);
  //   console.log("Event name: ", event);
  //   console.log("Selected option: ", selectedOption);
  //   const values = [...banks];
  //   if (selectedOption) {
  //     values[index]["bankCode"] = selectedOption.key;
  //     values[index]["bankName"] = selectedOption.text;
  //   }
  //   console.log("Banks: ", values);

  //   setBanks(values);
  // };

  // const handleChangeBankType = (index, event, selectedOption) => {
  //   console.log("Change index:", index);
  //   console.log("Event name: ", event);
  //   console.log("Selected option: ", selectedOption);
  //   const values = [...banks];
  //   if (selectedOption) {
  //     values[index]["typeCode"] = selectedOption.key;
  //     values[index]["typeName"] = selectedOption.text;
  //   }
  //   console.log("Banks: ", values);

  //   setBanks(values);
  // };

  // const handleChangeBank = (index, event, selectedOption) => {
  //   console.log("Change index:", index);
  //   console.log("Event name: ", event);
  //   console.log("Selected option: ", selectedOption);
  //   const values = [...banks];

  //   values[index][event.target.name] = event.target.value;

  //   console.log("Banks: ", values);

  //   setBanks(values);
  // };

  // const handleAddBank = () => {
  //   setBanks([
  //     ...banks,
  //     {
  //       bankCode: "",
  //       bankName: "",
  //       branch: "",
  //       typeCode: "",
  //       typeName: "",
  //       accountNumber: "",
  //     },
  //   ]);
  // };

  // const handleRemoveBank = (index) => {
  //   console.log("Delete index:", index);
  //   const values = [...banks];
  //   values.splice(index, 1);
  //   setBanks(values);
  // };

  const getErrorMessage = (value) => {
    return value && value !== "0"
      ? ""
      : t("requestFormEditContact.getErrorMessage");
  };

  const getTaxIdErrorMessage = (value) => {
    if (!value) return t("requestFormEditContact.getErrorMessage");
    if (taxNumberType === "TXID" || taxNumberType === "NIDN") {
      if (isNaN(value))
        return t("requestFormEditContact.getBranchNumberErrorMessage1");
      if (value.length !== 13) return t("requestFormEditContact.taxIdError");
    }
    return "";
  };

  const getBranchNumberErrorMessage = (value) => {
    if (!value) return t("requestFormEditContact.pleaseSelectBranch");
    if (isNaN(value))
      return t("requestFormEditContact.getBranchNumberErrorMessage1");
    if (value.length !== 5)
      return t("requestFormEditContact.getBranchNumberErrorMessage2");
    return "";
  };

  const getEmailValidateMessage = (email) => {
    if (!email) {
      return t("requestFormEditContact.pleaseEnterYourEmail");
    }
    if (!isValidEmail(email)) {
      return t("requestFormEditContact.pleaseCheckYourEmail");
    }
    return "";
  };

  // Function to check if email is valid
  const isValidEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getTypeNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectTaxPayerType");
    }

    return "";
  };

  const getNameNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterYourName");
    }

    return "";
  };

  const getฺBuildingNumberNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterTheNumber");
    }

    return "";
  };

  const getฺAddressNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseEnterTheAddress");
    }

    return "";
  };

  const getฺProvinceNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectProvince");
    }

    return "";
  };

  const getฺDistrictNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectDistrict");
    }

    return "";
  };

  const getฺsubDistrictNull = (name) => {
    if (!name) {
      return t("requestFormEditContact.pleaseSelectSubDistrict");
    }

    return "";
  };

  // const getPhoneNumberErrorMessage = (phone) => {
  //   if (!phone) {
  //     return "กรุณากรอกข้อมูลเบอร์โทร";
  //   }
  //   if (!isValidPhoneNumber(phone)) {
  //     return "โปรดตรวจสอบข้อมูลเบอร์โทร";
  //   }
  //   return "";
  // };

  // Function to check if phone number is valid
  // const isValidPhoneNumber = (phone) => {
  //   // Regular expression for validating phone number format
  //   const phoneRegEx = /^(0[1-9][0-9]{8,13})?$/;
  //   return phoneRegEx.test(phone);
  // };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === "TH") {
      if (isNaN(value))
        return t("requestFormEditContact.getPostCodeErrorMessage1");
      if (!value || value.length !== 5)
        return t("requestFormEditContact.getPostCodeErrorMessage2");
    }

    if (countryCode === "EN") {
      if (isNaN(value)) return "Please fill 16 numeric characters";
    }
  };

  const [typeContact, setTypeContact] = useState({ key: "", text: "" });

  const documentOptionswithTextTh = [
    { key: "TXID", text: "เลขประจำตัวผู้เสียภาษี (นิติบุคคล)" },
    { key: "NIDN", text: "เลขประจำตัวประชาชน (บุคคลธรรมดา)" },
    { key: "CCPT", text: "เลขที่หนังสือเดินทาง" },
  ];

  const documentOptionswithTextEn = [
    { key: "TXID", text: "Tax ID (Juristic Person) " },
    { key: "NIDN", text: "ID Number (Ordinary Person)" },
    { key: "CCPT", text: "Passport Number" },
  ];

  const options = [
    { key: "TXID", text: t("requestFormEditContact.txid") },
    { key: "NIDN", text: t("requestFormEditContact.nidn") },
    { key: "CCPT", text: t("requestFormEditContact.ccpt") },
  ];

  const handleonClick = (newValue) => {
    handleTaxType(newValue);
  };

  // const optionsStreet = [
  //   { key: "ถ.", text: "ถ." },
  //   { key: "ถนน", text: "ถนน" },
  //   { key: "", text: t("requestFormEditContact.notDisplay") },
  // ];

  // const optionsProvince = [
  //   { key: "จ.", text: "จ." },
  //   { key: "จังหวัด", text: "จังหวัด" },
  //   { key: "", text: t("requestFormEditContact.notDisplay") },
  // ];

  // const optionsDistrictPrefix = [
  //   { key: "อ.", text: "อ." },
  //   { key: "อำเภอ", text: "อำเภอ" },
  //   { key: "เขต", text: "เขต" },
  //   { key: "", text: t("requestFormEditContact.notDisplay") },
  // ];

  // const optionssubDistrictPrefix = [
  //   { key: "ต.", text: "ต." },
  //   { key: "ตำบล", text: "ตำบล" },
  //   { key: "แขวง", text: "แขวง" },
  //   { key: "", text: t("requestFormEditContact.notDisplay") },
  // ];

  const setPostCodelength = (e) => {
    if (e.target.value.length <= 16 && language === "en") {
      setPostCode(e.target.value);
    }

    if (e.target.value.length <= 5 && language === "th") {
      setPostCode(e.target.value);
    }
  };
  const handlesetbuildingNumber = (e) => {
    setShowValidateBranchNumber(false);
    setBuildingNumber(e.target.value);
  };

  const setNotelength = (e) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (e.target.value.length <= 500) {
      setNote(e.target.value);
    }
  };

  const setTaxIdlength = (e) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (e.length <= 13) {
      setTaxId(e);
    }
  };

  // const setIdNumlength = (e) => {
  //   // The controlled input pattern can be used for other purposes besides validation,
  //   // but validation is a useful example
  //   if (e.length <= 13) {
  //     setTaxIdlength(e);
  //   }
  // };

  const setBranchlength = (e) => {
    if (e.length <= 5) {
      setBranchNumber(e);
    }
  };

  const setPhonelength = (e) => {
    if (e.length <= 10) {
      setPhone(e);
    }
  };

  const currentStep = 1;

  useState(() => {
    setStep(currentStep);
  }, []);

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === "10") {
      if (language === "th") {
        setSubDistrictPrefix("แขวง");
        setDistrictPrefix("เขต");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      }
    } else {
      if (language === "en") {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("ต.");
        setDistrictPrefix("อ.");
        setProvincePrefix("จ.");
      }
    }
  };

  return (
    <div className="responsive-element">
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.updateSuccess")}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.updateFail")}
        </MessageBar>
      )}
      {showMessageBarRepeatTaxId && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatTaxId(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.alreadyHaveTaxId")}
        </MessageBar>
      )}
      {showMessageBarRepeatOTHR && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatOTHR(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("requestFormEditContact.alreadyHaveContact")}
        </MessageBar>
      )}
      {showMessageBarSearchError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchFail")}
        </MessageBar>
      )}
      {showMessageBarSearchNotFound && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchNotFound(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchNotFound")}
        </MessageBar>
      )}

      <form onSubmit={handleSubmit}>
        <div className="header-part">
          <div className="header-topic">
            <div className="header-topic-word">
              <Text as="h1" block className={styles.title}>
                {" "}
              </Text>
            </div>
          </div>
        </div>

        <div className="new-section-container">
          <div className={styles.cardStyle}>
            <div className="field-long">
              <Label
                style={{
                  fontSize: "14px",
                  alignSelf: "center",
                  fontWeight: "bold",
                  // color:"var(--secondary-color)"
                }}
              >
                {t("requestFormEditContact.enterClient")}
              </Label>
            </div>

            <div className="first-part">
              <div className="company-information">
                <div classname="tax-number-type">
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.taxNumberType")}
                        </Label>
                      }
                      required={true}
                      // label={t("requestFormEditContact.taxId")}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showValidateTaxID ? getTypeNull(options.key) : ""
                      }
                    >
                      <Dropdown
                        aria-labelledby={`${dropdownId}-outline`}
                        placeholder={t("requestFormEditContact.pleaseSelect")}
                        appearance="outline"
                        size="medium"
                        style={{ minWidth: "25%" }}
                        disabled={!isLoadComplete}
                        required={true}
                        value={getDocumentTypeText(
                          taxNumberType,
                          languageCheck
                        )}
                        ref={taxNumberTypeRef}
                        onBlur={() => {
                          if (typeContact.key === "") {
                            setTypeErrMsg(DropdownErrMsg);
                            setShowValidateTaxID(true);
                          } else {
                            setTypeErrMsg("");
                          }
                        }}
                        errorMessage={TypeErrMsg}
                      >
                        {options.map((option) => (
                          <Option
                            key={option.key}
                            onClick={() => {
                              handleonClick(option, language);
                              setTypeContact(option);
                              setShowValidateTaxID(false);
                            }}
                            text={option.text}
                          >
                            {option.text}
                          </Option>
                        ))}
                      </Dropdown>
                    </Field>
                  </div>
                </div>

                {taxNumberType !== "" && !isLoadComplete && (
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "1px",
                          }}
                        >
                          {taxNumberType === "NIDN"
                            ? t("requestFormEditContact.ninText")
                            : taxNumberType === "TXID"
                            ? t("requestFormEditContact.tinText")
                            : taxNumberType === "OTHR"
                            ? t("requestFormEditContact.passportText")
                            : t("requestFormEditContact.passportText")}
                        </Label>
                      }
                      required
                      // label={t("requestFormEditContact.taxId")}
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showValidateTaxIDText ? getTaxIdErrorMessage(taxId) : ""
                      }
                    >
                      <Input
                        value={taxId}
                        disabled={!isLoadComplete}
                        onChange={(e) => {
                          setShowValidateTaxIDText(false);
                          if (
                            taxNumberType === "TXID" ||
                            taxNumberType === "NIDN"
                          ) {
                            if (!isNaN(e.target.value)) {
                              setTaxIdlength(e.target.value);
                            }
                          } else {
                            setTaxIdlength(e.target.value);
                          }
                        }}
                        validateOnLoad={false}
                        validateOnFocusOut
                        onBlur={(e) => {
                          setShowValidateTaxIDText(true);
                        }}
                      />
                    </Field>
                  </div>
                )}

                {taxNumberType === "" && !isLoadComplete && (
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.name")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      required
                      validationMessage={
                        showMessageBarErrorName ? getNameNull(name) : ""
                      }
                    >
                      {" "}
                      <Input
                        disabled={!isLoadComplete}
                        appearance="outline"
                        id={outlineId}
                        size="medium"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                    </Field>
                  </div>
                )}

                {taxNumberType === "TXID" && (
                  <>
                    <div className="branch-display">
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "14px",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {t("requestFormEditContact.branchDisplay")}
                            </Label>
                          }
                          // label={t("requestFormEditContact.taxId")}
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="medium"
                          validationMessage={
                            showValidateBranchNumber
                              ? getBranchNumberErrorMessage(branchDisplay.key)
                              : ""
                          }
                          required
                        >
                          <Dropdown
                            disabled={!isLoadComplete}
                            aria-labelledby={`${dropdownId}-outline`}
                            size="medium"
                            style={{ minWidth: "25%" }}
                            placeholder={t(
                              "requestFormEditContact.pleaseSelect"
                            )}
                            appearance="outline"
                            required
                            value={getBranchTypeText(taxBranchNumberType)}
                            ref={taxBranchNumberTypeRef}
                            onBlur={() => {
                              if (branchBlankDisplay.key === "") {
                                setBranchErrMsg(DropdownErrMsg);
                                setShowValidateBranchNumber(true);
                              } else {
                                setBranchErrMsg("");
                              }
                            }}
                            errorMessage={branchErrMsg}
                          >
                            {branchDisplay.map((option) => (
                              <Option
                                key={option.key}
                                onClick={() => {
                                  setBranchBlankDisplay(option);
                                  setBranchErrMsg(option);
                                  setTaxBranchNumberType(option.key);

                                  setShowValidateBranchNumber(false);
                                  handleBranch(option.key, language);
                                }}
                                text={option.text}
                              >
                                {option.text}
                              </Option>
                            ))}
                          </Dropdown>
                        </Field>
                      </div>
                    </div>

                    {showBranchNumber && (
                      <div className="branch-number">
                        <div className="field-long">
                          <Field
                            label={
                              <Label
                                htmlFor={outlineId}
                                style={{
                                  fontSize: "14px",

                                  fontWeight: "bold",
                                  alignSelf: "left",
                                  marginBottom: "0px",
                                }}
                              >
                                {t("requestFormEditContact.branchNumber")}
                              </Label>
                            }
                            required
                            style={{
                              textAlign: "left",
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                            size="medium"
                            validationMessage={
                              showValidateBranchNumber
                                ? getBranchNumberErrorMessage(branchNumber)
                                : ""
                            }
                          >
                            <Input
                              disabled={!isLoadComplete}
                              value={branchNumber}
                              onChange={(e) => {
                                setShowValidateBranchNumber(false);
                                if (!isNaN(e.target.value)) {
                                  setBranchlength(e.target.value);

                                  if (language === "en") {
                                    setBranchText("Branch " + e.target.value);
                                  } else {
                                    setBranchText("สาขา " + e.target.value);
                                  }
                                }
                              }}
                            />
                          </Field>
                        </div>
                      </div>
                    )}
                  </>
                )}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    height: "80px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      height: "80px",
                    }}
                  >
                    {(taxNumberType === "TXID" ||
                      taxNumberType === "OTHR" ||
                      taxNumberType === "CCPT") && (
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "14px",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {taxNumberType === "NIDN"
                                ? t("requestFormEditContact.companyName")
                                : taxNumberType === "TXID"
                                ? t("requestFormEditContact.companyName")
                                : taxNumberType === "OTHR"
                                ? t("requestFormEditContact.onlyName")
                                : t("requestFormEditContact.onlyName")}
                            </Label>
                          }
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="medium"
                          required
                          validationMessage={
                            showMessageBarErrorName ? getNameNull(name) : ""
                          }
                        >
                          <Input
                            disabled={!isLoadComplete}
                            appearance="outline"
                            id={outlineId}
                            size="medium"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onGetErrorMessage={getErrorMessage}
                            validateOnLoad={false}
                            validateOnFocusOut
                            required
                            onBlur={(e) => {
                              setShowMessageBarErrorName(true);
                            }}
                          />
                        </Field>
                      </div>
                    )}
                    {taxNumberType === "NIDN" && (
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "14px",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "0px",
                              }}
                            >
                              {t("requestFormEditContact.name")}
                            </Label>
                          }
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="medium"
                          required
                          validationMessage={
                            showMessageBarErrorName ? getNameNull(name) : ""
                          }
                        >
                          {" "}
                          <Input
                            disabled={!isLoadComplete}
                            appearance="outline"
                            id={outlineId}
                            size="medium"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onGetErrorMessage={getErrorMessage}
                            validateOnLoad={false}
                            validateOnFocusOut
                            onBlur={(e) => {
                              setShowMessageBarErrorName(true);
                            }}
                            required
                          />
                        </Field>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />

          <div className={styles.cardStyle}>
            <div className="field-long">
              <Label
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {t("requestFormEditContact.addressHeader")}
              </Label>
            </div>
            {/* <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 10 } }}
            ></Stack> */}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="new-section-container"
            >
              <div className="build-number-address">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.buildingNumber")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateBuildingNumber
                        ? getฺBuildingNumberNull(buildingNumber)
                        : ""
                    }
                    required
                  >
                    {" "}
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      id={outlineId}
                      value={buildingNumber}
                      min-width="25%"
                      size="medium"
                      name="buildingNumber"
                      onChange={handlesetbuildingNumber}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateBuildingNumber(true);
                      }}
                    />
                  </Field>
                </div>

                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.address")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateAddress ? getฺAddressNull(address) : ""
                    }
                    required
                  >
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      size="medium"
                      id={outlineId}
                      name="address"
                      label={t("requestFormEditContact.address")}
                      value={address}
                      onChange={(e) => {
                        setShowValidateAddress(false);
                        setAddress(e.target.value);
                      }}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateAddress(true);
                      }}
                    />
                  </Field>
                </div>
              </div>

              <div className="field-long">
                <div className="street">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.streetName")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                  >
                    <Input
                      disabled={!isLoadComplete}
                      appearance="outline"
                      id={outlineId}
                      size="medium"
                      name="streetName"
                      label={t("requestFormEditContact.streetName")}
                      value={streetName}
                      onChange={(e) => setStreetName(e.target.value)}
                    />
                  </Field>
                </div>
              </div>

              <div className="province">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.province")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateProvince
                        ? getฺProvinceNull(provinceList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      disabled={!isLoadComplete}
                      aria-labelledby={`${dropdownId}-outline`}
                      // placeholder={t("requestFormEditContact.pleaseSelect")}
                      placeholder={t("requestFormEditContact.pleaseSelect")}
                      appearance="outline"
                      name="province"
                      required
                      size="medium"
                      style={{ minWidth: "25%" }}
                      label={t("requestFormEditContact.province")}
                      value={province.text}
                      ref={provinceRef}
                      onBlur={() => {
                        if (province.key === "") {
                          setProvinceErrMsg(DropdownErrMsg);
                          setShowValidateProvince(true);
                        } else {
                          setProvinceErrMsg("");
                        }
                      }}
                      errorMessage={provinceErrMsg}
                    >
                      {provinceList.map((option) => (
                        <Option
                          key={option.key}
                          onClick={(e) => {
                            setProvince(option);
                            setAreaPrefix(option.key);

                            // setProvinceKey(option.key);

                            setDistrict({ key: "", text: "" });
                            setDistrictList(
                              getDistrictList(option.key, language)
                            );
                            setSubDistrict({ key: "", text: "" });

                            setProvinceErrMsg("");
                            setDistrictErrMsg("");
                            setSubDistrictErrMsg("");
                            setShowValidateProvince(false);
                          }}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>
                </div>
              </div>

              <div className="district">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        id={`${dropdownId}-outline`}
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.district")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateDistrict
                        ? getฺDistrictNull(districtList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      disabled={!isLoadComplete}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("requestFormEditContact.pleaseSelect")}
                      appearance="outline"
                      required
                      style={{ minWidth: "25%" }}
                      size="medium"
                      label={t("requestFormEditContact.district")}
                      value={district.text}
                      styles={{ root: { width: 150 } }}
                      ref={districtRef}
                      onBlur={(e) => {
                        if (province.key !== "" && district.key === "") {
                          setDistrictErrMsg(DropdownErrMsg);
                          setShowValidateDistrict(true);
                        } else {
                          setDistrictErrMsg("");
                        }
                      }}
                      errorMessage={districtErrMsg}
                    >
                      {districtList.map((option) => (
                        <Option
                          key={option.key}
                          onClick={(e) => {
                            setDistrict(option);
                            setSubDistrict({ key: "", text: "" });
                            setSubDistrictList(
                              getSubDistrictList(option.key, language)
                            );

                            setDistrictErrMsg("");
                            setSubDistrictErrMsg("");
                            setShowValidateDistrict(false);
                          }}
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>{" "}
                </div>
              </div>

              <div className="subdistrict">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "14px",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "0px",
                        }}
                      >
                        {t("requestFormEditContact.subDistrict")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="medium"
                    validationMessage={
                      showValidateSubDistrict
                        ? getฺsubDistrictNull(subDistrictList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      disabled={!isLoadComplete}
                      aria-labelledby={`${dropdownId}-outline`}
                      // placeholder={t("requestFormEditContact.pleaseSelect")}
                      placeholder={t("requestFormEditContact.pleaseSelect")}
                      appearance="outline"
                      style={{ minWidth: "25%" }}
                      required
                      size="medium"
                      label={t("requestFormEditContact.subDistrict")}
                      // value={subDistrictList.key}
                      value={subDistrict.text}
                      ref={subDistrictRef}
                      onBlur={(e) => {
                        if (district.key !== "" && subDistrict.key === "") {
                          setSubDistrictErrMsg(DropdownErrMsg);
                          setShowValidateSubDistrict(true);
                        } else {
                          setSubDistrictErrMsg("");
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                    >
                      {subDistrictList.map((option) => (
                        <Option
                          key={option.key}
                          onClick={(e) => {
                            setSubDistrict(option);

                            setSubDistrictErrMsg("");
                            setShowValidateSubDistrict(false);
                          }}
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>
                  </Field>{" "}
                </div>
              </div>
              <div className="build-number-address">
                <div className="postcode">
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "14px",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "0px",
                          }}
                        >
                          {t("requestFormEditContact.postCode")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="medium"
                      validationMessage={
                        showMessageBarErrorPostcode
                          ? getPostCodeErrorMessage(postcode)
                          : ""
                      }
                      required
                    >
                      <Input
                        disabled={!isLoadComplete}
                        appearance="outline"
                        id={outlineId}
                        name="postcode"
                        type="number"
                        size="medium"
                        min-width="25%"
                        label={t("requestFormEditContact.postCode")}
                        value={postcode}
                        onChange={setPostCodelength}
                        onGetErrorMessage={getPostCodeErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        onBlur={(e) => {
                          setShowMessageBarPostcode(true);
                        }}
                      />
                    </Field>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br />

        <div className={styles.cardStyle}>
          <div className="field-long">
            {" "}
            <Label
              style={{
                fontSize: "14px",

                fontWeight: "bold",
                alignSelf: "center",
              }}
            >
              {t("requestFormEditContact.contactHeader")}
            </Label>
          </div>

          <div className="contact">
            <div className="field-long">
              <Field
                label={
                  <Label
                    htmlFor={outlineId}
                    style={{
                      fontSize: "14px",

                      fontWeight: "bold",
                      alignSelf: "left",
                      marginBottom: "0px",
                    }}
                  >
                    {t("requestFormEditContact.email")}
                  </Label>
                }
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                size="medium"
                required
                validationMessage={
                  showValidateEmail ? getEmailValidateMessage(email) : ""
                }
              >
                <Input
                  disabled={!isLoadComplete}
                  appearance="outline"
                  id={outlineId}
                  type="email"
                  name="email"
                  size="medium"
                  label={t("requestFormEditContact.email")}
                  minWidth="80%"
                  value={email}
                  onChange={(e) => {
                    setShowValidateEmail(false);
                    setEmail(e.target.value);
                  }}
                  onBlur={(e) => {
                    setShowValidateEmail(true);
                  }}
                />
              </Field>
            </div>

            <div className="field-long">
              <Label
                htmlFor={outlineId}
                style={{
                  fontSize: "14px",

                  fontWeight: "bold",
                  alignSelf: "left",
                  marginBottom: "0px",
                }}
              >
                {t("requestFormEditContact.phone")}
              </Label>
              <Field
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                size="medium"
              >
                <Input
                  disabled={!isLoadComplete}
                  appearance="outline"
                  id={outlineId}
                  name="phone"
                  size="medium"
                  label={t("requestFormEditContact.phone")}
                  minWidth="80%"
                  value={phone}
                  onChange={(e) => setPhonelength(e.target.value)}
                />
              </Field>
            </div>
          </div>

          <div className="field-long">
            <Field
              label={
                <Label
                  style={{
                    fontSize: "14px",

                    fontWeight: "bold",
                    textAlign: "left",
                    marginBottom: "0px",
                  }}
                >
                  {t("requestFormEditContact.note")}
                </Label>
              }
              size="medium"
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Textarea
                disabled={!isLoadComplete}
                onChange={setNotelength}
                value={note}
                size="medium"
                resize="both"
              />
            </Field>
          </div>
        </div>

        <br />

        <div className="responsive-boxbutton">
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
            <Dialog modalType="modal">
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  style={{ marginRight: "10px" }}
                  disabled={!isLoadComplete}
                >
                  {t("requestFormAdminFormEditor.save")}
                </Button>
              </DialogTrigger>

              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("requestFormAdminFormEditor.sentData")}
                  </DialogTitle>

                  <DialogContent>
                    {" "}
                    {t("requestFormAdminFormEditor.confirmToSave")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                        value="next"
                        onClick={() => handleSubmit()}
                      >
                        {t("requestFormAdminFormEditor.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("requestFormAdminFormEditor.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
            <Button
              appearance="secondary"
              disabled={!isLoadComplete}
              onClick={() => {
                history.push({
                  pathname: "/request-forms/contacts-client-list",
                  state: { isback: true },
                });
              }}
            >
              {t("requestFormEditContact.back")}
            </Button>
          </Stack>
        </div>

        <br />
        <br />
      </form>
    </div>
  );
};

export default EditContacts;
