import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TextField,
  DatePicker,
  DayOfWeek,
  mergeStyleSets,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Checkbox,
  Dropdown,
  Stack,
  getTheme,
  Shimmer,
  Toggle,
  Separator,
  Spinner,
} from '@fluentui/react';
import { useLocation, useHistory } from 'react-router-dom';

import axios from 'axios';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import { getProvince, getDistrict, getSubDistrict } from '../../../providers/AreaData';
import { getUnit, getUnitList } from '../../../providers/UnitData';
import { numberToTextEn, numberToTextTh } from '../../../helpers/BahtWord';
import AutocompleteTextField from '../../../helpers/customComponents/autocompleteTextField/product/AutocompleteTextField';

const API_URL = process.env.REACT_APP_API_URL;

const theme = getTheme();

const onFormatDate = (date, format) => {
  if (format === 'CE') {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear();
  } else {
    return `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543);
  }
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
});

const ReceiptTaxInvoiceAbb = () => {
  const location = useLocation();
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [data] = useState(location.state);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  console.log('Render');

  const [company, setCompany] = useState([]);
  const [companyName, setCompanyName] = useState('');
  const [companyPhone, setCompanyPhone] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [companyTaxId, setCompanyTaxId] = useState('');
  const [companyBranchText, setCompanyBranchText] = useState('');
  const [companyAllAddress, setCompanyAllAddress] = useState('');

  //Customer Detail
  const [name, setName] = useState('');
  const [buildingNumber, setBuildingNumber] = useState('');
  const [address, setAddress] = useState('');
  const [streetPrefix, setStreetPrefix] = useState('');
  const [streetName, setStreetName] = useState('');
  const [provincePrefix, setProvincePrefix] = useState('');
  const [province, setProvince] = useState({ key: '', text: '' });
  const [subDistrictPrefix, setSubDistrictPrefix] = useState('');
  const [subDistrict, setSubDistrict] = useState({ key: '', text: '' });
  const [districtPrefix, setDistrictPrefix] = useState('');
  const [district, setDistrict] = useState({ key: '', text: '' });
  const [postcode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [taxNumberType, setTaxNumberType] = useState('');
  const [taxId, setTaxId] = useState('');
  const [branchText, setBranchText] = useState('');

  const [branchNumber, setBranchNumber] = useState('00000');
  const [email, setEmail] = useState('');
  const [structure, setStructure] = useState(true);
  const [lineOne, setLineOne] = useState('');
  const [lineTwo, setLineTwo] = useState('');

  const [countryCode, setCountryCode] = useState('TH');
  const [countryName, setCountryName] = useState('ไทย');

  const [reference, setReference] = useState('');

  const [language, setLanguage] = useState(sessionStorage.getItem('address-language') || 'th');

  const [number, setNumber] = useState('');
  const [date, setDate] = useState(new Date().toISOString());
  const [dateBE, setDateBE] = useState(onFormatDate(new Date(), 'BE'));
  const [dateCE, setDateCE] = useState(onFormatDate(new Date(), 'CE'));
  const [note, setNote] = useState('');
  const [receivedBy, setReceivedBy] = useState('');

  const [amountTotal, setAmountTotal] = useState(0);

  // VAT type
  const percentVat = 7;
  const zeroVat = 0;
  const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  const nonVatText = '-';

  const [vatPriceTotal, setVatPriceTotal] = useState(0);
  const [zeroVatPriceTotal, setZeroVatPriceTotal] = useState(0);
  const [nonVatPriceTotal, setNonVatPriceTotal] = useState(0);

  const [discountDisable, setDiscountDisable] = useState(false);

  const [vatTotal, setVatTotal] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [grandTotalEN, setGrandTotalEN] = useState('');
  const [grandTotalTH, setGrandTotalTH] = useState('');

  const unitList = getUnitList(language);

  const reasonList = [
    {
      key: 'TIVC01',
      text: t('receiptTaxInvoiceAbb.reason1'),
    },
    {
      key: 'TIVC02',
      text: t('receiptTaxInvoiceAbb.reason2'),
    },
    {
      key: 'TIVC99',
      text: t('receiptTaxInvoiceAbb.reason3'),
    },
  ];

  const [firstDayOfWeek] = React.useState(DayOfWeek.Sunday);

  const [items, setItems] = useState([
    {
      number: '',
      description: '',
      quantity: '',
      unitCode: '',
      unitName: '-',
      price: '',
      discount: 0,
      percentVat: percentVat,
      percentVatText: percentVatText,
      total: 0,
      sku: ''
    },
  ]);

  //Cancel and Replace Doc
  const [reIssue, setReIssue] = useState(false);
  const [referNumber, setReferNumber] = useState('');
  const [referDate, setReferDate] = useState('');
  const [referDateBE, setReferDateBE] = useState('');
  const [referDateCE, setReferDateCE] = useState('');
  const [reason, setReason] = useState({ key: '', text: '' });
  const [specificReason, setSpecificReason] = useState('');

  const [includeVat, setIncludeVat] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [backUrl, setBackUrl] = useState('/etax/documents/all');

  //Style
  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const [reasonErrMsg, setReasonErrMsg] = useState('');

  const dateRef = useRef();
  const referDateRef = useRef();

  const companyId = sessionStorage.getItem('companyId') || '';

  const DropdownErrMsg = t('receiptTaxInvoiceAbb.dropdownErrMsg');

  const [extraAttributes, setExtraAttributes] = useState(null)

  const DayPickerStrings = {
    months: [
      t('documents.months01'),
      t('documents.months02'),
      t('documents.months03'),
      t('documents.months04'),
      t('documents.months05'),
      t('documents.months06'),
      t('documents.months07'),
      t('documents.months08'),
      t('documents.months09'),
      t('documents.months10'),
      t('documents.months11'),
      t('documents.months12'),
    ],

    shortMonths: [
      t('documents.shortMonths01'),
      t('documents.shortMonths02'),
      t('documents.shortMonths03'),
      t('documents.shortMonths04'),
      t('documents.shortMonths05'),
      t('documents.shortMonths06'),
      t('documents.shortMonths07'),
      t('documents.shortMonths08'),
      t('documents.shortMonths09'),
      t('documents.shortMonths10'),
      t('documents.shortMonths11'),
      t('documents.shortMonths12'),
    ],

    days: [
      t('documents.days01'), 
      t('documents.days02'), 
      t('documents.days03'), 
      t('documents.days04'), 
      t('documents.days05'), 
      t('documents.days06'), 
      t('documents.days07')
    ],

    shortDays: [
      t('documents.shortDays01'), 
      t('documents.shortDays02'), 
      t('documents.shortDays03'), 
      t('documents.shortDays04'), 
      t('documents.shortDays05'), 
      t('documents.shortDays06'), 
      t('documents.shortDays07')
    ],

    goToToday: t('documents.goToToday'),
    prevMonthAriaLabel: t('documents.prevMonthAriaLabel'),
    nextMonthAriaLabel: t('documents.nextMonthAriaLabel'),
    prevYearAriaLabel: t('documents.prevYearAriaLabel'),
    nextYearAriaLabel: t('documents.nextYearAriaLabel'),
    closeButtonAriaLabel: t('documents.close'),
    monthPickerHeaderAriaLabel: t('documents.monthPickerHeaderAriaLabel'),
    yearPickerHeaderAriaLabel: t('documents.yearPickerHeaderAriaLabel'),

    // isRequiredErrorMessage: t('documents.isRequiredErrorMessage'),

    invalidInputErrorMessage: t('documents.invalidInputErrorMessage'),
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .get(API_URL + '/companies-details', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
              cid: companyId
            },
          })
          .then(
            (response) => {
              console.log('Companies details data: ', response);

              if (response.data) {
                if (
                  response.data &&
                  response.data.balances &&
                  response.data.balances.documents &&
                  response.data.balances.documents.credits !== undefined &&
                  response.data.balances.documents.credits !== null
                ) {
                  console.log('Credit documents: ', response.data.balances.documents.credits);

                  let _documents = response.data.balances.documents.credits;

                  if (_documents <= 0) {
                    history.push({
                      pathname: '/billings',
                      state: {},
                    });
                  }
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                if (companyId && response.data.find(_company => _company.id === companyId)) {
                  const _company = response.data.find(_company => _company.id === companyId)
                
                  if (_company.includeVat && !data) {
                    setIncludeVat(_company.includeVat);
                  } else {
                    _company.includeVat = false;
                  }
                  
                  setCompany(_company);
  
                  setCompanyName(_company.name);
                  setCompanyPhone(_company.phone);
                  setCompanyEmail(_company.email);
                  setCompanyTaxId(_company.taxId);
                  setCompanyBranchText(_company.branchText);
                } else {
                  if (response.data[0].includeVat && !data) {
                    setIncludeVat(response.data[0].includeVat);
                  } else {
                    response.data[0].includeVat = false;
                  }
                  
                  setCompany(response.data[0]);
  
                  setCompanyName(response.data[0].name);
                  setCompanyPhone(response.data[0].phone);
                  setCompanyEmail(response.data[0].email);
                  setCompanyTaxId(response.data[0].taxId);
                  setCompanyBranchText(response.data[0].branchText);

                }
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + '/functions/wakeup', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then((response) => {
            console.log('Wake up function: ', response);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('Company state: ', company);

    if (company.taxId) {
      console.log('Company useEffect call!');
      console.log(company);
      console.log(company.id);

      setCompanyAllAddress(
        company.buildingNumber +
          ' ' +
          (!/^-*$/.test(company.address) && company.address.trim() && !/^-*$/.test(company.address.trim()) ? company.address : '') +
          ' ' +
          (!/^-*$/.test(company.streetName) && company.streetName.trim() && !/^-*$/.test(company.streetName.trim())
            ? company.streetPrefix + company.streetName
            : '') +
          ' ' +
          company.subDistrictPrefix +
          company.subDistrictName +
          ' ' +
          company.districtPrefix +
          company.districtName +
          ' ' +
          company.provincePrefix +
          company.provinceName +
          ' ' +
          company.postcode
      );
    }
  }, [company]);

  useEffect(() => {
    console.log('Language Effect: ', language);

    console.log('Province: ', province);

    if (language != null) {
      setProvince(getProvince(province.key, language));
      setDistrict(getDistrict(district.key, language));
      setSubDistrict(getSubDistrict(subDistrict.key, language));

      handleBranch(branchNumber, language);

      setAreaPrefix(province.key);

      const values = [...items];

      values.forEach((value) => {
        value.unitCode = getUnit(value.unitCode, language).key;
        value.unitName = getUnit(value.unitCode, language).text;
      });

      setItems(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    console.log('useEffect data call!: ', data);

    if (data !== undefined) {
      if (data.command === 'reIssue') {
        setReIssue(true);

        setReferNumber(data.number);
        setReferDate(data.date);
        setReferDateBE(data.dateBE);
        setReferDateCE(data.dateCE);
        setReason({ key: '', text: '' });
        setSpecificReason('');
      } else {
        setNumber(data.number);

        if (data.date) {
          setDate(data.date);
          setDateBE(data.dateBE);
          setDateCE(data.dateCE);
        }

        setReIssue(data.reIssue);
        if (data.reIssue) {
          setReferNumber(data.refer.number);
          setReferDate(data.refer.date);
          setReferDateBE(data.refer.dateBE);
          setReferDateCE(data.refer.dateCE);
          setReason({
            key: data.refer.reasonCode,
            text: data.refer.reasonName,
          });
          setSpecificReason(data.refer.specificReason);
        }
      }
      //Company
      setCompany(data.company);
      setCompanyName(data.company.name);
      setCompanyBranchText(data.company.branchText);
      setCompanyPhone(data.company.phone);
      setCompanyEmail(data.company.email);
      setCompanyTaxId(data.company.taxId);

      setReference(data.reference)
      //Customer
      setName(data.customer.name);

      setPostCode(data.customer.postcode);
      setPhone(data.customer.phone);
      setTaxNumberType(data.customer.taxNumberType);
      setTaxId(data.customer.taxId);
      setBranchText(data.customer.branchText);
      setBranchNumber(data.customer.branchNumber);

      setCountryCode(data.customer.countryCode || 'TH');
      setCountryName(data.customer.countryName || 'ไทย');

      setEmail(data.customer.email);

      setLanguage(data.customer.language);
      setStructure(!data.customer.unstructure);

      if (data.customer.unstructure) {
        setLineOne(data.customer.addressLineOne);
        setLineTwo(data.customer.addressLineTwo);
      } else {
        setBuildingNumber(data.customer.buildingNumber);
        setAddress(data.customer.address);
        setStreetPrefix(data.customer.streetPrefix);
        setStreetName(data.customer.streetName);
        setProvincePrefix(data.customer.provincePrefix);
        setProvince({
          key: data.customer.provinceCode,
          text: data.customer.provinceName,
        });

        setSubDistrictPrefix(data.customer.subDistrictPrefix);
        setSubDistrict({
          key: data.customer.subDistrictCode,
          text: data.customer.subDistrictName,
        });

        setDistrictPrefix(data.customer.districtPrefix);
        setDistrict({
          key: data.customer.districtCode,
          text: data.customer.districtName,
        });
      }

      setDiscount(data.discount);

      let _items = [];
      let nonVatPrice = 0;
      let zeroVatPrice = 0;
      let vatPrice = 0;

      data.items.forEach((item) => {
        if (item.percentVat === nonVat) {
          nonVatPrice += item.total;
        } else if (item.percentVat === zeroVat) {
          zeroVatPrice += item.total;
        } else {
          vatPrice += item.total;
        }

        let _percentVat = '';
        let _percentVatText = '';

        if (item.percentVat === zeroVat) {
          _percentVat = item.percentVat;
          _percentVatText = zeroVatText;
        } else if (item.percentVat === nonVat) {
          _percentVat = item.percentVat;
          _percentVatText = nonVatText;
        } else {
          _percentVat = percentVat;
          _percentVatText = percentVatText;
        }

        _items.push({
          number: item.number,
          description: item.description,
          quantity: item.quantity,
          unitCode: item.unitCode,
          unitName: item.unitName,
          price: item.price,
          discount: item.discount,
          percentVat: _percentVat,
          percentVatText: _percentVatText,
          total: item.total,
          sku: item.sku ? item.sku : ''
        });
      });

      setVatPriceTotal(vatPrice);
      setZeroVatPriceTotal(zeroVatPrice);
      setNonVatPriceTotal(nonVatPrice);

      setItems(_items);

      setAmountTotal(data.amountTotal);
      setVatTotal(data.vatTotal);
      setGrandTotal(data.grandTotal);

      setGrandTotalEN(data.grandTotalEN);
      setGrandTotalTH(data.grandTotalTH);

      setNote(data.note);
      setReceivedBy(data.receivedBy);

      if (data.backUrl) {
        setBackUrl(data.backUrl);
      }

      setIncludeVat(data.includeVat);

      if (data.extraAttributes) {
        setExtraAttributes(data.extraAttributes)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);

    let vatTypeArray = [];

    items.forEach((item) => {
      if (!vatTypeArray.includes(item.percentVat)) {
        vatTypeArray.push(item.percentVat);
      }
      if (vatTypeArray.length > 1) {
        setDiscountDisable(true);
        setDiscount(0);
      } else {
        setDiscountDisable(false);
      }
    });

    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  useEffect(() => {
    console.log('useEffect items changed!: ', items);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [includeVat]);

  useEffect(() => {
    console.log('useEffect special discount changed!: ', discount);
    calAmountTotal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discount]);

  const handleBranch = (branchNum) => {
    console.log('Change branch:', branchNum);
    if (branchNum === '00000') {
      setBranchNumber('00000');
    } else {
      if (branchNum === 'branchNumber') {
        setBranchNumber('00000');
      } else {
        setBranchNumber(branchNum);
      }
    }
  };

  const handleChangeItem = (index, event, selectedOption) => {
    console.log('Change index:', index);
    const values = [...items];
    if (selectedOption) {
      if (selectedOption.id === 'percentVat') {
        values[index]['percentVat'] = selectedOption.key;
        values[index]['percentVatText'] = selectedOption.text;
      } else {
        values[index]['unitCode'] = selectedOption.key;
        values[index]['unitName'] = selectedOption.text;
      }
    } else {
      if (event.target.name === 'quantity' || event.target.name === 'price' || event.target.name === 'discount') {
        if (Number(event.target.value) >= 9999999999.99) {
          values[index][event.target.name] = 9999999999.99;
        } else {
          values[index][event.target.name] = event.target.value;
        }
      } else {
        values[index][event.target.name] = event.target.value;

        if (event.target.name === 'description') {
          values[index]['sku'] = ''
        }
      }
    }

    setItems(values);
  };

  const handleBlurItem = (index, event) => {
    const values = [...items];
    values[index][event.target.name] = Math.round(values[index][event.target.name] * 100) / 100;
    setItems(values);
    calLineTotal(index);
  };

  const handleAddItem = () => {
    setItems([
      ...items,
      {
        number: '',
        description: '',
        quantity: '',
        unitCode: '',
        unitName: '-',
        price: 0,
        discount: 0,
        percentVat: percentVat,
        percentVatText: percentVatText,
        total: 0,
        sku: ''
      },
    ]);
  };

  const handleRemoveItem = (index) => {
    console.log('Delete index:', index);
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let isCorrect = true;

    if (date === undefined || date === null || date === '') {
      isCorrect = false;
      dateRef.current.focus();
    }

    if (reIssue) {
      if (referDate === undefined || referDate === null || referDate === '') {
        isCorrect = false;
        referDateRef.current.focus();
      }

      if (reason.key === undefined || reason.key === null || reason.key === '') {
        isCorrect = false;
        setReasonErrMsg(DropdownErrMsg);
      }

      switch (reason.key) {
        case 'TIVC01':
          reason.text = t('receiptTaxInvoiceAbb.reason1');
          break;
        case 'TIVC02':
          reason.text = t('receiptTaxInvoiceAbb.reason2');
          break;
        case 'TIVC99':
          reason.text = t('receiptTaxInvoiceAbb.reason3');
          break;
        // no default
      }
    }

    items.forEach((item) => {
      if (item.total < 0) {
        isCorrect = false;
      }
    });

    if (number.includes(",") || number.includes(" ")) {
      isCorrect = false;
    }

    if (number.length > 35) {
      isCorrect = false;
    }

    if (referNumber.length > 35) {
      isCorrect = false;
    }

    if (specificReason.length > 256) {
      isCorrect = false;
    }

    items.forEach((_item => {
      if (_item.number.length > 35) {
        isCorrect = false;
      }

      if (_item.description.length > 256) {
        isCorrect = false;
      }
    }))

    if (note.length > 500) {
      isCorrect = false;
    }

    if (!isCorrect) {
      window.scrollTo(0, 0);

      handleFocusAndBlurAll()
    }

    console.log(
      'Name:',
      name,
      '\nbuildingNumber:',
      buildingNumber,
      '\naddress:',
      address,
      '\nstreetName:',
      streetName,
      '\nprovincePrefix:',
      provincePrefix,
      '\nprovince:',
      province,
      '\ndistrictPrefix:',
      districtPrefix,
      '\ndistrict:',
      district,
      '\nsubDistrictPrefix:',
      subDistrictPrefix,
      '\nsubDistrict:',
      subDistrict,
      '\npostcode:',
      postcode,
      '\nphone:',
      phone,
      '\ntaxId:',
      taxId,
      '\nbranchNumber:',
      branchNumber,
      '\nemail:',
      email
    );

    console.log(
      'number:',
      number,
      '\ndate:',
      date,
      '\nnote:',
      note,
      '\namountTotal:',
      amountTotal,
      '\npercentVat:',
      percentVat,
      '\nvatTotal:',
      vatTotal,
      '\ngrandTotal:',
      grandTotal
    );
    console.log('Items', items);

    if (isCorrect) {
      console.log('Data correct!');

      let _customer = {};

      if (structure) {
        _customer = {
          address: address,
          branchNumber: branchNumber,
          branchText: branchText,
          buildingNumber: buildingNumber,
          streetPrefix: streetPrefix,
          streetName: streetName,
          districtPrefix: districtPrefix,
          districtCode: district.key,
          districtName: district.text,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          provincePrefix: provincePrefix,
          provinceCode: province.key,
          provinceName: province.text,
          subDistrictPrefix: subDistrictPrefix,
          subDistrictCode: subDistrict.key,
          subDistrictName: subDistrict.text,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: false,
        };
      } else {
        _customer = {
          addressLineOne: lineOne,
          addressLineTwo: lineTwo,
          branchNumber: branchNumber,
          branchText: branchText,
          email: email,
          name: name,
          phone: phone,
          phoneCountryCode: '+66',
          postcode: postcode,
          taxId: taxId,
          taxNumberType: taxNumberType,
          language: language,
          countryCode: countryCode,
          countryName: countryName,
          unstructure: true,
        };
      }

      if (!reIssue) {
        history.push({
          pathname: '/etax/documents/receipts-taxinvoices-abb/confirm',
          state: {
            // location state
            amountTotal: amountTotal >= 0 ? amountTotal : 0,
            company: company,
            customer: _customer,
            date: date,
            dateBE: dateBE,
            dateCE: dateCE,
            discount: discount,
            grandTotal: grandTotal >= 0 ? grandTotal : 0,
            grandTotalTH: grandTotalTH,
            grandTotalEN: grandTotalEN,
            nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
            zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
            vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
            items: items,
            note: note,
            number: number,
            percentVat: percentVat,
            receivedBy: receivedBy,
            vatTotal: vatTotal >= 0 ? vatTotal : 0,
            reIssue: reIssue,
            backUrl: backUrl,
            includeVat: includeVat,
            reference: reference,
            ...(extraAttributes ? {extraAttributes: extraAttributes} : {})
          },
        });
      } else {
        history.push({
          pathname: '/etax/documents/receipts-taxinvoices-abb/confirm',
          state: {
            // location state
            amountTotal: amountTotal >= 0 ? amountTotal : 0,
            company: company,
            customer: _customer,
            date: date,
            dateBE: dateBE,
            dateCE: dateCE,
            discount: discount,
            grandTotal: grandTotal >= 0 ? grandTotal : 0,
            grandTotalTH: grandTotalTH,
            grandTotalEN: grandTotalEN,
            nonVatPriceTotal: nonVatPriceTotal >= 0 ? nonVatPriceTotal : 0,
            zeroVatPriceTotal: zeroVatPriceTotal >= 0 ? zeroVatPriceTotal : 0,
            vatPriceTotal: vatPriceTotal >= 0 ? vatPriceTotal : 0,
            items: items,
            note: note,
            number: number,
            percentVat: percentVat,
            receivedBy: receivedBy,
            vatTotal: vatTotal >= 0 ? vatTotal : 0,

            //Cancel and Replace Doc
            reIssue: reIssue,
            refer: {
              number: referNumber,
              date: referDate,
              dateBE: referDateBE,
              dateCE: referDateCE,
              reasonCode: reason.key,
              reasonName: reason.text,
              specificReason: specificReason,
            },
            backUrl: backUrl,
            includeVat: includeVat,
            reference: reference,
            ...(extraAttributes ? {extraAttributes: extraAttributes} : {})
          },
        });
      }
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: backUrl,
      state: {},
    });
  };

  const calLineTotal = (index) => {
    const values = [...items];
    values[index]['total'] = Math.round(values[index]['quantity'] * (values[index]['price'] - values[index]['discount']) * 100) / 100;
    setItems(values);
    calAmountTotal();
  };

  const calAmountTotal = () => {
    let sumVatTotal = 0;
    let sumGrandTotal = 0;

    let sumAmountTotal = 0;
    let nonVatPrice = 0;
    let zeroVatPrice = 0;
    let vatPrice = 0;

    let _zeroVatPriceTotal = 0
    let _vatPriceTotal = 0

    if (includeVat) {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        } else {
          vatPrice += items[i]['total'];
          sumGrandTotal += items[i]['total'];
        }
      }

      if (!discountDisable) {
        sumGrandTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;

      sumVatTotal =
        Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100;
      sumAmountTotal = sumGrandTotal - sumVatTotal;

      sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;

      _zeroVatPriceTotal = zeroVatPrice - Math.round(((zeroVatPrice * zeroVat) / (100 + zeroVat)) * 100) / 100
      _vatPriceTotal = vatPrice - Math.round(((vatPrice * percentVat) / (100 + percentVat)) * 100) / 100

      _zeroVatPriceTotal = Math.round(_zeroVatPriceTotal * 100) / 100;
      _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(_zeroVatPriceTotal);
      setVatPriceTotal(_vatPriceTotal);
    } else {
      for (let i = 0; i < items.length; i++) {
        if (items[i].percentVat === nonVat) {
          nonVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        } else if (items[i].percentVat === zeroVat) {
          zeroVatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        } else {
          vatPrice += items[i]['total'];
          sumAmountTotal += items[i]['total'];
        }
      }

      if (!discountDisable) {
        sumAmountTotal -= discount;
        if (vatPrice !== 0) {
          vatPrice -= discount;
        } else if (zeroVatPrice !== 0) {
          zeroVatPrice -= discount;
        } else {
          nonVatPrice -= discount;
        }
      }

      sumAmountTotal = Math.round(sumAmountTotal * 100) / 100;
      vatPrice = Math.round(vatPrice * 100) / 100;
      nonVatPrice = Math.round(nonVatPrice * 100) / 100;
      zeroVatPrice = Math.round(zeroVatPrice * 100) / 100;

      sumVatTotal = Math.round(((vatPrice * percentVat) / 100) * 100) / 100 + Math.round(((zeroVatPrice * zeroVat) / 100) * 100) / 100;
      sumGrandTotal = sumAmountTotal + sumVatTotal;

      sumGrandTotal = Math.round(sumGrandTotal * 100) / 100;

      setNonVatPriceTotal(nonVatPrice);
      setZeroVatPriceTotal(zeroVatPrice);
      setVatPriceTotal(vatPrice);
    }

    setAmountTotal(sumAmountTotal);
    setVatTotal(sumVatTotal);
    setGrandTotal(sumGrandTotal);

    setGrandTotalEN(numberToTextEn(sumGrandTotal >= 0 ? sumGrandTotal : 0));
    setGrandTotalTH(numberToTextTh(sumGrandTotal >= 0 ? sumGrandTotal : 0));
  };

  const handleReIssue = () => {
    setReIssue(!reIssue);
    console.log('reIssue:', reIssue);

    if (!reIssue) {
      setReferNumber('');
      setReferDate('');
      setReferDate('');
      setReferDateBE('');
      setReferDateCE('');
      setReason({ key: '', text: '' });
      setSpecificReason('');
    }
  };

  const handleReason = (selectedOption) => {
    console.log('Change reason:', selectedOption.key);
    if (selectedOption.key !== 'TIVC99') {
      setSpecificReason('');
      setReason(selectedOption);
      setReasonErrMsg('');
    } else {
      setReason(selectedOption);
      setReasonErrMsg('');
    }
  };

  const getErrorMessageWithMaxLength = (value, maxLength, required, extraError) => {
    if (extraError) return extraError
    if (value.length > maxLength) return t('receiptTaxInvoiceAbb.getErrorMessageExceedMaxLength') + maxLength + t('receiptTaxInvoiceAbb.getErrorMessageExceedMaxLength2');
    if (!required) return ""
    if (value && value !== '0') return ""
    return value && value !== '0' ? '' : t('receiptTaxInvoiceAbb.getErrorMessage');
  };

  const getErrorMessage = (value) => {
    return value && value !== '0' ? '' : t('receiptTaxInvoiceAbb.getErrorMessage');
  };

  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === '10') {
      if (language === 'th') {
        setSubDistrictPrefix('แขวง');
        setDistrictPrefix('เขต');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      }
    } else {
      if (language === 'en') {
        setSubDistrictPrefix('');
        setDistrictPrefix('');
        setProvincePrefix('');
      } else {
        setSubDistrictPrefix('ต.');
        setDistrictPrefix('อ.');
        setProvincePrefix('จ.');
      }
    }
  };

  const updateCompany = (replace, value) => {
    console.log('userEffect updateCompany call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //Check documents spending limit
        axios
          .patch(
            API_URL + '/etax/companies/' + company.id,
            {
              replace: replace,
              value: value,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
              },
            }
          )
          .then(
            (response) => {
              console.log('Company data: ', response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  let itemsNumberRef = useRef(items.slice(0, items.length));
  let itemsDescRef = useRef(items.slice(0, items.length));
  let itemsQtyRef = useRef(items.slice(0, items.length));
  let itemsPriceRef = useRef(items.slice(0, items.length));

  let referReasonRef = useRef(false);

  let refArray = useRef({
    customerName: '',
    lineOneRef: '',
    lineTwoRef: '',
    buildingNumberRef: '',
    addressRef: '',
    streetNameRef: '',
    taxIdRef: '',
    branchNumberRef: '',
    numberRef: '',
    emailRef: '',
    noteRef: '',
    referNumberRef: '',
    referSpecificReasonRef: '',
    previouseAmountRef: '',
  });

  useLayoutEffect(() => {
    itemsNumberRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsDescRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsQtyRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsPriceRef.current.forEach((_ref) => {
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });
  }, [i18n.language]);

  useLayoutEffect(() => {
    Object.keys(refArray.current).forEach((key) => {
      let _ref = refArray.current[key];
      if (_ref?._errorMessage) {
        _ref.focus();
        _ref.blur();
      }
    });

    if (referDateRef.current?.state?.errorMessage) {
      referDateRef.current?._onTextFieldBlur();
    }

    if (referReasonRef.current?.props?.errorMessage) {
      referReasonRef.current?._onDropdownBlur();
    }

    if (i18n.language === "en") {
      setLanguage('en');
      sessionStorage.setItem('address-language', 'en');
    } else {
      setLanguage('th');
      sessionStorage.setItem('address-language', 'th');
    }
  }, [i18n.language]);

  const handleFocusAndBlurAll = () => {
    itemsNumberRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsDescRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsQtyRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    itemsPriceRef.current.forEach((_ref) => {
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });

    Object.keys(refArray.current).forEach((key) => {
      let _ref = refArray.current[key];
      if (_ref) {
        _ref.focus();
        _ref.blur();
      }
    });
  }

  return (
    <form onSubmit={handleSubmit} onKeyDown={(e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    }}>
      <Stack>
        <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('receiptTaxInvoiceAbb.header')}</h2>
        <div
          style={{
            width: '1000px',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 30,
            background: '#ffffff',
            marginLeft: '0px',
            marginTop: '0px',
            boxShadow: theme.effects.elevation4,
          }}
        >
          <Stack horizontal horizontalAlign="space-between" styles={{ root: { height: 110 } }}>
            {companyName ? (
              <div className="ms-Grid-col ms-lg7">
                <h4 style={{ marginBottom: 2, marginTop: 5, fontSize: '16px' }}>
                  {companyName} ({companyBranchText})
                </h4>
                <span>{companyAllAddress}</span>
                <br />
                {t('receiptTaxInvoiceAbb.companyTel')} {companyPhone} {t('receiptTaxInvoiceAbb.companyEmail')} {companyEmail}
                <br />
                {t('receiptTaxInvoiceAbb.companyTaxId')} {companyTaxId}
              </div>
            ) : (
              <div className="ms-Grid-col ms-lg7">
                <Shimmer height="1%" width="50%" />
                <br />
                <Shimmer width="65%" />
                <br />
                <Shimmer width="65%" />
              </div>
            )}

            <div className="ms-Grid-col ms-sm12 ms-lg5" style={{ width: 385 }}>
              <center>
                <h2
                  style={{
                    color: '#0C479D',
                    marginBottom: 5,
                    marginTop: 5,
                    fontSize: '25px',
                  }}
                >
                  {t('receiptTaxInvoiceAbb.documentHeader')}
                </h2>
                <span
                  style={{
                    color: '#696969',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  {t('receiptTaxInvoiceAbb.documentSubHeader')}
                </span>
                <h3>{t('receiptTaxInvoiceAbb.original')}</h3>
              </center>
            </div>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <Stack vertical verticalAlign="end" style={{ width: '700px' }}>
              <Toggle
                label={t('receiptTaxInvoiceAbb.priceIncludeVat')}
                onText={t('receiptTaxInvoiceAbb.on')}
                offText={t('receiptTaxInvoiceAbb.off')}
                checked={includeVat}
                onChange={() => {
                  setIncludeVat(!includeVat);

                  setCompany({
                    ...company,
                    includeVat: !includeVat,
                  });

                  updateCompany('includeVat', !includeVat);
                }}
                style={{ alignItems: 'right' }}
              />
            </Stack>

            <Stack vertical></Stack>

            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="number"
                  label={t('receiptTaxInvoiceAbb.number')}
                  componentRef={(input) => (refArray.current.numberRef = input)}
                  styles={{ root: { width: 150 } }}
                  maxLength={35}
                  onChange={(e) => {
                    setNumber(e.target.value);
                  }}
                  value={number}
                  onGetErrorMessage={(value) => 
                    getErrorMessageWithMaxLength(value, 35, true, 
                      value.includes(",") ? t('receiptTaxInvoiceAbb.getErrorMessageNoComma') : value.includes(" ") ? t('receiptTaxInvoiceAbb.getErrorMessageNoEmptySpace') : "")
                  }
                  validateOnLoad={false}
                  validateOnFocusOut
                  required
                />
              </Stack>
              <Stack horizontal>
                <DatePicker
                  name="date"
                  label={t('receiptTaxInvoiceAbb.date')}
                  allowTextInput={false}
                  className={controlClass.control}
                  firstDayOfWeek={firstDayOfWeek}
                  strings={DayPickerStrings}
                  formatDate={onFormatDate}
                  placeholder={t('receiptTaxInvoiceAbb.pleaseSelectDate')}
                  styles={{ root: { width: 150 } }}
                  onSelectDate={(date) => {
                    setDateBE(
                      `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                    );
                    setDateCE(`${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear());

                    setDate(date.toISOString());
                  }}
                  value={new Date(date)}
                  isRequired={true}
                  componentRef={dateRef}
                />
              </Stack>
              <Stack horizontal style={{ marginTop: '-15px' }}>
                <TextField
                  name="reference"
                  label={t('receiptTaxInvoice.reference')}
                  styles={{ root: { width: 150 } }}
                  maxLength={35}
                  onChange={(e) => {
                    setReference(e.target.value);
                  }}
                  value={reference}
                />
              </Stack>
            </Stack>
          </Stack>

          <div>
            <br />
            <Separator />
            <br />
          </div>

          <Stack horizontal tokens={{ childrenGap: 15 }}>
            <span align="center" style={{ width: '50px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.itemNumber')}
            </span>
            <span align="center" style={{ width: '325px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.item')}
            </span>
            <span align="center" style={{ width: '70px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.quantity')}
            </span>
            <span align="center" style={{ width: '80px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.unit')}
            </span>
            <span align="center" style={{ width: '100px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.unitPrice')}
            </span>
            <span align="center" style={{ width: '80px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.discount')}
            </span>
            <span align="center" style={{ width: '65px', fontSize: '16px' }}>
              {t('receiptTaxInvoiceAbb.itemVat')}
            </span>
            <span align="center" style={{ width: '140px', fontSize: '16px' }}>
              {includeVat ? <span>{t('receiptTaxInvoiceAbb.totalIncludeVat')}</span> : <span>{t('receiptTaxInvoiceAbb.totalBeforeVat')}</span>}
            </span>
          </Stack>
          <br />

          {items.map((item, index) => {
            return (
              <div key={index}>
                <Stack horizontal tokens={{ childrenGap: 15 }}>
                  <TextField
                    name="number"
                    componentRef={(input) => (itemsNumberRef.current[index] = input)}
                    styles={{ root: { width: 50 } }}
                    value={items[index]['number']}
                    maxLength={35}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 35, true)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <div style={{ width: 325 }}>
                    <AutocompleteTextField 
                      componentRef={(input) => (itemsDescRef.current[index] = input)}
                      styles={{
                        suffix: { background: 'none' }
                      }} 
                      name="description" 
                      onChangeFunction={handleChangeItem} 
                      value={items[index]['description']}
                      description={item.sku ? item.sku : ''}
                      items={items} 
                      setState={setItems} 
                      index={index}
                      maxLength={256}
                      onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, true)}
                      validateOnLoad={false}
                      validateOnFocusOut
                    />
                  </div>
                  {/* <TextFieldWithDropDown 
                    name="description" 
                    styles={{
                      root: {
                        width: 325,
                      },
                    }} 
                    index={index} 
                    onChangeFunction={handleChangeItem} 
                    value={items[index]['description']}
                    description={item.sku ? item.sku : ''}
                    items={items}
                    setState={setItems} 
                  /> */}
                  {/* <TextField
                    name="description"
                    componentRef={(input) => (itemsDescRef.current[index] = input)}
                    styles={{ root: { width: 325 } }}
                    value={items[index]['description']}
                    maxLength={256}
                    onChange={(event) => handleChangeItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  /> */}
                  <TextField
                    name="quantity"
                    componentRef={(input) => (itemsQtyRef.current[index] = input)}
                    styles={{ root: { width: 70 } }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['quantity']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    onGetErrorMessage={getErrorMessage}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="unitCode"
                    options={unitList}
                    selectedKey={items[index]['unitCode']}
                    styles={{ root: { width: 80 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="price"
                    styles={{
                      root: { width: 100 },
                    }}
                    componentRef={(input) => (itemsPriceRef.current[index] = input)}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['price']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <TextField
                    name="discount"
                    styles={{
                      root: { width: 80 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={items[index]['discount']}
                    onChange={(event) => {
                      if (event.target.value >= 0) {
                        handleChangeItem(index, event);
                      }
                    }}
                    onBlur={(event) => handleBlurItem(index, event)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <Dropdown
                    name="percentVat"
                    options={[
                      { key: nonVat, text: nonVatText, id: 'percentVat' },
                      { key: zeroVat, text: zeroVatText, id: 'percentVat' },
                      {
                        key: percentVat,
                        text: percentVatText,
                        id: 'percentVat',
                      },
                    ]}
                    selectedKey={items[index]['percentVat']}
                    styles={{ root: { width: 65 } }}
                    onChange={(event, selectedOption) => {
                      console.log(selectedOption);
                      handleChangeItem(index, event, selectedOption);
                    }}
                  />
                  <TextField
                    name="total"
                    styles={{ root: { width: 110 } }}
                    disabled
                    readOnly
                    value={items[index]['total'].toLocaleString(undefined, {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    })}
                    onGetErrorMessage={() => {
                      if (items[index]['total'] < 0) {
                        return t('receiptTaxInvoiceAbb.totalErrorMessage1');
                      }
                    }}
                  />

                  <IconButton iconProps={{ iconName: 'Delete' }} title={t('receiptTaxInvoiceAbb.delete')} onClick={() => handleRemoveItem(index)} />
                </Stack>

                <br />
              </div>
            );
          })}

          <Stack horizontal style={centerItem}>
            <PrimaryButton
              iconProps={{ iconName: 'Add' }}
              style={{ letterSpacing: '1px' }}
              name="addItem"
              text={t('receiptTaxInvoiceAbb.addItem')}
              onClick={() => handleAddItem()}
            />
          </Stack>
          <br />

          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              <Stack horizontal>
                <TextField
                  name="note"
                  label={t('receiptTaxInvoiceAbb.note')}
                  componentRef={(input) => (refArray.current.noteRef = input)}
                  styles={{ root: { width: 450 } }}
                  maxLength={500}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  value={note}
                  onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 500, false)}
                  validateOnLoad={false}
                  validateOnFocusOut
                />
              </Stack>
              <Stack horizontal>
                <br />
              </Stack>
              <Stack horizontal>
                <br />
                <TextField
                  name="receivedBy"
                  label={t('receiptTaxInvoiceAbb.receivedBy')}
                  styles={{ root: { width: 450 } }}
                  maxLength={140}
                  onChange={(e) => {
                    setReceivedBy(e.target.value);
                  }}
                  value={receivedBy}
                />
              </Stack>
              <br />
              <br />
              <Stack horizontal>
                <br />
                <Checkbox
                  label={t('receiptTaxInvoiceAbb.reIssueMessage1')}
                  checked={reIssue}
                  onChange={(e) => {
                    handleReIssue();
                  }}
                />
              </Stack>
            </Stack>
            <Stack horizontalAlign="end">
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('receiptTaxInvoiceAbb.discount')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    name="specialDiscount"
                    styles={{
                      root: { width: 150 },
                    }}
                    type="number"
                    min="0"
                    step=".01"
                    max={9999999999.99}
                    value={discount}
                    onChange={(event) => {
                      if (Number(event.target.value) >= 9999999999.99) {
                        setDiscount(9999999999.99);
                      } else {
                        if (Number(event.target.value) <= 0) {
                          setDiscount(0);
                        } else {
                          setDiscount(event.target.value);
                        }
                      }
                    }}
                    onBlur={(event) => setDiscount(Math.round(event.target.value * 100) / 100)}
                    disabled={discountDisable}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
              <br />

              {includeVat ? (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('receiptTaxInvoiceAbb.amountTotalBeforeVat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
                </Stack>
              ) : (
                <Stack horizontal>
                  <span style={{ width: 178, fontSize: '18px' }}>{t('receiptTaxInvoiceAbb.amountTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {
                    <TextField
                      styles={{ root: { width: 150 } }}
                      disabled
                      readOnly
                      value={
                        amountTotal >= 0
                          ? amountTotal.toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            })
                          : '0.00'
                      }
                    />
                  }
                  <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
                </Stack>
              )}

              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('receiptTaxInvoiceAbb.vat')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      vatTotal >= 0
                        ? vatTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178, fontSize: '18px' }}>{t('receiptTaxInvoiceAbb.grandTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      grandTotal >= 0
                        ? grandTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span style={{ fontSize: '18px' }}>&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
            </Stack>
          </Stack>

          <Stack horizontal horizontalAlign="space-between">
            <Stack vertical>
              {reIssue && (
                <div>
                  <TextField
                    name="referNumber"
                    label={t('receiptTaxInvoiceAbb.referNumber')}
                    componentRef={(input) => (refArray.current.referNumberRef = input)}
                    styles={{ root: { width: 220 } }}
                    maxLength={35}
                    onChange={(e) => {
                      setReferNumber(e.target.value);
                    }}
                    value={referNumber}
                    onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 35, true)}
                    validateOnLoad={false}
                    validateOnFocusOut
                    required
                  />
                  <div>
                    <DatePicker
                      name="referDate"
                      label={t('receiptTaxInvoiceAbb.referDate')}
                      allowTextInput={false}
                      className={controlClass.control}
                      firstDayOfWeek={firstDayOfWeek}
                      strings={DayPickerStrings}
                      formatDate={onFormatDate}
                      placeholder={t('receiptTaxInvoiceAbb.pleaseSelect')}
                      styles={{ root: { width: 220, marginBottom: 0 } }}
                      onSelectDate={(date) => {
                        setReferDateBE(
                          `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + (date.getFullYear() + 543)
                        );
                        setReferDateCE(
                          `${date.getDate()}`.padStart(2, '0') + '/' + `${date.getMonth() + 1}`.padStart(2, '0') + '/' + date.getFullYear()
                        );

                        setReferDate(date.toISOString());
                      }}
                      value={referDate && new Date(referDate)}
                      isRequired={true}
                      componentRef={referDateRef}
                    />
                    {!referDate ? (
                      <p style={{ fontSize: 12, color: 'rgb(164, 38, 44)', margin: 0}}>
                        {t('documents.isRequiredErrorMessage')}
                      </p>
                    ) : null}
                  </div>
                  <Dropdown
                    name="reason"
                    label={t('receiptTaxInvoiceAbb.reason')}
                    placeholder={t('receiptTaxInvoiceAbb.pleaseSelect')}
                    componentRef={referReasonRef}
                    options={reasonList}
                    selectedKey={reason.key}
                    styles={{ root: { width: 250 } }}
                    onChange={(event, selectedOption) => {
                      handleReason(selectedOption);

                      setReasonErrMsg('');
                    }}
                    onBlur={(e) => {
                      console.log(reason.key);
                      if (reason.key === '') {
                        setReasonErrMsg(DropdownErrMsg);
                      } else {
                        setReasonErrMsg('');
                      }
                    }}
                    errorMessage={reasonErrMsg}
                    required
                  />
                  {reason.key === 'TIVC99' && (
                    <TextField
                      name="specificReason"
                      label={t('receiptTaxInvoiceAbb.specificReason')}
                      componentRef={(input) => (refArray.current.referSpecificReasonRef = input)}
                      styles={{ root: { width: 300 } }}
                      maxLength={256}
                      onChange={(e) => {
                        setSpecificReason(e.target.value);
                      }}
                      value={specificReason}
                      onGetErrorMessage={(value) => getErrorMessageWithMaxLength(value, 256, true)}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  )}
                </div>
              )}
            </Stack>

            <Stack horizontalAlign="end">
              <br />
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('receiptTaxInvoiceAbb.nonVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      nonVatPriceTotal >= 0
                        ? nonVatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('receiptTaxInvoiceAbb.zeroVatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      zeroVatPriceTotal >= 0
                        ? zeroVatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
              <br />
              <Stack horizontal>
                <span style={{ width: 178 }}>{t('receiptTaxInvoiceAbb.vatPriceTotal')}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {
                  <TextField
                    styles={{ root: { width: 150 } }}
                    disabled
                    readOnly
                    value={
                      vatPriceTotal >= 0
                        ? vatPriceTotal.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          })
                        : '0.00'
                    }
                  />
                }
                <span>&nbsp;&nbsp;&nbsp;&nbsp;{t('receiptTaxInvoiceAbb.thb')}</span>
              </Stack>
            </Stack>
          </Stack>
          <br />
          <br />
          {companyName && (
            <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
              <PrimaryButton style={{ width: '125px', letterSpacing: '1px' }} text={t('receiptTaxInvoiceAbb.next')} type="submit" value="next" />
              <DefaultButton text={t('receiptTaxInvoiceAbb.cancel')} onClick={handleCancel} />
            </Stack>
          )}
          {!companyName && <Spinner label={t('receiptTaxInvoiceAbb.loading')} labelPosition="right" />}
          <br />
          <br />
        </div>
        <br />
        <br />
      </Stack>
    </form>
  );
};

export default ReceiptTaxInvoiceAbb;
