import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Stack, PrimaryButton, DefaultButton, ProgressIndicator, getTheme } from '@fluentui/react';

import { useMsal, useAccount } from '@azure/msal-react';
import { silentRequest } from '../../../authConfig';

import PdfAllPages from '../../etax/documents/pdf/view/PdfAllPages';

const API_URL = process.env.REACT_APP_API_URL;

//Progress Indicator Setting
const intervalDelay = 100;
const intervalIncrement = 0.01;

const ExpenseNoteConfirm = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const location = useLocation();
  const history = useHistory();

  const { t } = useTranslation();

  const data = location.state;

  if (data === undefined) {
    history.push('/expenses/expense-notes');
  }

  const [percentComplete, setPercentComplete] = useState(0);

  const [url, setUrl] = useState('');

  const companyId = sessionStorage.getItem('companyId') || '';

  const centerItem = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const theme = getTheme();

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('submit click');

    console.log(JSON.stringify(data));

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        if (data.customer.taxId !== 'N/A') {
          let contactData = {};

          if (data.customer.unstructure) {
            contactData = {
              name: data.customer.name,
              addressLineOne: data.customer.addressLineOne,
              addressLineTwo: data.customer.addressLineTwo,
              unstructure: true,
            };
          } else {
            contactData = {
              name: data.customer.name,
              buildingNumber: data.customer.buildingNumber,
              address: data.customer.address,
              streetPrefix: data.customer.streetPrefix,
              streetName: data.customer.streetName,
              provincePrefix: data.customer.provincePrefix,
              provinceCode: data.customer.provinceCode,
              provinceName: data.customer.provinceName,
              districtPrefix: data.customer.districtPrefix,
              districtCode: data.customer.districtCode,
              districtName: data.customer.districtName,
              subDistrictPrefix: data.customer.subDistrictPrefix,
              subDistrictCode: data.customer.subDistrictCode,
              subDistrictName: data.customer.subDistrictName,
              unstructure: false,
            };
          }

          contactData.countryCode = data.customer.countryCode;
          contactData.countryName = data.customer.countryName;
          contactData.postcode = data.customer.postcode;
          contactData.taxNumberType = data.customer.taxNumberType;
          contactData.taxId = data.customer.taxId;
          contactData.branchNumber = data.customer.branchNumber;
          contactData.branchText = data.customer.branchText;
          contactData.email = data.customer.email;
          contactData.phone = data.customer.phone;
          contactData.phoneCountryCode = data.customer.phoneCountryCode;
          contactData.contactName = '';
          contactData.website = '';
          contactData.fax = '';
          contactData.contactPhone = '';
          contactData.contactEmail = '';
          contactData.contactNickName = '';
          contactData.contactPosition = '';
          contactData.contactDepartment = '';
          contactData.payment = {
            accountName: '',
            banks: [
              {
                bankCode: '',
                bankName: '',
                branch: '',
                typeCode: '',
                typeName: '',
                accountNumber: '',
              },
            ],
            note: '',
          };
          contactData.buyer = false;
          contactData.seller = true;
          contactData.note = '';

          if (data.contactSuggestion && Object.keys(data.contactSuggestion).length !== 0 && data.contactSuggestion.taxId === data.customer.taxId) {
            contactData.website = data.contactSuggestion.website;
            contactData.fax = data.contactSuggestion.fax;
            contactData.contactPhone = data.contactSuggestion.contactPhone;
            contactData.contactEmail = data.contactSuggestion.contactEmail;
            contactData.contactNickName = data.contactSuggestion.contactNickName;
            contactData.contactPosition = data.contactSuggestion.contactPosition;
            contactData.contactDepartment = data.contactSuggestion.contactDepartment;
            contactData.payment = data.contactSuggestion.payment;
            contactData.buyer = data.contactSuggestion.buyer;
            contactData.seller = data.contactSuggestion.seller;
            contactData.note = data.contactSuggestion.note;

            if (!data.customer.contactName) {
              contactData.contactName = data.contactSuggestion.contactName;
            }
          }

          axios
            .post(API_URL + '/contacts', contactData, {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            })
            .then(
              (response) => {
                console.log(response);
              },
              (error) => {
                console.log(error);
              }
            );
        }

        axios
          .post(
            API_URL + '/expenses/expense-notes/',
            {
              company: data.company,
              customer: data.customer,
              date: data.date,
              dateBE: data.dateBE,
              dateCE: data.dateCE,
              discount: data.discount,
              amountTotal: data.amountTotal,
              vatTotal: data.vatTotal,
              grandTotal: data.grandTotal,
              grandTotalTH: data.grandTotalTH,
              grandTotalEN: data.grandTotalEN,
              nonVatPriceTotal: data.nonVatPriceTotal,
              zeroVatPriceTotal: data.zeroVatPriceTotal,
              vatPriceTotal: data.vatPriceTotal,
              items: data.items,
              note: data.note,
              number: data.number,
              percentVat: data.percentVat,
              receivedBy: data.receivedBy,
              includeVat: data.includeVat,
              whtTotal: data.whtTotal,
              dueDate: data.dueDate,
              dueDateBE: data.dueDateBE,
              dueDateCE: data.dueDateCE,
              reference: data.reference,
              taxInvoice: data.taxInvoice,
              paidTotal: data.paidTotal,
              paymentList: data.paymentList,
            },
            {
              headers: {
                Authorization: 'Bearer ' + tokenResponse.accessToken,
                'Content-Type': 'application/json',
                'cid': companyId
              },
            }
          )
          .then(
            (response) => {
              console.log(response);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    history.push({
      pathname: '/expenses/all',
      state: {},
    });
  };

  const handleEdit = (e) => {
    e.preventDefault();

    history.push({
      pathname: '/expenses/expense-notes',
      state: {
        command: 'EDIT',
        amountTotal: data.amountTotal,
        company: data.company,
        customer: data.customer,
        date: data.date,
        dateBE: data.dateBE,
        dateCE: data.dateCE,
        discount: data.discount,
        grandTotal: data.grandTotal,
        grandTotalTH: data.grandTotalTH,
        grandTotalEN: data.grandTotalEN,
        nonVatPriceTotal: data.nonVatPriceTotal,
        zeroVatPriceTotal: data.zeroVatPriceTotal,
        vatPriceTotal: data.vatPriceTotal,
        items: data.items,
        note: data.note,
        number: data.number,
        percentVat: data.percentVat,
        receivedBy: data.receivedBy,
        vatTotal: data.vatTotal,
        contactSuggestion: data.contactSuggestion,
        includeVat: data.includeVat,
        backUrl: data.backUrl,
        whtTotal: data.whtTotal,
        dueDate: data.dueDate,
        dueDateBE: data.dueDateBE,
        dueDateCE: data.dueDateCE,
        reference: data.reference,
        taxInvoice: data.taxInvoice,
        paidTotal: data.paidTotal,
        paymentList: data.paymentList,
      },
    });
  };

  useEffect(() => {
    console.log('userEffect Call!');

    console.log('data: ', data);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        try {
          axios
            .post(
              API_URL + '/expenses/expense-notes/pdf-preview',
              {
                amountTotal: data.amountTotal,
                company: data.company,
                customer: data.customer,
                date: data.date,
                dateBE: data.dateBE,
                dateCE: data.dateCE,
                discount: data.discount,
                grandTotal: data.grandTotal,
                grandTotalTH: data.grandTotalTH,
                grandTotalEN: data.grandTotalEN,
                nonVatPriceTotal: data.nonVatPriceTotal,
                zeroVatPriceTotal: data.zeroVatPriceTotal,
                vatPriceTotal: data.vatPriceTotal,
                items: data.items,
                note: data.note,
                number: data.number,
                percentVat: data.percentVat,
                receivedBy: data.receivedBy,
                vatTotal: data.vatTotal,
                includeVat: data.includeVat,
                whtTotal: data.whtTotal,
                dueDate: data.dueDate,
                dueDateBE: data.dueDateBE,
                dueDateCE: data.dueDateCE,
                reference: data.reference,
                taxInvoice: data.taxInvoice,
                paidTotal: data.paidTotal,
                paymentList: data.paymentList,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                  'Content-Type': 'application/json',
                  'cid': companyId
                },
                responseType: 'blob',
              }
            )
            .then((response) => {
              console.log(response);
              setUrl(window.URL.createObjectURL(response.data));
              setPercentComplete(100);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (e) {
          console.log(e.response); // undefined
        }
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (url === '') {
      const id = setInterval(() => {
        setPercentComplete((intervalIncrement + percentComplete) % 1);
      }, intervalDelay);
      return () => {
        clearInterval(id);
      };
    }
  });

  return (
    <Stack
      vertical
      verticalAlign="start"
      style={{
        background: 'rgb(204,204,204)',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      <br />
      <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
        <PrimaryButton text={t('expenseNoteConfirm.confirm')} style={{ width: '125px', letterSpacing: '1px' }} onClick={handleSubmit} />
        <DefaultButton text={t('expenseNoteConfirm.edit')} onClick={handleEdit} />
      </Stack>

      <br />
      {!url ? (
        <Stack horizontal horizontalAlign="center">
            <div
                style={{
                    width: '780px',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 20,
                    marginLeft: '0px',
                    marginRight: '0px',
                    background: '#FFFFFF',
                    boxShadow: theme.effects.elevation4,
                }}
            >
                <ProgressIndicator
                    label={t('expenseNoteConfirm.processing')}
                    description={t('expenseNoteConfirm.processingMessage')}
                    percentComplete={percentComplete}
                    style={centerItem}
                />
                <br />
            </div>
            <br />
          <br />
        </Stack>
      ) : (
        <Stack horizontal horizontalAlign="center">
          <PdfAllPages pdf={url} />
        </Stack>
      )}
      <br />
      <br />
    </Stack>
  );
};

export default ExpenseNoteConfirm;
