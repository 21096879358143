import React, { useState, useEffect } from "react";

import axios from "axios";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./styles/style.css";
import  "./ThemeSelector.css"; // Import CSS module
import { useTranslation } from "react-i18next";
import { themeQR } from "./ThemeColorsSetting.js";
import qrCode from 'qrcode';
import {
  Button,
  Text,
  makeStyles,
  shorthands,
  Field,
  Textarea,
  Switch,
  typographyStyles,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogContent,
  DialogBody,
  DialogActions,
  CardPreview,
  Spinner,
  Skeleton,
  SkeletonItem,
  tokens,
  MenuButton,
} from "@fluentui/react-components";
import { DocumentCopyRegular } from '@fluentui/react-icons';
import PdfAllPages from "./PdfAllPages.js";
import ThemeSelectorForQrCode from "./ThemeSelectorForQrCode.js";
const API_URL = process.env.REACT_APP_REQUEST_FORM_API_URL;
const WEB_APP_URL = process.env.REACT_APP_REQUEST_FORM_URL;

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
  },
  title: typographyStyles.title2,
  languagechange: typographyStyles.subtitle2,
  labeldata: typographyStyles.body1Stronger,
  variabledata: typographyStyles.body1,
  captiondata: typographyStyles.title3,
  emaildata: typographyStyles.title3,

  firstshade: "var(--firstshade-color)",

  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
    marginBottom: "10px",
  },
  firstRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    gridTemplateColumns: "min-content 80%",
  },

  Paper: {
    maxWidth: "400px",
    height: "500px",
    backgroundColor: "red",
  },
});

const AdminManagementQrCode = ({
  urlID,
  EditConfirm,
  formId,
  EditUnsuccess,
  CopySuccess
}) => {


  const styles = useStyles();

  const qrSize = 250;
  const [logo, setLogo] = useState(null);
  const [logoCurrentDatabase, setLogoCurrentDatabase] = useState(null);

  // const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [topicState, setTopicState] = useState("แบบฟอร์มขอใบกำกับภาษี");
  const [scanQRState, setScanQRState] = useState("สแกน QR CODE");

  const [editorState, setEditorState] = useState("");
  const [editorStateFromServer, setEditorStateFromServer] = useState("");



  const { t } = useTranslation();
  // Example URL to encode into the QR code

  const UrlApp = `${WEB_APP_URL}/${urlID}`;

  // Generate the QR code URL
  // const qrCodeURL = generateQRCodeURL(UrlApp);
const [qrCodeURL, setQRCodeURL] = useState('')
 
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(UrlApp);
      
      CopySuccess()
    } catch (err) {
      console.log(err);
    }
  };

const opts ={
  errorCorrectionLevel: 'H',
  type: 'image/jpeg',
  quality: 0.3,
  margin: 1,
  // color: {
  //   dark:"#010599FF",
  //   light:"#FFBF60FF"
  // }
}

 const handleQr = (webAppUrl) => {
   
    qrCode
      .toDataURL(webAppUrl,opts)
      .then((url) => {
        console.log(url);
        setQRCodeURL(url);
      })
      .catch((err) => {
        console.error(err);
      });
  };



  useEffect(()=>{
    handleQr(UrlApp)
    // eslint-disable-next-line
  },[])

  const [nameFileReady, setNameFileReady] = useState(null);

  const handleFileUpload = async (file, realfile) => {
    if (file) {
      try {
        // setIsLoadComplete(false);

        const formData = new FormData();
        formData.append("CompanyLogo", realfile);

        // Second HTTP request
        // const responseformStorage = await axios.post(
        //   "http://localhost:5004/api/LogoUpload",
        //   formData,
        const responseformStorage = await axios.post(
          `${API_URL}/logos/`,
          formData,

          {
            headers: {
              "Content-Type": "multipart/form-data",
              "fid": formId,
            },
          }
        );

        if (
          responseformStorage.status >= 200 &&
          responseformStorage.status < 300
        ) {
          if (fileCompanyImage === null && realImageFile === null) {
            setLogo(null);
            setLogoCurrentDatabase(null);
            const fileName = "null";
            handleNameUpload(fileCompanyImage, fileName);
          } else {
            setNameFileReady(responseformStorage.data.fileName);
          }

          // setLogoCurrentDatabase(file);
        } else {
          console.error(
            "Upload RealFile failed with status:",
            responseformStorage.status,
            responseformStorage.statusText
          );
        }

        // if (response.status >= 200 && response.status < 300) {
        //   console.log(
        //     "Upload real image success",
        //     responseformStorage.data.body
        //   );
        //   console.log("Upload successful:", response.data);
        //   setLogoCurrentDatabase(file);
        // } else {
        //   console.error(
        //     "Upload failed with status:",
        //     response.status,
        //     response.statusText
        //   );
        // }
      } catch (error) {
        if (error.response) {
          console.error(
            "Error response from server:",
            error.response.status,
            error.response.statusText,
            error.response.data
          );
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error setting up request:", error.message);
        }
      } finally {
        // setIsLoadComplete(true);
      }
    }
  };

  const handleNameUpload = async (file, namefile) => {
    try {
      setThemeLoading(true);
      setLogoLoading(true);
      const patchPayload = {
        replace: "/companyLogo",
        value: namefile,
      };

      // First HTTP request
      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,
      //   patchPayload
      // );
      const response = await axios.patch(
        `${API_URL}/forms/${formId}`,
        patchPayload
      );

      if (response.status >= 200 && response.status < 300) {
        console.log("Upload real image success", response.data);
        console.log("Upload successful:", response.data);
        // setLogoCurrentDatabase(file);
      } else {
        console.error(
          "Upload failed with status:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Error response from server:",
          error.response.status,
          error.response.statusText,
          error.response.data
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error setting up request:", error.message);
      }
    } finally {
      // setThemeLoading(false);
      // setLogoLoading(false);
    }
  };

  useEffect(() => {
    if (nameFileReady !== null) {
      handleNameUpload(fileCompanyImage, nameFileReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameFileReady]);

  const [fileCompanyImage, setFileCompanyImage] = useState(null);
  const [realImageFile, setRealImageFile] = useState(null);
  const handleFileChange = async (e) => {
    setThemeLoading(true);
    setLogoLoading(true);
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = async () => {
      
      const base64String = reader.result; // Use the entire reader.result as the Base64 string
      setLogo(base64String); // Set the Base64 string as the logo in state
      setFileCompanyImage(base64String);
      setThemeLoading(false);
      setLogoLoading(false);
    };
    reader.readAsDataURL(file); // Read the file as a data URL

    setRealImageFile(file);

    // handleFileUpload(fileCompanyImage);
    
   
  };

  const handleRemoveImage = async () => {
    try {
      // setIsLoadComplete(false);
      setLogo(null);

      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,

      //   {
      //     "replace": "/companyLogo",
      //     "value": "null",
      //   }
      // );

      const response = await axios.patch(
        `${API_URL}/forms/${formId}`,

        {
          "replace": "/companyLogo",
          "value": "null",
        }
      );

      // Check the response status
      if (response.status >= 200 && response.status < 300) {
        console.log("Deleted companyLogo successful:", response.data);

        setFileCompanyImage(null);
        setLogo(null);
        setLogoCurrentDatabase(null);
      } else {
        console.error(
          "Deleted failed with status:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status other than 2xx
        console.error(
          "Error response from server:",
          error.response.status,
          error.response.statusText,
          error.response.data
        );
      } else if (error.request) {
        // Request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
      }
    } finally {
      // setIsLoadComplete(true);
    }
  };

  const [responseExampleFromServer, setResponseExampleFromServer] =
    useState(null);



  async function generatePDF(imageUrl, text, logo, selectedPalette) {
    try {
      setLoading(true);
      //http://localhost:5004/api/forms/pdf
      //${API_URL}/forms/pdf
      const response = await axios.post(
        `${API_URL}/forms/pdf`,
        {
          imageUrl,
          text,
          logo,
          qrSize,
          selectedPalette,
          etaxLogoAlready,
          topicState,
          scanQRState,
        },
        {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
            "fid": formId,
          },
        }
      );

     console.log("response data",response)
      // Return the PDF blob
     setResponseExampleFromServer(
        window.URL.createObjectURL(response.data)
      );
    } catch (error) {
      console.error("Error generating PDF:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  }

  const getPDFpaper = (QRCodePIC) => {
    generatePDF(QRCodePIC, editorState, logo, selectedPalette, etaxLogoAlready);
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const [etaxLogoAlready, setEtaxLogoAlready] = useState(true);
  const [etaxLogoAlreadyFromDatabase, setEtaxLogoAlreadyFromDatabase] =
    useState(true);

  const [languagePaper, setLanguagePaper] = useState("");
  const [languagePaperFromDatabase, setlanguagePaperFromDatabase] =
    useState("");

  const CloseEtaxLogo = () => {
    setEtaxLogoAlready((prevState) => !prevState);
  };

  const ChangePaperLanguage = () => {
    if (languagePaper === "th") {
      setLanguagePaper("en");
    } else if (languagePaper === "en") {
      setLanguagePaper("th");
    }
  };

  const setPaperLanguageText = () => {
    if (languagePaper === "th") {
      setTopicState("แบบฟอร์มขอใบกำกับภาษี");
      setScanQRState("สแกน QR CODE");
    } else {
      setTopicState("Request Tax Invoice Form");
      setScanQRState("SCAN QR CODE");
    }
  };

  useEffect(() => {
    setPaperLanguageText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languagePaper]);

  const [loading, setLoading] = useState(true);

  const handlePrint = () => {
    const newWindow = window.open(responseExampleFromServer, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      // Popup blocked, inform user to allow pop-ups
      alert("Please allow pop-ups for this website to print the PDF.");
    }
  };

  // const savedPalette = localStorage.getItem("selectedBackground");
  const defaultPalette = {
    "id": "001",
    "name": "มาตรฐาน",
    "firstshade": "#3174C2",
    "secondshade": "#3174c2",
    "thirdshade": "#c6d6e7",
    "fourthshade": "#c6d6e7",
  };
  const initialPalette = {
    "id": "001",
    "name": "มาตรฐาน",
    "firstshade": "#3174C2",
    "secondshade": "#3174c2",
    "thirdshade": "#c6d6e7",
    "fourthshade": "#c6d6e7",
  };

  const [selectedPalette, setSelectedPalette] = useState(initialPalette);

  const palettesQR = [
    {
      "id": "1",
      "name": "มาตรฐาน",
      "firstshade": "#3174C2",
      "secondshade": "#3174c2",
      "thirdshade": "#c6d6e7",
      "fourthshade": "#c6d6e7",
    },
  ];

  const [themeloading, setThemeLoading] = useState(true);
  const [logoLoading, setLogoLoading] = useState(true);

  const themeGetter = (id) => {
    return themeQR.find((theme) => theme.id === id);
  };

  const fetchLoadResource = async () => {
    setThemeLoading(true);
    setLogoLoading(true);
    try {
      // const response = await axios.get(
      //   `http://localhost:5004/api/forms/${formId}`
      // );

      const response = await axios.get(`${API_URL}/forms/${formId}`);

      if (response.data.companyLogo === "null") {
        setLogo(null);
        setLogoCurrentDatabase(null);
        setThemeLoading(false);
        setLogoLoading(false);
      } else {
        // setLogo(response.data.companyLogo);

        // setLogoCurrentDatabase(response.data.companyLogo);
        // const fid = formId;
        // await handleDownloadImage(response.data.companyLogo, fid);


        const responseImage = await axios.get(`${API_URL}/logos/`, {
        responseType: "blob", // Specify response type as blob
        headers: {
          "fid": formId,
          "filename": response.data.companyLogo,
        },
      });

      if (responseImage.data) {
        const reader = new FileReader();
        reader.readAsDataURL(responseImage.data); // Read blob data as data URL

        reader.onload = () => {
          const base64String = reader.result;

          setLogoCurrentDatabase(base64String);
          setLogo(base64String);
           setThemeLoading(false);
           setLogoLoading(false);
          
        };
      }
      }

      // setPalletesQR(response.data.themeQR);

      if (response.data.setting.qrCode.etaxLogo === true) {
        setEtaxLogoAlready(true);
        setEtaxLogoAlreadyFromDatabase(true);
      } else {
        setEtaxLogoAlready(false);
        setEtaxLogoAlreadyFromDatabase(false);
      }

      if (response.data.setting.qrCode.language === "th") {
        setLanguagePaper("th");
        setlanguagePaperFromDatabase("th");
      } else {
        setLanguagePaper("en");
        setlanguagePaperFromDatabase("en");
      }

      const themecurrent = themeGetter(response.data.setting.qrCode.themeId);
      console.log("theme current", themecurrent);
      applyPalette(response.data.setting.qrCode.themeId, themecurrent);
      applyPaletteFromServer(
        response.data.setting.qrCode.themeId,
        themecurrent
      );
      setEditorState(response.data.setting.qrCode.description);
      setEditorStateFromServer(response.data.setting.qrCode.description);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      // setThemeLoading(false);
      // setLogoLoading(false);
    }
  };

  useEffect(() => {
    if (formId){
      fetchLoadResource();
    }
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId]);

  // const handleDownloadImage = async (fileName) => {
  //   try {
   
  //     // const response = await axios.get(
  //     //   "http://localhost:5004/api/LogoDownLoad",
  //     //   {
  //     //     responseType: "blob", // Specify response type as blob
  //     //     headers: {
  //     //       "fid": formId,
  //     //       "filename": fileName,
  //     //     },
  //     //   }
  //     // );

  //     const response = await axios.get(`${API_URL}/logos/`, {
  //       responseType: "blob", // Specify response type as blob
  //       headers: {
  //         "fid": formId,
  //         "filename": fileName,
  //       },
  //     });

  //     if (response.data) {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(response.data); // Read blob data as data URL

  //       reader.onload = () => {
  //         const base64String = reader.result;

  //         setLogoCurrentDatabase(base64String);
  //         setLogo(base64String);
          
  //       };
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const [setPaperSize, paperSize] = useState("A5");

  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedThemeFromDatabase, setSelectedThemeFromDatabase] =
    useState(null);
  // Function to apply selected palette
  const applyPalette = (themeId, palette) => {
    const { firstshade, secondshade, thirdshade, fourthshade } = palette;

    setSelectedTheme(themeId);

    document.documentElement.style.setProperty(
      "--firstshade-color",
      firstshade
    );
    document.documentElement.style.setProperty(
      "--secondshade-color",
      secondshade
    );
    document.documentElement.style.setProperty(
      "--thirdshade-color",
      thirdshade
    );
    document.documentElement.style.setProperty(
      "--fourthshade-color",
      fourthshade
    );

    setSelectedPalette(palette);

    // localStorage.setItem("selectedBackground", JSON.stringify(palette));
  };

  const applyPaletteFromServer = (themeId, palette) => {
    // const { id } = palette;

    setSelectedThemeFromDatabase(themeId);
  };

  useEffect(() => {
    applyPalette("001", initialPalette);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run only once when the component mounts

  const handleSaveData = async () => {
    try {
      setThemeLoading(true);
      setLogoLoading(true);
      // const response = await axios.patch(
      //   `http://localhost:5004/api/forms/${formId}`,
      //   {
      //     // Ensure the request payload is structured correctly
      //     "replace": "/qrCodeStuff",
      //     "value": {
      //       "themepaper": selectedPalette,
      //       "description": editorState,
      //       "etaxLogoAlready": etaxLogoAlready,
      //       "paperlanguage": thaipaper,
      //     },
      //   }
      // );
      //`${API_URL}/forms/${formId}`

      const response = await axios.patch(`${API_URL}/forms/${formId}`, {
        // Ensure the request payload is structured correctly
        "replace": "/qrCode",
        "value": {
          "themeId": selectedPalette.id,
          "description": editorState,
          "etaxLogo": etaxLogoAlready,
          "language": languagePaper,
        },
      });

      // const responseThemeQR = await axios.patch(`${API_URL}/forms/${formId}`, {
      //   // Ensure the request payload is structured correctly
      //   "replace": "/settingThemeQrId",
      //   "value": selectedPalette.id,
      // });

      if (response.status === 200) {
        setEtaxLogoAlreadyFromDatabase(etaxLogoAlready);
        setSelectedThemeFromDatabase(selectedTheme);
        setEditorStateFromServer(editorState);
        setLogoCurrentDatabase(logo);
        setlanguagePaperFromDatabase(languagePaper);
        EditConfirm();
        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      EditUnsuccess();
      // Handle error: show error message to the user or retry saving
    } finally {
      setThemeLoading(false);
      setLogoLoading(false);
    }
  };

  const handleResetData = async () => {
    setThemeLoading(true);
    setLogoLoading(true);
    try {
      setEditorState("");

      setEditorStateFromServer("");

      setEtaxLogoAlready(true);

      setEtaxLogoAlreadyFromDatabase(true);

      setLogo(null);

      setLogoCurrentDatabase(null);

      setLanguagePaper("th");
      setlanguagePaperFromDatabase("th");

      applyPalette("001", defaultPalette);
      applyPaletteFromServer("001", defaultPalette);

      setFileCompanyImage(null);
      setRealImageFile(null);

      const fileName = "null";
      handleNameUpload(fileCompanyImage, fileName);
      const response = await axios.patch(`${API_URL}/forms/${formId}`, {
        // Ensure the request payload is structured correctly
        "replace": "/qrCode",
        "value": {
          "themeId": "001",
          "description": "",
          "etaxLogo": true,
          "language": "th",
        },
      });

      if (response.status === 200) {
        setEtaxLogoAlreadyFromDatabase(true);
        setSelectedThemeFromDatabase(selectedTheme);
        setEditorState("");
        setEditorStateFromServer("");
        setLogoCurrentDatabase(logo);

        setlanguagePaperFromDatabase("th");

        // Optionally, you can reset the state or show a success message to the user
      } else {
        throw new Error("Failed to save data");
      }
    } catch (error) {
      console.error("Error saving data:", error.message);
      // Handle error: show error message to the user or retry saving
    } finally {
      setThemeLoading(false);
      setLogoLoading(false);
    }
  };

  return (
    <div className="qr-code-generator">
      <div className="left-side">
        <h2> {t("requestFormAdminFormEditor.editPaper")}</h2>
        <div >
          <>
            {/* <div>
              <Switch
                onChange={() => AddingCompanyLogo()}
                label={"เปิดการอัปโหลดโลโก้เพิ่มเติม"}
              ></Switch>
            </div> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginTop: "21px",
              }}
            >
              <Text>{t("requestFormAdminFormEditor.youCanAddLogo")}</Text>
            </div>
            {logoLoading ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  padding: "10px",
                  color: "#555",
                }}
              >
                <div className={styles.invertedWrapper}>
                  <Skeleton>
                    <SkeletonItem className={styles.firstRow} size={20}>
                      <div style={{ display: "flex", width: "100px" }}></div>
                    </SkeletonItem>
                  </Skeleton>
                </div>
              </div>
            ) : (
              <>
                {logo && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "10px",
                      color: "#555",
                    }}
                  >
                    {/* <img
                    src={`data:image/png;base64=${logo}`}
                    alt="Logo"
                    style={{ height: "30px", marginBottom: "25px" }}
                  /> */}

                    <Dialog modalType="modal">
                      <DialogTrigger disableButtonEnhancement>
                        <Button>
                          {t("requestFormAdminFormEditor.deleteLogo")}
                        </Button>
                      </DialogTrigger>

                      <DialogSurface className="fluentui-dialog">
                        <DialogBody className="dialog-body">
                          <DialogTitle>
                            {" "}
                            {t("requestFormAdminFormEditor.confirmLogo")}
                          </DialogTitle>
                          <DialogContent>
                            {" "}
                            {t(
                              "requestFormAdminFormEditor.confirmLogoDeletion"
                            )}
                          </DialogContent>
                          <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="primary"
                                className="button-dialog"
                                onClick={handleRemoveImage}
                              >
                                {t("requestFormAdminFormEditor.confirm")}
                              </Button>
                            </DialogTrigger>

                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="secondary"
                                className="button-dialog"
                              >
                                {t("requestFormAdminFormEditor.cancel")}
                              </Button>
                            </DialogTrigger>
                          </DialogActions>
                        </DialogBody>
                      </DialogSurface>
                    </Dialog>
                  </div>
                )}
                {!logo && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        padding: "20px",
                        color: "#555",
                      
                      }}
                    >
                      {/* <p
                      style={{
                        margin: "10px 0",
                        marginBottom: "25px",

                        fontSize: "15px",
                      }}
                    >
                      อัปโหลดโลโก้
                    </p> */}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        id="fileInput"
                      />
                      <Button
                        appearance="primary"
                        // disabled={!isChecked}
                        iconProps={{ iconName: "Document" }}
                        onClick={() => {
                          document.getElementById("fileInput").click();
                        }}
                      >
                        {t("requestFormAdminFormEditor.uploadLogo")}
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
          </>

          {logoLoading ? (
            <div style={{ marginBottom: "2px" }}>
              <Skeleton>
                <SkeletonItem className={styles.firstRow} size={12}>
                  <div
                    style={{ display: "flex", width: "100px", height: "0.5px" }}
                  ></div>
                </SkeletonItem>
              </Skeleton>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
               
                <Switch
                  checked={etaxLogoAlready}
                  label={t("requestFormAdminFormEditor.toEnableOrDisable")}
                  onChange={() => CloseEtaxLogo()}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Text
                  style={{
                    marginLeft: "5px",

                    marginRight: "5px",

                    color: etaxLogoAlready ? "green" : "red",
                  }}
                >
                  {etaxLogoAlready
                    ? t("requestFormAdminFormEditor.avaliable")
                    : t("requestFormAdminFormEditor.disabled")}
                </Text>
              </div>
            </div>
          )}
          {logoLoading ? (
            <div className={styles.invertedWrapper}>
              <Skeleton>
                <SkeletonItem className={styles.firstRow} size={12}>
                  <div
                    style={{ display: "flex", width: "100px", height: "0.5px"}}
                  ></div>
                </SkeletonItem>
              </Skeleton>
            </div>
          ) : (
            <div style={{ display: "flex" }}>
              <div>
              
                <Switch
                  checked={languagePaper === "th"}
                  label={t("requestFormAdminFormEditor.paperLanguage")}
                  onChange={() => ChangePaperLanguage()}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Text
                  style={{
                    marginLeft: "5px",

                    marginRight: "5px",

                    color: "blue",
                  }}
                >
                  {languagePaper === "th"
                    ? t("requestFormAdminFormEditor.thaiPaper")
                    : t("requestFormAdminFormEditor.englishPaper")}
                </Text>
              </div>

              {languagePaper === "th" ? (
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignContent: "center",

                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {/* <ThailandFlag /> */}
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",

                    justifyContent: "center",
                    alignContent: "center",

                    flexDirection: "column",
                  }}
                >
                  {" "}
                  {/* <EnglandFlag /> */}
                  {/* <EnglishFlag /> */}
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <ThemeSelectorForQrCode
            applyPalette={applyPalette}
            initialPalette={initialPalette}
            selectedTheme={selectedTheme}
            palettesQR={palettesQR}
            // savedPalette={savedPalette}

            themeloading={themeloading}
          />
        </div>
        <div className="wrap-paper" style={{ marginTop: "25px" }}>
          <h3
            style={{
              display: "flex",
              justifyContent: "center",
              height: "15px",
              fontWeight: "bold",
            }}
          >
            <Text> {t("requestFormAdminFormEditor.addDescription")}</Text>
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginBottom: "10px",
             
            }}
          >
            <Textarea
              className="editor-qr"
              value={editorState}
              onChange={(e) => handleEditorStateChange(e.target.value)}
              maxLength={34}
              disabled={logoLoading}
            />
          </div>
         
        </div>
 
 
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          position: "absolute",
          left: "0px",
          bottom: "35px",

          justifyContent: "center",
      
          justifyItems: "center",
       
        }}
      >
        <div
          style={{
            width: "47%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",

              justifyContent: "center",
              alignContent: "center",
              verticalAlign: "center",
            }}
          >
            <Dialog modalType="modal">
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="primary"
                  style={{ marginRight: "10px" }}
                  disabled={logoLoading}
                >
                  {t("requestFormAdminFormEditor.save")}
                </Button>
              </DialogTrigger>

              <DialogSurface className="fluentui-dialog">
                <DialogBody className="dialog-body">
                  <DialogTitle>
                    {" "}
                    {t("requestFormAdminFormEditor.sentData")}
                  </DialogTitle>

                  <DialogContent>
                    {" "}
                    {t("requestFormAdminFormEditor.confirmToSave")}
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button
                        appearance="primary"
                        className="button-dialog"
                        onClick={() => {
                          if (fileCompanyImage !== null) {
                            handleFileUpload(fileCompanyImage, realImageFile);
                          }
                          handleSaveData();
                        }}
                      >
                        {t("requestFormAdminFormEditor.confirm")}
                      </Button>
                    </DialogTrigger>

                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary" className="button-dialog">
                        {t("requestFormAdminFormEditor.cancel")}
                      </Button>
                    </DialogTrigger>
                  </DialogActions>
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>
        </div>

        <div
          style={{
            width: "47%",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "4%",
              marginRight: "30px",
              minWidth: "180px",
            }}
          >
            <Dialog>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  appearance="secondary"
                  onClick={() => getPDFpaper(qrCodeURL)}
                  disabled={
                    logoLoading ||
                    logoCurrentDatabase !== logo ||
                    etaxLogoAlreadyFromDatabase !== etaxLogoAlready ||
                    selectedTheme !== selectedThemeFromDatabase ||
                    editorStateFromServer !== editorState ||
                    languagePaper !== languagePaperFromDatabase
                  }
                >
                  {t("requestFormAdminFormEditor.previewBeforePrinting")}
                </Button>
              </DialogTrigger>
              <DialogSurface className="fluentui-dialogpolicy">
                <DialogBody className="dialog-bodypolicy">
                  <DialogTitle className={styles.languagechange}>
                    {t("requestFormAdminFormEditor.previewPaper")}
                  </DialogTitle>
                  <DialogContent
                    style={
                      !loading
                        ? {}
                        : {
                            overflowX: "hidden",
                            overflowY: "hidden",
                          }
                    }
                  >
                    {loading ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Spinner
                          label={t("requestFormAdminFormEditor.creatingPaper")}
                        />
                      </div>
                    ) : (
                      <CardPreview
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignContent: "center",
                          alignItems: "center",
                          justifyItems: "center",
                        }}
                      >
                        <PdfAllPages pdfBlobUrl={responseExampleFromServer} />
                      </CardPreview>
                    )}
                  </DialogContent>
                  {!loading ? (
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          onClick={() => setResponseExampleFromServer(null)}
                          appearance="secondary"
                        >
                          {t("requestFormAdminFormEditor.cancel")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button appearance="primary" onClick={handlePrint}>
                          {t("requestFormAdminFormEditor.print")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  ) : (
                    ""
                  )}
                </DialogBody>
              </DialogSurface>
            </Dialog>
          </div>

          {!logoLoading &&
            (logoCurrentDatabase !== logo ||
              etaxLogoAlreadyFromDatabase !== etaxLogoAlready ||
              selectedTheme !== selectedThemeFromDatabase ||
              editorStateFromServer !== editorState ||
              languagePaperFromDatabase !== languagePaper) && (
              <div style={{ position: "absolute", top: "35px" }}>
                <Field
                  validationState="error"
                  validationMessage={
                    <Text> {t("requestFormAdminFormEditor.thereIsEdit")}</Text>
                  }
                >
                  {" "}
                </Field>
              </div>
            )}
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
                alignContent: "center",
                verticalAlign: "center",
                minWidth: "170px",
              }}
            >
              <Dialog modalType="modal">
                <DialogTrigger disableButtonEnhancement>
                  <Button
                    appearance="secendary"
                    disabled={logoLoading}
                    // style={{ marginRight: "50px", marginTop: "80px" }}
                  >
                    {t("requestFormAdminFormEditor.resetCodeFormat")}
                  </Button>
                </DialogTrigger>

                <DialogSurface className="fluentui-dialog">
                  <DialogBody className="dialog-body">
                    <DialogTitle> {t("requestFormAdminFormEditor.resetFormatQrCode")}</DialogTitle>
                    <DialogContent>
                      {" "}
                       {t("requestFormAdminFormEditor.areYouWantReset")}
                    </DialogContent>
                    <DialogActions>
                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          appearance="primary"
                          className="button-dialog"
                          onClick={() => {
                            handleResetData();
                          }}
                        >
                          {t("requestFormAdminFormEditor.confirm")}
                        </Button>
                      </DialogTrigger>

                      <DialogTrigger disableButtonEnhancement>
                        <Button
                          appearance="secondary"
                          className="button-dialog"
                        >
                          {t("requestFormAdminFormEditor.cancel")}
                        </Button>
                      </DialogTrigger>
                    </DialogActions>
                  </DialogBody>
                </DialogSurface>
              </Dialog>
            </div>
          </div>

          {/* <div
            style={{
              position: "absolute",
              right: "-50px",
              zIndex: "999",
              backgroundColor: "red",
            }}
          >
        
            
          </div> */}
        </div>
      </div>

      <div className="right-side">
       
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex:"-100"
            }}
            
          />

          <div
            style={{
              opacity: 1,
            }}
            className="font-preview"
          >
            <>
            
              <div style={{ display: "flex" }}>
                <h2
                  size="200"
                  style={{ fontSize: "20px" }}
                  fontWeight="450"
                >
                  {t("requestFormAdminFormEditor.displayPaper")}
                </h2>{" "}
              </div>
            <div style={{maxWidth: "400px",display:"flex",marginBottom:"10px",paddingLeft:"10px",paddingRight:"10px"}} >
              <div style={{ alignItems: "center",width:"86%"}}>
              <input
                  type="text"
                  readOnly
                  value={UrlApp}
                  style={{ padding: 8, border: "1px solid #ccc",alignContent:"center",width:"100%" }}
              ></input>
            
              </div>
              <div style={{width:"14%",display:"flex",justifyContent:"right"}} >
                <div style={{position:"relative"}}>
                <MenuButton
                icon={<DocumentCopyRegular />}
                onClick={copyToClipboard}
                style={{border: "1px solid #ccc",alignItems:"end",height:"34px",zIndex:"1"}}
                // style={{ minWidth: 50, padding: '0 12px',height:"35px" }}
               ></MenuButton>
               </div>
               </div>
              </div>
              
              {logoLoading && themeloading ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    padding: "10px",
                    color: "#555",
                    maxWidth: "400px",
                    
                  }}
                >
                  
                  <div className={styles.invertedWrapper}>
                    <div>
                      <Skeleton>
                        <SkeletonItem
                          style={{
                            maxWidth: "380px",
                            height: "525px",
                          }}
                        >
                          <div
                            style={{
                              width: "400px",
                              height: "400px",
                            }}
                          ></div>
                        </SkeletonItem>
                      </Skeleton>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <div className="style-paper-only">
                      <div
                        style={{
                          width: "380px",
                          height: "13%",
                          position: "relative",
                        }}
                      >
                        {etaxLogoAlready && (
                          <div
                            style={{
                              position: "absolute",
                              left: "-5%",
                              top: "-15px",
                              verticalAlign: "center",
                            }}
                          >
                            <div style={{ marginBottom: "20px" }}>
                              <img
                                src="/images/etaxlogo.png"
                                alt="Logo"
                                style={{
                                  width: "120px",
                                  marginTop: "10px",
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex", height: "5%" }}>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              width: "80%",
                              height: "50%",
                              margin: "auto",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              style={{
                                fontSize: "24px",
                                color: "rgb(0, 0, 0)",
                                fontWeight: "bolder",
                              }}
                            >
                              {topicState}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          height: "67%",
                          padding: "5%",
                        }}
                      >
                        <div
                          style={{
                            width: "90%",
                            height: "120%",
                            backgroundColor: "white",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            zIndex: 1,
                            borderRadius: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              textAlign: "center",
                              width: "100%",
                              height: "100%",
                              borderRadius: "10px",
                            }}
                          >
                            {/* Header */}
                            {logo &&   (
                              <div
                                style={{
                                  width: "100%",
                                  height: "20%",
                                  color: "black",
                                  fontWeight: "bolder",

                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  verticalAlign: "center",
                                  backgroundColor: "var(--firstshade-color)",
                                  marginBottom: "5%",
                                  borderTopLeftRadius: "10px",
                                  borderTopRightRadius: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    verticalAlign: "center",
                                  }}
                                >
                                  <img
                                    src={`data:image/png;base64=${logo}`}
                                    alt="Logo"
                                    style={{ height: "40px" }}
                                  />
                                </div>
                              </div>
                            )}

                            {/* QR Code Container */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "rgb(255, 255, 255)",

                                height: "80%",
                                width: "80%",
                                margin: "auto",
                                marginBottom: "2%",
                              }}
                            >
                              {/* <img src={imageUrl} alt="QR Code" width="100%" height="100%" style={{ paddingTop: '10%' }} /> */}
                              <div>
                                {/* <QRCode
                                // size={qrSize}
                                size={250}
                                bgColor={qrStyle.bgColor}
                                fgColor={`rgba(${qrColor.r}, ${qrColor.g}, ${qrColor.b}, ${qrColor.a})`}
                                level="H"
                                renderAs="svg"
                                value={url}
                              /> */}
                                <img
                                src={qrCodeURL} 
                                alt="QR Code"
                                width="250px"
                                height="250px"
                         
                                ></img>
                             
                          
                                 {/* <img src={qrCodeURL} alt="QR Code" style={{ width: qrSize, height: qrSize }} /> */}
                              </div>
                            </div>
                            <div
                              style={{
                                width: "100%",
                                height: "10%",
                                color: "black",
                                fontSize: "28px",
                                fontWeight: "bolder",
                                borderRadius: "10px",
                                marginBottom: "3px",
                                paddingBottom:"1px"
                              }}
                            >
                              {scanQRState}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          backgroundColor: "var(--firstshade-color)",
                          padding: "12px",

                          height: "17%",
                          position: "relative",
                          
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            backgroundColor: "var(--firstshade-color)",
                            width: "100%",
                            minHeight: "10%",

                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",

                              fontWeight: "bolder",
                              textAlign: "center",
                              color: "white",
                              fontSize: "20px",
                              width: "100%",
                            }}
                          >
                            {editorState ? (
                              <div style={{ marginTop: "10%" }}>
                                {editorState}
                              </div>
                            ) : (
                              <div style={{ marginTop: "10%" }}>
                                {t("requestFormAdminFormEditor.addingHere")}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            right: "20%",
                            bottom: "0px",
                          }}
                        >
                          <div className="footer">
                            <div
                              style={{
                                position: "absolute",
                                right: "-80px",
                                bottom: "0px",
                                display: "flex",
                                width: "150px",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "10px",
                                  marginRight: "10px",
                                }}
                              >
                                <label
                                  style={{
                                    color: "white",
                                  }}
                                >
                                  Powered by
                                </label>
                              </div>
                              <div>
                                <img
                                  src="/images/leceipt-logo-new.png"
                                  alt="Logo"
                                  style={{
                                    height: "25px",
                                    paddingBottom: "1px",
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>

      </div>
    </div>
  );
};

export default AdminManagementQrCode;
