import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { silentRequest } from '../../../authConfig';

import { useMsal, useAccount } from '@azure/msal-react';

import paginate from 'jw-paginate';

import {
  DetailsListLayoutMode,
  ShimmeredDetailsList,
  Selection,
  SelectionMode,
  Spinner,
  SpinnerSize,
  CommandBar,
  Stack,
  Icon,
  Calendar,
  DateRangeType,
  PrimaryButton,
  DefaultButton,
  TextField,
  DocumentCard,
  DocumentCardTitle,
  DocumentCardDetails,
  DocumentCardLogo,
  SearchBox,
  DocumentCardType,
  Text,
  ContextualMenu,
  CheckboxVisibility,
  Toggle,
  Dialog,
  DialogType,
  DialogFooter,
} from '@fluentui/react';

// import { Pagination } from '@uifabric/experiments/lib/Pagination';
import { Pagination } from '@fluentui/react-experiments/lib/Pagination';

import CountUp from 'react-countup';
import axios from 'axios';

var moment = require('moment'); // require
moment().format();


const homeStyles = {
  border: 0,
  margin: 0,
  width: '100%',
  background: '#FAF9F8',
};

const cardStyles = {
  cardStyles: {
    root: {
      background: 'white',
      borderLeft: '5px solid #0078d4',
      width: '30%',
      minWidth: '315px',
      maxWidth: '424px',
      height: '130px',
    },
  },
  header: {
    root: {
      fontSize: 20,
      fontWeight: 'bold',
      paddingTop: '20px',
      textAlign: 'center',
    },
  },
  amount: {
    root: {
      fontSize: 30,
      paddingBottom: '25px',
      textAlign: 'center',
    },
  },
};

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const padLeadingZeros = (num, size) => {
  var s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
};

function JdCentral() {
  const API_URL = process.env.REACT_APP_API_URL;

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const { t, i18n } = useTranslation();

  const [company, setCompany] = useState('');
  const [items, setItems] = useState([]);

  const percentVat = 7;
  const zeroVat = 0;
  // const nonVat = -1;

  const percentVatText = '7%';
  const zeroVatText = '0%';
  // const nonVatText = '-';

  const [totalDocuments, setTotalDocuments] = useState(0);
  const [totalVat, setTotalVat] = useState(0);
  const [totalGrand, setTotalGrand] = useState(0);

  const [documents, setDocuments] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [getNewDocuments, setGetNewDocuments] = useState(false);
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);

  //CommandBars Items
  const [documentType, setDocumentType] = useState({
    key: 0,
    text: t('jdCentral.allType'),
  });

  const [registerVat, setRegisterVat] = useState(true);

  const [includeVat, setIncludeVat] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const [createDocumentStatus, setCreateDocumentStatus] = useState('');

  const isCreateDocument = createDocumentStatus === 'Creating';
  const isCreateDocumentComplete = createDocumentStatus === 'Complete';

  const dateType = { key: 'document', text: 'วันที่บนเอกสาร' };
  const [date, setDate] = useState(new Date());

  const [selectedItems, setSelectedItems] = useState([]);

  const [selection] = useState(
    new Selection({
      onSelectionChanged: () => {
        console.log('isCreateDocument: ', isCreateDocument);
        console.log('isCreateDocumentComplete: ', isCreateDocumentComplete);
        let _selectedItems = selection.getSelection();

        setSelectedItems(_selectedItems);
      },
    })
  );

  const [dialogConfirm, setDialogConfirm] = useState({
    isClick: false,
    header: '',
    message: '',
    onConfirm: null,
    onCancel: null,
  });

  const [primarySort, setPrimarySort] = useState(sessionStorage.getItem('jdCentral-primarySort') || 'createdTime');

  const [isSortedDescendingCreatedTime, setIsSortedDescendingCreatedTime] = useState(() => {
    if (sessionStorage.getItem('jdCentral-sort-createdTime') === 'true') {
      return true;
    } else {
      return false;
    }
  });

  const [isSortedDescendingNumber, setIsSortedDescendingNumber] = useState(() => {
    if (sessionStorage.getItem('jdCentral-sort-number')) {
      if (sessionStorage.getItem('jdCentral-sort-number') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingCustomerName, setIsSortedDescendingCustomerName] = useState(() => {
    if (sessionStorage.getItem('jdCentral-sort-customerName')) {
      if (sessionStorage.getItem('jdCentral-sort-customerName') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const [isSortedDescendingGrandTotal, setIsSortedDescendingGrandTotal] = useState(() => {
    if (sessionStorage.getItem('jdCentral-sort-grandTotal')) {
      if (sessionStorage.getItem('jdCentral-sort-grandTotal') === 'true') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });

  const [prefixNumber, setPrefixNumber] = useState('');
  const [countingNumber, setCountingNumber] = useState('');

  const [showShippingFee, setShowShippingFee] = useState(true);

  const [allDocs, setAllDocs] = useState([]);

  const [search, setSearch] = useState('');

  const isCompactMode = false;

  const DayPickerStrings = {
    months: [
      t('jdCentral.months01'),
      t('jdCentral.months02'),
      t('jdCentral.months03'),
      t('jdCentral.months04'),
      t('jdCentral.months05'),
      t('jdCentral.months06'),
      t('jdCentral.months07'),
      t('jdCentral.months08'),
      t('jdCentral.months09'),
      t('jdCentral.months10'),
      t('jdCentral.months11'),
      t('jdCentral.months12'),
    ],

    shortMonths: [
      t('jdCentral.shortMonths01'),
      t('jdCentral.shortMonths02'),
      t('jdCentral.shortMonths03'),
      t('jdCentral.shortMonths04'),
      t('jdCentral.shortMonths05'),
      t('jdCentral.shortMonths06'),
      t('jdCentral.shortMonths07'),
      t('jdCentral.shortMonths08'),
      t('jdCentral.shortMonths09'),
      t('jdCentral.shortMonths10'),
      t('jdCentral.shortMonths11'),
      t('jdCentral.shortMonths12'),
    ],

    days: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],

    shortDays: ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'],

    goToToday: t('jdCentral.goToToday'),
    prevMonthAriaLabel: 'ไปเดือนที่แล้ว',
    nextMonthAriaLabel: 'ไปเดือนถัดไป',
    prevYearAriaLabel: 'ไปปีที่แล้ว',
    nextYearAriaLabel: 'ไปปีถัดไป',
    closeButtonAriaLabel: 'ปิด',
    monthPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนปี',
    yearPickerHeaderAriaLabel: '{0}, เลือกเพื่อเปลี่ยนเดือน',

    isRequiredErrorMessage: t('jdCentral.isRequiredErrorMessage'),

    invalidInputErrorMessage: 'รูปแบบวันที่ไม่ถูกต้อง',
  };

  const createDocument = async (documentType, item) => {
    let invoiceDetail = await getOrderDetails(item.orderId);

    if (invoiceDetail.invoiceTitle) {
      item['customerName'] = invoiceDetail.invoiceTitle;
    }

    if (invoiceDetail.registeredAddress) {
      item['customerAddress'] = invoiceDetail.registeredAddress;
    }

    if (invoiceDetail.invoiceCode) {
      item['customerTaxId'] = invoiceDetail.invoiceCode;
    } else {
      item['customerTaxId'] = '';
    }

    if (invoiceDetail.officeType === '1' || invoiceDetail.officeType === 'Head Office') {
      item['_branchText'] = 'สำนักงานใหญ่';
      item['_branchNumber'] = '00000';
      item['_taxNumberType'] = 'TXID';
    } else if (invoiceDetail.officeType === '2' || invoiceDetail.officeType === 'Branch') {
      item['_branchText'] = 'สาขาที่';
      item['_branchNumber'] = '';
      item['_taxNumberType'] = 'TXID';
    } else if (invoiceDetail.officeType === '3' || invoiceDetail.officeType === 'Individual') {
      item['_taxNumberType'] = 'NIDN';
    }

    if (invoiceDetail.branchId) {
      item['_branchNumber'] = invoiceDetail.branchId;
    }

    if (invoiceDetail.registeredPhone) {
      item['customerPhone'] = invoiceDetail.registeredPhone;
    } else {
      item['customerPhone'] = '';
    }

    if (invoiceDetail.email) {
      item['customerEmail'] = invoiceDetail.email;
    } else {
      item['customerEmail'] = '';
    }

    if (invoiceDetail.postCode) {
      item['customerPostcode'] = invoiceDetail.postCode;
    }

    let documentPath = '/etax/documents/receipts';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        documentPath = '/etax/documents/receipts-taxinvoices';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        documentPath = '/etax/documents/receipts-taxinvoice-abb';
        break;
      case 'RECEIPT':
        documentPath = '/etax/documents/receipts';
        break;
      case 'INVOICE-TAXINVOICE':
        documentPath = '/etax/documents/invoices-taxinvoices';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        documentPath = '/etax/documents/deliveryorders-taxinvoices';
        break;
      case 'TAXINVOICE':
        documentPath = '/etax/documents/taxinvoices';
        break;
      default:
        break;
    }

    let _date = new Date(item.date);

    let _dateBE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
    let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

    let _taxId = '';
    let _taxNumberType = '';
    let _branchNumber = '';
    let _branchText = '';

    if (item.customerTaxId === '' || !item.customerTaxId) {
      _taxId = 'N/A';
      _taxNumberType = 'OTHR';
    } else {
      _taxId = item.customerTaxId;
      _taxNumberType = item._taxNumberType;
      _branchNumber = item._branchNumber;
      _branchText = item._branchText;
    }

    let _customer = {
      addressLineOne: item.customerAddress,
      addressLineTwo: '',
      branchNumber: _branchNumber,
      branchText: _branchText,
      email: item.customerEmail,
      name: item.customerName,
      phone: item.customerPhone !== '' ? item.customerPhone : '',
      phoneCountryCode: '+66',
      postcode: item.customerPostcode,
      taxId: _taxId,
      taxNumberType: _taxNumberType,
      language: 'th',
      countryCode: 'TH',
      countryName: 'ไทย',
      unstructure: true,
    };

    console.log('_customer: ', _customer);
    console.log(_date);
    console.log(_dateBE);
    console.log(_dateCE);

    history.push({
      pathname: documentPath,
      state: {
        command: 'EDIT',
        amountTotal: item.amountTotal,
        company: {
          ...company,
          includeVat: includeVat,
        },
        customer: _customer,
        date: _date,
        dateBE: _dateBE,
        dateCE: _dateCE,
        grandTotal: item.grandTotal,
        items: item.items,
        note: item.note,
        number: item.number,
        percentVat: percentVat,
        receivedBy: 'JD Central',
        vatTotal: item.vatTotal,
        reIssue: false,
        discount: 0,
        backUrl: '/etax/sources/jdcentral',
        includeVat: includeVat,
      },
    });
  };

  const createSelectedDocuments = async (documentType) => {
    let _selectedItems = selection.getItems();

    let postUrl = '';

    switch (documentType) {
      case 'RECEIPT-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices/pdf-xml';
        break;
      case 'RECEIPT-TAXINVOICE-ABB':
        postUrl = API_URL + '/etax/documents/receipts-taxinvoices-abb/pdf';
        break;
      case 'RECEIPT':
        postUrl = API_URL + '/etax/documents/receipts/pdf-xml';
        break;
      case 'INVOICE-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/invoices-taxinvoices/pdf-xml';
        break;
      case 'DELIVERYORDER-TAXINVOICE':
        postUrl = API_URL + '/etax/documents/deliveryorders-taxinvoices/pdf-xml';
        break;
      case 'TAXINVOICE':
        postUrl = API_URL + '/etax/documents/taxinvoices/pdf-xml';
        break;
      default:
        break;
    }

    if (postUrl) {
      let _countingNumberLength = countingNumber.length;
      let _countingNumber = Number(countingNumber);

      await instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then(async (tokenResponse) => {
          for (let i = 0; i < selectedItems.length; i++) {
            console.log('create selected document: ', selectedItems[i]);

            for (let j = 0; j < _selectedItems.length; j++) {
              if (_selectedItems[j].key === selectedItems[i].key) {
                _selectedItems[j].status = 'processing';
                setItems([..._selectedItems]);

                const responseOrderDetail = await axios.get(API_URL + '/sources/jdcentral/orders/' + selectedItems[i].orderId, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                  params: {
                    orderId: selectedItems[i].orderId,
                  },
                });

                let invoiceDetail = responseOrderDetail.data.invoiceInfoToOpenApi;

                if (invoiceDetail.invoiceTitle) {
                  selectedItems[i]['customerName'] = invoiceDetail.invoiceTitle;
                }

                if (invoiceDetail.registeredAddress) {
                  selectedItems[i]['customerAddress'] = invoiceDetail.registeredAddress;
                }

                if (invoiceDetail.invoiceCode) {
                  selectedItems[i]['customerTaxId'] = invoiceDetail.invoiceCode;
                } else {
                  selectedItems[i]['customerTaxId'] = '';
                }

                if (invoiceDetail.officeType === '1' || invoiceDetail.officeType === 'Head Office') {
                  selectedItems[i]['_branchText'] = 'สำนักงานใหญ่';
                  selectedItems[i]['_branchNumber'] = '00000';
                  selectedItems[i]['_taxNumberType'] = 'TXID';
                } else if (invoiceDetail.officeType === '2' || invoiceDetail.officeType === 'Branch') {
                  selectedItems[i]['_branchText'] = 'สาขาที่';
                  selectedItems[i]['_branchNumber'] = '';
                  selectedItems[i]['_taxNumberType'] = 'TXID';
                } else if (invoiceDetail.officeType === '3' || invoiceDetail.officeType === 'Individual') {
                  selectedItems[i]['_taxNumberType'] = 'NIDN';
                }

                if (invoiceDetail.branchId) {
                  selectedItems[i]['_branchNumber'] = invoiceDetail.branchId;
                }

                if (invoiceDetail.registeredPhone) {
                  selectedItems[i]['customerPhone'] = invoiceDetail.registeredPhone;
                } else {
                  selectedItems[i]['customerPhone'] = '';
                }

                if (invoiceDetail.email) {
                  selectedItems[i]['customerEmail'] = invoiceDetail.email;
                } else {
                  selectedItems[i]['customerEmail'] = '';
                }

                if (invoiceDetail.postCode) {
                  selectedItems[i]['customerPostcode'] = invoiceDetail.postCode;
                }

                let _date = new Date(selectedItems[i].date);

                let _dateBE =
                  `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + (_date.getFullYear() + 543);
                let _dateCE = `${_date.getDate()}`.padStart(2, '0') + '/' + `${_date.getMonth() + 1}`.padStart(2, '0') + '/' + _date.getFullYear();

                let _taxId = '';
                let _taxNumberType = '';
                let _branchNumber = '';
                let _branchText = '';

                if (selectedItems[i].customerTaxId === '' || !selectedItems[i].customerTaxId) {
                  _taxId = 'N/A';
                  _taxNumberType = 'OTHR';
                } else {
                  _taxId = selectedItems[i].customerTaxId;
                  _taxNumberType = selectedItems[i]._taxNumberType;
                  _branchNumber = selectedItems[i]._branchNumber;
                  _branchText = selectedItems[i]._branchText;
                }

                let _customer = {
                  addressLineOne: selectedItems[i].customerAddress,
                  addressLineTwo: '',
                  branchNumber: _branchNumber,
                  branchText: _branchText,
                  email: selectedItems[i].customerEmail,
                  name: selectedItems[i].customerName,
                  phone: selectedItems[i].customerPhone !== '' ? selectedItems[i].customerPhone : '',
                  phoneCountryCode: '+66',
                  postcode: selectedItems[i].customerPostcode,
                  taxId: _taxId,
                  taxNumberType: _taxNumberType,
                  language: 'th',
                  countryCode: 'TH',
                  countryName: 'ไทย',
                  unstructure: true,
                };

                let _number = prefixNumber + padLeadingZeros(Number(_countingNumber), _countingNumberLength);
                _countingNumber = padLeadingZeros(Number(_countingNumber) + 1, _countingNumberLength);
                setCountingNumber(_countingNumber);

                const promise_PDF_XML = await new Promise(async (resolve) => {
                  await axios
                    .post(
                      postUrl,
                      {
                        number: _number,
                        company: {
                          ...company,
                          includeVat: includeVat,
                        },
                        customer: _customer,
                        date: _date,
                        dateBE: _dateBE,
                        dateCE: _dateCE,
                        items: selectedItems[i].items,
                        percentVat: registerVat ? percentVat : 0,
                        discount: 0,
                        amountTotal: selectedItems[i].amountTotal,
                        vatTotal: selectedItems[i].vatTotal,
                        grandTotal: selectedItems[i].grandTotal,
                        nonVatPriceTotal: selectedItems[i].nonVatPriceTotal,
                        zeroVatPriceTotal: selectedItems[i].zeroVatPriceTotal,
                        vatPriceTotal: selectedItems[i].vatPriceTotal,
                        includeVat: includeVat,
                        reIssue: false,
                        receivedBy: 'JD Central',
                        note: 'JD Central Order ID ' + selectedItems[i].orderId,
                      },
                      {
                        headers: {
                          Authorization: 'Bearer ' + tokenResponse.accessToken,
                        },
                      }
                    )
                    .then(
                      async (response) => {
                        console.log('Create document response: ', response);

                        let count = 0;

                        let interval = await setInterval(async () => {
                          await axios
                            .get(`${API_URL}/etax/jobs/${response.data.id}`, {
                              headers: {
                                Authorization: 'Bearer ' + tokenResponse.accessToken,
                                'Content-Type': 'application/json',
                              },
                            })
                            .then((jobsResponse) => {
                              count += 1;
                              console.log('Jobs response: ', j, jobsResponse.data.status, count);

                              if (jobsResponse.data.status === 'complete') {
                                _selectedItems[j].status = 'successful';

                                setItems([..._selectedItems]);

                                clearInterval(interval);

                                resolve(true);
                              } else if (count === 300) {
                                _selectedItems[j].status = 'fail';

                                setItems([..._selectedItems]);

                                clearInterval(interval);

                                resolve(false);
                              }
                            });
                        }, 1000);
                      },
                      (error) => {
                        console.log('Error Create document response: ', error);
                        _selectedItems[j].status = 'fail';
                        setItems([..._selectedItems]);
                        resolve(false);
                      }
                    );
                }); //promise

                Promise.all([promise_PDF_XML]).then((value) => {
                  console.log(i, value);
                });
              } //if
            } //for j
          } //for i
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });

      setCreateDocumentStatus('Complete');
    }

    setCreateDocumentStatus('Complete');
  };

  const registerVatColumns = [
    {
      key: 'column1',
      name: t('jdCentral.createdTime'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: !isSortedDescendingCreatedTime,
      isSortedAscending: isSortedDescendingCreatedTime,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('jdCentral-primarySort', 'createdTime');
        sessionStorage.setItem('jdCentral-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-customerName');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('jdCentral.orderId'),
      fieldName: 'orderId',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('jdCentral-primarySort', 'number');
        sessionStorage.setItem('jdCentral-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-customerName');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.orderId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('jdCentral.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('jdCentral-primarySort', 'customerName');
        sessionStorage.setItem('jdCentral-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('jdCentral.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 180,
      maxWidth: 350,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('jdCentral.vatTotal'),
      fieldName: 'vatTotal',
      minWidth: 60,
      maxWidth: 60,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.vatTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('jdCentral.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('jdCentral-primarySort', 'grandTotal');
        sessionStorage.setItem('jdCentral-sort-grandTotal', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-customerName');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: t('jdCentral.payment'),
      fieldName: 'payment',
      minWidth: 80,
      maxWidth: 90,
      isResizable: true,
      isCollapsible: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.paymentType}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column8',
      name: 'Leceipt',
      fieldName: 'etaxCreated',
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      data: 'string',
      onRender: (item) => {
        if (item.etaxCreated) {
          return (
            <div style={{ textAlign: 'center' }}>
              <Icon iconName="SkypeCircleCheck" style={{ fontSize: '20px', color: 'green' }} />
            </div>
          );
        }
      },
    },
    {
      key: 'column9',
      name: '',
      fieldName: 'download',
      minWidth: 120,
      maxWidth: 120,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('jdCentral.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('jdCentral.processing')} labelPosition="right" style={{ marginLeft: '-35px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('jdCentral.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('jdCentral.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('jdCentral.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('jdCentral.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('jdCentral.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('jdCentral.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('jdCentral.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('jdCentral.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('jdCentral.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: () => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const columns = [
    {
      key: 'column1',
      name: t('jdCentral.createdTime'),
      fieldName: 'createdTime',
      minWidth: 70,
      maxWidth: 125,
      isResizable: true,
      isSorted: primarySort === 'createdTime' ? true : false,
      isSortedDescending: !isSortedDescendingCreatedTime,
      isSortedAscending: isSortedDescendingCreatedTime,
      onColumnClick: (item) => {
        console.log('Sort document createTime!');
        setPrimarySort('createdTime');
        setIsSortedDescendingCreatedTime(!isSortedDescendingCreatedTime);

        sessionStorage.setItem('jdCentral-primarySort', 'createdTime');
        sessionStorage.setItem('jdCentral-sort-createdTime', !isSortedDescendingCreatedTime === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-customerName');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'number',
      onRender: (item) => {
        return <span>{item.createdTime}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column2',
      name: t('jdCentral.orderId'),
      fieldName: 'orderId',
      minWidth: 100,
      maxWidth: 100,
      isRowHeader: true,
      isResizable: true,
      isSorted: primarySort === 'number' ? true : false,
      isSortedDescending: isSortedDescendingNumber,
      isSortedAscending: !isSortedDescendingNumber,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('number');
        setIsSortedDescendingNumber(!isSortedDescendingNumber);

        sessionStorage.setItem('jdCentral-primarySort', 'number');
        sessionStorage.setItem('jdCentral-sort-number', !isSortedDescendingNumber === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-customerName');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.orderId}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column3',
      name: t('jdCentral.customerName'),
      fieldName: 'customerName',
      minWidth: 80,
      maxWidth: 100,
      isResizable: true,
      isSorted: primarySort === 'customerName' ? true : false,
      isSortedDescending: isSortedDescendingCustomerName,
      isSortedAscending: !isSortedDescendingCustomerName,
      onColumnClick: () => {
        console.log('Sort document number!');
        setPrimarySort('customerName');
        setIsSortedDescendingCustomerName(!isSortedDescendingCustomerName);

        sessionStorage.setItem('jdCentral-primarySort', 'customerName');
        sessionStorage.setItem('jdCentral-sort-customerName', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-grandTotal');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerName}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column4',
      name: t('jdCentral.customerAddress'),
      fieldName: 'customerAddress',
      minWidth: 240,
      maxWidth: 410,
      isRowHeader: true,
      isResizable: true,
      isSorted: false,
      isSortedDescending: false,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.customerAddress}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column5',
      name: t('jdCentral.grandTotal'),
      fieldName: 'grandTotal',
      minWidth: 70,
      maxWidth: 70,
      isResizable: true,
      isSorted: primarySort === 'grandTotal' ? true : false,
      isSortedDescending: isSortedDescendingGrandTotal,
      isSortedAscending: !isSortedDescendingGrandTotal,
      onColumnClick: () => {
        setPrimarySort('grandTotal');
        setIsSortedDescendingGrandTotal(!isSortedDescendingGrandTotal);

        sessionStorage.setItem('jdCentral-primarySort', 'grandTotal');
        sessionStorage.setItem('jdCentral-sort-grandTotal', !isSortedDescendingCustomerName === false ? 'false' : 'true');

        sessionStorage.removeItem('jdCentral-sort-createdTime');
        sessionStorage.removeItem('jdCentral-sort-number');
        sessionStorage.removeItem('jdCentral-sort-customerName');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);

        setCreateDocumentStatus('');
        setSelectedItems([]);
      },
      data: 'string',
      onRender: (item) => {
        return (
          <span style={{ display: 'block', textAlign: 'right' }}>
            {item.grandTotal.toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: 'column6',
      name: t('jdCentral.payment'),
      fieldName: 'payment',
      minWidth: 80,
      maxWidth: 90,
      isResizable: true,
      onColumnClick: '',
      data: 'string',
      onRender: (item) => {
        return <span>{item.paymentType}</span>;
      },
      isPadded: true,
    },
    {
      key: 'column7',
      name: '',
      fieldName: 'download',
      minWidth: 90,
      maxWidth: 100,
      isResizable: true,
      data: 'string',
      onColumnClick: '',
      onRender: (item) => {
        if (isCreateDocument || isCreateDocumentComplete) {
          if (item.status === 'pending') {
            return <span style={{ color: '#106EBE' }}>{t('jdCentral.pending')}</span>;
          } else if (item.status === 'processing') {
            return <Spinner label={t('jdCentral.processing')} labelPosition="right" style={{ marginLeft: '-35px' }} />;
          } else if (item.status === 'successful') {
            return <span style={{ color: 'green' }}>{t('jdCentral.success')}</span>;
          } else if (item.status === 'fail') {
            return <span style={{ color: 'red' }}>{t('jdCentral.fail')}</span>;
          }
        } else {
          return (
            <span data-selection-disabled={true}>
              <DefaultButton
                text={t('jdCentral.create')}
                iconProps={{ iconName: 'Add', style: { color: '#106ebe' } }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('jdCentral.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('jdCentral.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT-TAXINVOICE-ABB', item);
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('jdCentral.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('RECEIPT', item);
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('jdCentral.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('INVOICE-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('jdCentral.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('DELIVERYORDER-TAXINVOICE', item);
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('jdCentral.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: (event) => {
                        createDocument('TAXINVOICE', item);
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
            </span>
          );
        }
      },
    },
  ];

  const getMonth = (goBack) => {
    let monthNames = [
      t('jdCentral.months01'),
      t('jdCentral.months02'),
      t('jdCentral.months03'),
      t('jdCentral.months04'),
      t('jdCentral.months05'),
      t('jdCentral.months06'),
      t('jdCentral.months07'),
      t('jdCentral.months08'),
      t('jdCentral.months09'),
      t('jdCentral.months10'),
      t('jdCentral.months11'),
      t('jdCentral.months12'),
    ];

    let d = new Date();

    let adjustDate = new Date(d.getFullYear(), d.getMonth() - goBack, 1);

    let year = adjustDate.getFullYear() + 543;

    return {
      month: adjustDate.getMonth(),
      year: adjustDate.getFullYear(),
      key: adjustDate.getMonth() + 1,
      text: monthNames[adjustDate.getMonth()] + ' ' + year,
    };
  };

  const [queryMonth, setQueryMonth] = useState({
    key: getMonth(0).key,
    text: t('jdCentral.thisMonth'),
  });

  const getFrom = (month, year) => {
    let _firstDay = new Date(year, month, 1);

    let thailand_Time = moment(_firstDay);

    return thailand_Time.format().split('T')[0] + ' ' + thailand_Time.format().split('T')[1].split('+')[0];
  };

  const getTo = (month, year) => {
    let _lastDay = new Date(year, month + 1, 1);

    let thailand_Time = moment(_lastDay).subtract(1, 's');

    return thailand_Time.format().split('T')[0] + ' ' + thailand_Time.format().split('T')[1].split('+')[0];
  };

  const [from, setFrom] = useState(getFrom(getMonth(0).month, getMonth(0).year));
  const [to, setTo] = useState(getTo(getMonth(0).month, getMonth(0).year));

  console.log('from: ', from, 'to: ', to);

  const history = useHistory();

  function getDocumentTypeText(key) {
    if (key === 0) return t('jdCentral.allType');
    if (key === 1) return t('jdCentral.unpaidType');
    if (key === 2) return t('jdCentral.readyToship');
    if (key === 3) return t('jdCentral.shipping');
    if (key === 4) return t('jdCentral.shipped');
    if (key === 5) return t('jdCentral.cancel');
  }

  function getDateText(monthObject) {
    if (monthObject.text === 'เดือนนี้' || monthObject.text === 'This Month') return t('jdCentral.thisMonth');

    let monthNames = [
      t('jdCentral.months01'),
      t('jdCentral.months02'),
      t('jdCentral.months03'),
      t('jdCentral.months04'),
      t('jdCentral.months05'),
      t('jdCentral.months06'),
      t('jdCentral.months07'),
      t('jdCentral.months08'),
      t('jdCentral.months09'),
      t('jdCentral.months10'),
      t('jdCentral.months11'),
      t('jdCentral.months12'),
    ];

    let _monthIndex = Number(monthObject.key.substring(4, 6)) - 1;
    let _year = Number(monthObject.key.substring(0, 4));
    if (i18n.language === 'th') {
      _year = _year + 543;
    }

    return monthNames[_monthIndex] + ' ' + _year;
  }

  const commandBarItems = [
    {
      key: documentType.key,
      text: getDocumentTypeText(documentType.key),
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'ProductList' },
      subMenuProps: {
        items: [
          {
            key: 0,
            text: t('jdCentral.allType'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log(t('jdCentral.allType'));
              setDocumentType({ key: 0, text: t('jdCentral.allType') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 1,
            text: t('jdCentral.unpaidType'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log(t('jdCentral.unpaidType'));
              setDocumentType({ key: 1, text: t('jdCentral.unpaidType') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 2,
            text: t('jdCentral.readyToship'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log(t('jdCentral.readyToship'));
              setDocumentType({ key: 2, text: t('jdCentral.readyToship') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 3,
            text: t('jdCentral.shipping'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log(t('jdCentral.shipping'));
              setDocumentType({ key: 3, text: t('jdCentral.shipping') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 4,
            text: t('jdCentral.shipped'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log(t('jdCentral.shipped'));
              setDocumentType({ key: 4, text: t('jdCentral.shipped') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
          {
            key: 5,
            text: t('jdCentral.cancel'),
            iconProps: { iconName: 'ProductList' },
            onClick: () => {
              console.log('ใบส่งของ/ใบกำกับภาษี');
              setDocumentType({ key: 5, text: t('jdCentral.cancel') });

              setLoadDataComplete(false);
              setItems([]);
              setGetNewDocuments(true);

              setTotalDocuments(0);
              setTotalVat(0);
              setTotalGrand(0);
            },
          },
        ],
      },
    },
    {
      key: queryMonth.key,
      text: getDateText(queryMonth),
      iconProps: { iconName: 'Calendar' },
      subMenuProps: {
        items: [
          {
            key: 'queryMonth',
          },
        ],
        onRenderMenuList: () => {
          return (
            <div style={{ width: 220 }}>
              <Calendar
                onSelectDate={(_date) => {
                  let monthNames = [
                    t('jdCentral.months01'),
                    t('jdCentral.months02'),
                    t('jdCentral.months03'),
                    t('jdCentral.months04'),
                    t('jdCentral.months05'),
                    t('jdCentral.months06'),
                    t('jdCentral.months07'),
                    t('jdCentral.months08'),
                    t('jdCentral.months09'),
                    t('jdCentral.months10'),
                    t('jdCentral.months11'),
                    t('jdCentral.months12'),
                  ];

                  setDate(_date);

                  // sessionStorage.setItem('ms-sales-date', _date.toISOString());

                  let adjustDate = _date;

                  let year = adjustDate.getFullYear() + 543;

                  setQueryMonth({
                    key: adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'),
                    text: monthNames[adjustDate.getMonth()] + ' ' + year,
                  });

                  // sessionStorage.setItem('ms-sales-queryMonth-key', adjustDate.getFullYear() + '' + String(adjustDate.getMonth() + 1).padStart(2, '0'));
                  // sessionStorage.setItem('ms-sales-queryMonth-text', monthNames[adjustDate.getMonth()] + ' ' + year);

                  setFrom(getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  setTo(getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  // sessionStorage.setItem('ms-sales-from', getFrom(adjustDate.getMonth(), adjustDate.getFullYear()));
                  // sessionStorage.setItem('ms-sales-to', getTo(adjustDate.getMonth(), adjustDate.getFullYear()));

                  setLoadDataComplete(false);
                  setItems([]);
                  setGetNewDocuments(true);

                  setCurrentPage(1);

                  setSearch('');

                  setPrimarySort('createdTime');
                  setIsSortedDescendingCreatedTime(false);
                  setIsSortedDescendingNumber(false);
                  setIsSortedDescendingCustomerName(false);
                  // setIsSortedDescendingAmountTotal(false);
                  // setIsSortedDescendingVatTotal(false);
                  setIsSortedDescendingGrandTotal(false);

                  // sessionStorage.setItem('ms-sales-primarySort', 'createdTime');
                  // sessionStorage.setItem('ms-sales-sort-createdTime', 'false');
                  // sessionStorage.setItem('ms-sales-sort-number', 'false');
                  // sessionStorage.setItem('ms-sales-sort-customerName', 'false');
                  // sessionStorage.setItem('ms-sales-sort-amountTotal', 'false');
                  // sessionStorage.setItem('ms-sales-sort-vatTotal', 'false');
                  // sessionStorage.setItem('ms-sales-sort-grandTotal', 'false');
                }}
                isMonthPickerVisible={true}
                highlightSelectedMonth={true}
                isDayPickerVisible={false}
                dateRangeType={DateRangeType.Day}
                autoNavigateOnSelection={false}
                showGoToToday={false}
                value={date}
                strings={DayPickerStrings}
                showSixWeeksByDefault={true}
              />
            </div>
          );
        },
      },
    },
    {
      key: 'refresh',
      text: t('jdCentral.refresh'),
      iconProps: { iconName: 'Refresh' },
      onClick: () => {
        console.log('Refresh');

        setLoadDataComplete(false);
        setDocuments([]);
        setItems([]);
        setGetNewDocuments(true);

        setTotalDocuments(0);
        setTotalVat(0);
        setTotalGrand(0);
      },
    },
  ];

  const overflowItems = [
    {
      key: 'disconnect',
      text: t('jdCentral.disconnect'),
      onClick: () => {
        console.log('JD disconnect!');
        setDialogConfirm((prev) => ({
          ...prev,
          isClick: true,
          header: t('jdCentral.disconnect'),
          message: t('jdCentral.disconnectConfirm'),
          onConfirm: () => {
            disconnect(true);
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            }));
          },
          onCancel: () =>
            setDialogConfirm((prev) => ({
              ...prev,
              isClick: false,
            })),
        }));
      },
      iconProps: { iconName: 'PlugDisconnected' },
    },
  ];

  const disconnect = (_confirm) => {
    let confirm = _confirm;

    if (confirm) {
      setIsLoading(true);

      instance.acquireTokenSilent({ ...silentRequest, account: account }).then(
        (tokenResponse) => {
          axios
            .patch(
              API_URL + '/sources',
              {
                source: 'jdcentral',
                replace: '/disabled',
                value: true,
              },
              {
                headers: {
                  Authorization: 'Bearer ' + tokenResponse.accessToken,
                },
              }
            )
            .then((response) => {
              console.log('disconnect: ', response);

              history.push({
                pathname: '/etax/sources/jdcentral/connect',
                state: {},
              });

              setIsLoading(false);
            })
            .catch((error) => {
              //Acquire token silent failure, and send an interactive request
              console.log(error);
              instance.acquireTokenRedirect(silentRequest);
            });
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  useEffect(() => {
    console.log('userEffect initial call!');

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + '/etax/companies', {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              if (response.data[0]) {
                setCompany(response.data[0]);

                if (response.data[0].registerVat) {
                  setIncludeVat(true);
                  setRegisterVat(true);
                } else {
                  setIncludeVat(false);
                  setRegisterVat(false);
                }

                setGetNewDocuments(true);
              } else {
                history.push({
                  pathname: '/etax/companies/profiles',
                  state: {},
                });
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getOrderDetails(orderId) {
    setIsLoading(true);

    let obj = '';

    await instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then(async (tokenResponse) => {
        await axios
          .get(API_URL + '/sources/jdcentral/orders/' + orderId, {
            headers: {
              Authorization: 'Bearer ' + tokenResponse.accessToken,
            },
            params: {
              orderId: orderId,
            },
          })
          .then(
            function (response) {
              obj = response.data.invoiceInfoToOpenApi;

              console.log('Order detail: ', obj);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });

    return obj;
  }

  useEffect(() => {
    async function fetchData() {
      if (getNewDocuments) {
        console.log('getNewOrder Running!!!');

        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then(async (tokenResponse) => {
            // axios
            //   .get(API_URL + '/sources/jdcentral/orders', {
            //     headers: {
            //       Authorization: 'Bearer ' + tokenResponse.accessToken,
            //     },
            //     params: {
            //       orderStatusType: documentType.key ? documentType.key : null,
            //       orderCreateTimeBegin: from ? from : null,
            //       orderCreateTimeEnd: to ? to : null,
            //     },
            //   })
            //   .then(
            //     function (response) {

            const [response, responseAllDocs] = await Promise.all([
              axios
                .get(API_URL + '/sources/jdcentral/orders', {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                  params: {
                    orderStatusType: documentType.key ? documentType.key : null,
                    orderCreateTimeBegin: from ? from : null,
                    orderCreateTimeEnd: to ? to : null,
                  },
                })
                .catch((error) => console.log(error)),

              axios
                .get(API_URL + '/etax/documents/ALL/all?from=' + from + '&to=' + to + '&date=' + dateType.key, {
                  headers: {
                    Authorization: 'Bearer ' + tokenResponse.accessToken,
                  },
                })
                .catch((error) => console.log(error)),
            ]);

            console.log('JD orders list: ', response.data);

            setAllDocs(responseAllDocs.data);

            let _orderList = response.data.items;

            setTotalDocuments(_orderList.length);
            setPageData(paginate(_orderList.length, currentPage, pageSize, 10));

            setDocuments(_orderList);

            const _docs = [];

            if (_orderList.length > 0) {
              if (primarySort === 'createdTime') {
                if (isSortedDescendingCreatedTime) {
                  _orderList = _orderList.reverse();
                }
              } else if (primarySort === 'number') {
                if (isSortedDescendingNumber) {
                  _orderList.sort((a, b) => {
                    return b.orderId - a.orderId;
                  });
                } else {
                  _orderList.sort((a, b) => {
                    return a.orderId - b.orderId;
                  });
                }
              }
              if (primarySort === 'customerName') {
                if (isSortedDescendingCustomerName) {
                  _orderList.sort((a, b) => {
                    if (b.consigneeName < a.consigneeName) {
                      return -1;
                    }
                    if (b.consigneeName > a.consigneeName) {
                      return 1;
                    }
                    return 0;
                  });
                } else {
                  _orderList.sort((a, b) => {
                    if (a.consigneeName < b.consigneeName) {
                      return -1;
                    }
                    if (a.consigneeName > b.consigneeName) {
                      return 1;
                    }
                    return 0;
                  });
                }
              } else if (primarySort === 'grandTotal') {
                if (isSortedDescendingGrandTotal) {
                  _orderList.sort(function (a, b) {
                    return b.totalAmount - a.totalAmount;
                  });
                } else {
                  _orderList.sort(function (a, b) {
                    return a.totalAmount - b.totalAmount;
                  });
                }
              }

              for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _orderList.length); i++) {
                let createdTime = _orderList[i].orderTime;

                let _items = [];
                let _customerEmail = '';
                let _customerPhone = '';
                let _amountTotal = 0;
                let _vatTotal = 0;
                let _grandTotal = 0;
                let _vatPriceTotal = 0;
                let _zeroVatPriceTotal = 0;
                let _nonVatPriceTotal = 0;

                let _percentVat = percentVat;
                let _percentVatText = percentVatText;

                if (!registerVat) {
                  _percentVat = zeroVat;
                  _percentVatText = zeroVatText;
                }

                _orderList[i].skus.forEach((obj_in_array, index) => {
                  let obj_to_items = {
                    number: index + 1,
                    description: obj_in_array.skuName,
                    quantity: obj_in_array.num,
                    unitCode: '',
                    unitName: '-',
                    price: obj_in_array.price,
                    discount: 0,
                    percentVat: _percentVat,
                    percentVatText: _percentVatText,
                    total: obj_in_array.price * obj_in_array.num,
                  };

                  _items.push(obj_to_items);
                });

                if (showShippingFee) {
                  if (_orderList[i].delivery === 1) {
                    _items.push({
                      number: _items.length + 1 + '',
                      description: 'ค่าขนส่ง',
                      quantity: 1,
                      unitCode: '',
                      unitName: '-',
                      price: _orderList[i].shippingAmount,
                      discount: 0,
                      percentVat: _percentVat,
                      percentVatText: _percentVatText,
                      total: _orderList[i].shippingAmount,
                    });
                  }
                }

                if (registerVat) {
                  if (includeVat) {
                    for (let i = 0; i < _items.length; i++) {
                      _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
                      _grandTotal = _grandTotal + _items[i]['total'];
                    }

                    _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                    _grandTotal = Math.round(_grandTotal * 100) / 100;

                    _vatTotal =
                      Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
                      Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                    _amountTotal = _grandTotal - _vatTotal;
                    _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
                  } else {
                    for (let i = 0; i < _items.length; i++) {
                      _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
                      _amountTotal = _amountTotal + _items[i]['total'];
                    }

                    _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
                    _amountTotal = Math.round(_amountTotal * 100) / 100;

                    _vatTotal =
                      Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
                      Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

                    _grandTotal = _amountTotal + _vatTotal;
                  }
                } else {
                  for (let i = 0; i < _items.length; i++) {
                    _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]['total'];
                    _amountTotal = _amountTotal + _items[i]['total'];
                    _grandTotal = _grandTotal + _items[i]['total'];
                  }
                }

                if (_orderList[i].email && _orderList[i].email !== null && _orderList[i].email !== undefined) {
                  _customerEmail = _orderList[i].email;
                }

                if (_orderList[i].consigneePhone && _orderList[i].consigneePhone !== null && _orderList[i].consigneePhone !== undefined) {
                  _customerPhone = _orderList[i].consigneePhone;
                }

                if (_orderList[i].totalAmount) {
                  if (registerVat) {
                    if (includeVat) {
                      _amountTotal = Math.round((_orderList[i].totalAmount / ((100 + percentVat) / 100)) * 100) / 100;
                      _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
                      _grandTotal = Math.round(_orderList[i].totalAmount * 100) / 100;
                    } else {
                      _amountTotal = Math.round(_orderList[i].totalAmount * 100) / 100;

                      _vatTotal = Math.round(((_amountTotal * percentVat) / (100 + percentVat)) * 100) / 100;

                      _grandTotal = _amountTotal + _vatTotal;
                    }
                  } else {
                    _amountTotal = Math.round(_orderList[i].totalAmount * 100) / 100;

                    _grandTotal = Math.round(_orderList[i].totalAmount * 100) / 100;
                  }
                }

                let paymentType = '';
                if (_orderList[i].paymentType === 4) {
                  paymentType = 'ออนไลน์';
                } else if (_orderList[i].paymentType === 1) {
                  paymentType = 'เก็บเงินปลายทาง';
                }

                _docs.push({
                  key: _orderList[i].orderId,
                  createdTime: createdTime,
                  orderId: _orderList[i].orderId,
                  customerName: _orderList[i].consigneeName,
                  customerAddress: _orderList[i].addrInfo.address,
                  customerPhone: _customerPhone,
                  date: _orderList[i].orderTime,
                  items: _items,
                  amountTotal: _amountTotal,
                  vatTotal: _vatTotal,
                  grandTotal: _grandTotal,
                  nonVatPriceTotal: _nonVatPriceTotal,
                  zeroVatPriceTotal: _zeroVatPriceTotal,
                  vatPriceTotal: _vatPriceTotal,
                  discount: _orderList[i].discount * 1,
                  customerPostcode: _orderList[i].postCode,
                  paymentType: paymentType,
                  customerEmail: _customerEmail,
                  note: _orderList[i].remark,
                  etaxCreated: responseAllDocs.data.some((_allDocs) => _allDocs.data.reference === _orderList[i].orderId),
                });
              }

              let _totalVat = 0;
              let _totalGrand = 0;

              if (registerVat) {
                for (let i = 0; i < _orderList.length; i++) {
                  _totalVat = _totalVat + Math.round(_orderList[i].price * (percentVat / (100 + percentVat)) * 100) / 100;
                  _totalGrand = _totalGrand + _orderList[i].price * 1;
                }
              } else {
                for (let i = 0; i < _orderList.length; i++) {
                  _totalGrand = _totalGrand + _orderList[i].price * 1;
                }
              }

              setTotalVat(_totalVat);
              setTotalGrand(_totalGrand);
            }

            setItems(_docs);

            setLoadDataComplete(true);
            setGetNewDocuments(false);
            //   },
            //   (error) => {
            //     console.log(error);

            //     disconnect(true);
            //   }
            // );
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      }
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNewDocuments]);

  useEffect(() => {
    console.log('this is current page: ', currentPage);

    if (documents.length > 0) {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let createdTime = documents[i].orderTime;

        let _items = [];
        let _customerEmail = '';
        let _customerPhone = '';
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;
        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;

        let _percentVat = percentVat;
        let _percentVatText = percentVatText;

        if (!registerVat) {
          _percentVat = zeroVat;
          _percentVatText = zeroVatText;
        }

        documents[i].skus.forEach((obj_in_array, index) => {
          let obj_to_items = {
            number: index + 1,
            description: obj_in_array.skuName,
            quantity: obj_in_array.num,
            unitCode: '',
            unitName: '-',
            price: obj_in_array.price,
            discount: 0,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
            total: obj_in_array.price * obj_in_array.num,
          };

          _items.push(obj_to_items);
        });

        if (showShippingFee) {
          if (documents[i].delivery === 1) {
            _items.push({
              number: _items.length + 1 + '',
              description: 'ค่าขนส่ง',
              quantity: 1,
              unitCode: '',
              unitName: '-',
              price: documents[i].shippingAmount,
              discount: 0,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: documents[i].shippingAmount,
            });
          }
        }

        if (registerVat) {
          if (includeVat) {
            for (let i = 0; i < _items.length; i++) {
              _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
              _grandTotal = _grandTotal + _items[i]['total'];
            }

            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _vatTotal =
              Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

            _amountTotal = _grandTotal - _vatTotal;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let i = 0; i < _items.length; i++) {
              _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
              _amountTotal = _amountTotal + _items[i]['total'];
            }

            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _vatTotal =
              Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

            _grandTotal = _amountTotal + _vatTotal;
          }
        } else {
          for (let i = 0; i < _items.length; i++) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]['total'];
            _amountTotal = _amountTotal + _items[i]['total'];
            _grandTotal = _grandTotal + _items[i]['total'];
          }
        }

        if (documents[i].email && documents[i].email !== null && documents[i].email !== undefined) {
          _customerEmail = documents[i].email;
        }

        if (documents[i].consigneePhone && documents[i].consigneePhone !== null && documents[i].consigneePhone !== undefined) {
          _customerPhone = documents[i].consigneePhone;
        }

        if (documents[i].totalAmount) {
          if (includeVat) {
            _amountTotal = Math.round((documents[i].totalAmount / ((100 + percentVat) / 100)) * 100) / 100;
            _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
            _grandTotal = Math.round(documents[i].totalAmount * 100) / 100;
          }
        }

        let paymentType = '';
        if (documents[i].paymentType === 4) {
          paymentType = 'ออนไลน์';
        } else if (documents[i].paymentType === 1) {
          paymentType = 'เก็บเงินปลายทาง';
        }

        _docs.push({
          key: documents[i].orderId,
          createdTime: createdTime,
          orderId: documents[i].orderId,
          customerName: documents[i].consigneeName,
          customerAddress: documents[i].addrInfo.address,
          customerPhone: _customerPhone,
          date: documents[i].orderTime,
          items: _items,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          nonVatPriceTotal: _nonVatPriceTotal,
          zeroVatPriceTotal: _zeroVatPriceTotal,
          vatPriceTotal: _vatPriceTotal,
          discount: documents[i].discount * 1,
          customerPostcode: documents[i].postCode,
          paymentType: paymentType,
          customerEmail: _customerEmail,
          note: documents[i].remark,
          etaxCreated: allDocs.some((_allDocs) => _allDocs.data.reference === documents[i].orderId),
        });
      }
      console.log('this is Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    console.log('isCreateDocumentComplete useEffect: ', isCreateDocumentComplete);

    if (loadDataComplete && !isCreateDocument) {
      let _items = selection.getItems();
      console.log('_items: ', _items);

      let _selectedItems = selection.getSelection();

      if (isCreateDocumentComplete) {
        setCreateDocumentStatus('');

        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }
      } else {
        for (let i = 0; i < _items.length; i++) {
          if (_items[i].status !== '') {
            _items[i].status = '';
          }
        }

        for (let i = 0; i < _selectedItems.length; i++) {
          console.log('select item key: ', _selectedItems[i].key);

          for (let j = 0; j < _items.length; j++) {
            if (_items[j].key === _selectedItems[i].key) {
              console.log('set pending item key: ', _items[j].key);
              _items[j].status = 'pending';
              console.log('_items: ', _items);
            }
          }
        }
      }

      setItems(_items);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    if (search !== '') {
      let _filterDocument =
        documents.filter(
          (document) =>
            String(document.orderId).includes(search) || document.consigneeName.includes(search) || String(document.totalAmount).includes(search)
        ) || [];

      const _docs = [];

      if (_filterDocument.length) {
        for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, _filterDocument.length); i++) {
          let createdTime = _filterDocument[i].orderTime;

          let _items = [];
          let _customerEmail = '';
          let _customerPhone = '';
          let _amountTotal = 0;
          let _vatTotal = 0;
          let _grandTotal = 0;
          let _vatPriceTotal = 0;
          let _zeroVatPriceTotal = 0;
          let _nonVatPriceTotal = 0;

          let _percentVat = percentVat;
          let _percentVatText = percentVatText;

          if (!registerVat) {
            _percentVat = zeroVat;
            _percentVatText = zeroVatText;
          }

          _filterDocument[i].skus.forEach((obj_in_array, index) => {
            let obj_to_items = {
              number: index + 1,
              description: obj_in_array.skuName,
              quantity: obj_in_array.num,
              unitCode: '',
              unitName: '-',
              price: obj_in_array.price,
              discount: 0,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: obj_in_array.price * obj_in_array.num,
            };

            _items.push(obj_to_items);
          });

          if (showShippingFee) {
            if (_filterDocument[i].delivery === 1) {
              _items.push({
                number: _items.length + 1 + '',
                description: 'ค่าขนส่ง',
                quantity: 1,
                unitCode: '',
                unitName: '-',
                price: _filterDocument[i].shippingAmount,
                discount: 0,
                percentVat: _percentVat,
                percentVatText: _percentVatText,
                total: _filterDocument[i].shippingAmount,
              });
            }
          }

          if (registerVat) {
            if (includeVat) {
              for (let i = 0; i < _items.length; i++) {
                _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
                _grandTotal = _grandTotal + _items[i]['total'];
              }

              _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
              _grandTotal = Math.round(_grandTotal * 100) / 100;

              _vatTotal =
                Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
                Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

              _amountTotal = _grandTotal - _vatTotal;
              _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
            } else {
              for (let i = 0; i < _items.length; i++) {
                _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
                _amountTotal = _amountTotal + _items[i]['total'];
              }

              _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
              _amountTotal = Math.round(_amountTotal * 100) / 100;

              _vatTotal =
                Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
                Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

              _grandTotal = _amountTotal + _vatTotal;
            }
          } else {
            for (let i = 0; i < _items.length; i++) {
              _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]['total'];
              _amountTotal = _amountTotal + _items[i]['total'];
              _grandTotal = _grandTotal + _items[i]['total'];
            }
          }

          if (_filterDocument[i].email && _filterDocument[i].email !== null && _filterDocument[i].email !== undefined) {
            _customerEmail = _filterDocument[i].email;
          }

          if (_filterDocument[i].consigneePhone && _filterDocument[i].consigneePhone !== null && _filterDocument[i].consigneePhone !== undefined) {
            _customerPhone = _filterDocument[i].consigneePhone;
          }

          if (_filterDocument[i].totalAmount) {
            if (includeVat) {
              _amountTotal = Math.round((_filterDocument[i].totalAmount / ((100 + percentVat) / 100)) * 100) / 100;
              _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
              _grandTotal = Math.round(_filterDocument[i].totalAmount * 100) / 100;
            }
          }

          let paymentType = '';
          if (_filterDocument[i].paymentType === 4) {
            paymentType = 'ออนไลน์';
          } else if (_filterDocument[i].paymentType === 1) {
            paymentType = 'เก็บเงินปลายทาง';
          }

          _docs.push({
            key: _filterDocument[i].orderId,
            createdTime: createdTime,
            orderId: _filterDocument[i].orderId,
            customerName: _filterDocument[i].consigneeName,
            customerAddress: _filterDocument[i].addrInfo.address,
            customerPhone: _customerPhone,
            date: _filterDocument[i].orderTime,
            items: _items,
            amountTotal: _amountTotal,
            vatTotal: _vatTotal,
            grandTotal: _grandTotal,
            nonVatPriceTotal: _nonVatPriceTotal,
            zeroVatPriceTotal: _zeroVatPriceTotal,
            vatPriceTotal: _vatPriceTotal,
            discount: _filterDocument[i].discount * 1,
            customerPostcode: _filterDocument[i].postCode,
            paymentType: paymentType,
            customerEmail: _customerEmail,
            note: _filterDocument[i].remark,
            etaxCreated: allDocs.some((_allDocs) => _allDocs.data.reference === _filterDocument[i].orderId),
          });
        }
        console.log('this is Docs data', _docs);
      }

      setItems(_docs);
    } else {
      const _docs = [];

      for (let i = (currentPage - 1) * pageSize; i < Math.min(currentPage * pageSize, documents.length); i++) {
        let createdTime = documents[i].orderTime;

        let _items = [];
        let _customerEmail = '';
        let _customerPhone = '';
        let _amountTotal = 0;
        let _vatTotal = 0;
        let _grandTotal = 0;
        let _vatPriceTotal = 0;
        let _zeroVatPriceTotal = 0;
        let _nonVatPriceTotal = 0;

        let _percentVat = percentVat;
        let _percentVatText = percentVatText;

        if (!registerVat) {
          _percentVat = zeroVat;
          _percentVatText = zeroVatText;
        }

        documents[i].skus.forEach((obj_in_array, index) => {
          let obj_to_items = {
            number: index + 1,
            description: obj_in_array.skuName,
            quantity: obj_in_array.num,
            unitCode: '',
            unitName: '-',
            price: obj_in_array.price,
            discount: 0,
            percentVat: _percentVat,
            percentVatText: _percentVatText,
            total: obj_in_array.price * obj_in_array.num,
          };

          _items.push(obj_to_items);
        });

        if (showShippingFee) {
          if (documents[i].delivery === 1) {
            _items.push({
              number: _items.length + 1 + '',
              description: 'ค่าขนส่ง',
              quantity: 1,
              unitCode: '',
              unitName: '-',
              price: documents[i].shippingAmount,
              discount: 0,
              percentVat: _percentVat,
              percentVatText: _percentVatText,
              total: documents[i].shippingAmount,
            });
          }
        }

        if (registerVat) {
          if (includeVat) {
            for (let i = 0; i < _items.length; i++) {
              _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
              _grandTotal = _grandTotal + _items[i]['total'];
            }

            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
            _grandTotal = Math.round(_grandTotal * 100) / 100;

            _vatTotal =
              Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

            _amountTotal = _grandTotal - _vatTotal;
            _vatPriceTotal = _vatPriceTotal - Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100;
          } else {
            for (let i = 0; i < _items.length; i++) {
              _vatPriceTotal = _vatPriceTotal + _items[i]['total'];
              _amountTotal = _amountTotal + _items[i]['total'];
            }

            _vatPriceTotal = Math.round(_vatPriceTotal * 100) / 100;
            _amountTotal = Math.round(_amountTotal * 100) / 100;

            _vatTotal =
              Math.round(((_vatPriceTotal * percentVat) / (100 + percentVat)) * 100) / 100 +
              Math.round(((_zeroVatPriceTotal * zeroVat) / (100 + zeroVat)) * 100) / 100;

            _grandTotal = _amountTotal + _vatTotal;
          }
        } else {
          for (let i = 0; i < _items.length; i++) {
            _zeroVatPriceTotal = _zeroVatPriceTotal + _items[i]['total'];
            _amountTotal = _amountTotal + _items[i]['total'];
            _grandTotal = _grandTotal + _items[i]['total'];
          }
        }

        if (documents[i].email && documents[i].email !== null && documents[i].email !== undefined) {
          _customerEmail = documents[i].email;
        }

        if (documents[i].consigneePhone && documents[i].consigneePhone !== null && documents[i].consigneePhone !== undefined) {
          _customerPhone = documents[i].consigneePhone;
        }

        if (documents[i].totalAmount) {
          if (includeVat) {
            _amountTotal = Math.round((documents[i].totalAmount / ((100 + percentVat) / 100)) * 100) / 100;
            _vatTotal = Math.round(_amountTotal * (percentVat / 100) * 100) / 100;
            _grandTotal = Math.round(documents[i].totalAmount * 100) / 100;
          }
        }

        let paymentType = '';
        if (documents[i].paymentType === 4) {
          paymentType = 'ออนไลน์';
        } else if (documents[i].paymentType === 1) {
          paymentType = 'เก็บเงินปลายทาง';
        }

        _docs.push({
          key: documents[i].orderId,
          createdTime: createdTime,
          orderId: documents[i].orderId,
          customerName: documents[i].consigneeName,
          customerAddress: documents[i].addrInfo.address,
          customerPhone: _customerPhone,
          date: documents[i].orderTime,
          items: _items,
          amountTotal: _amountTotal,
          vatTotal: _vatTotal,
          grandTotal: _grandTotal,
          nonVatPriceTotal: _nonVatPriceTotal,
          zeroVatPriceTotal: _zeroVatPriceTotal,
          vatPriceTotal: _vatPriceTotal,
          discount: documents[i].discount * 1,
          customerPostcode: documents[i].postCode,
          paymentType: paymentType,
          customerEmail: _customerEmail,
          note: documents[i].remark,
        });
      }
      console.log('this is Docs data', _docs);

      setItems(_docs);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onPageChange = (selectedPageIndex) => {
    console.log('selectedPageIndex: ', selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  return (
    <div style={homeStyles}>
      {!isLoading ? (
        <div>
          <Stack>
            <center>
              <h2 style={{ marginLeft: '0px', marginTop: '25px' }}>{t('jdCentral.header')}</h2>
            </center>

            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '30px' }}>
              <DocumentCard aria-label={t('jdCentral.orders')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
                <DocumentCardLogo {...{ logoIcon: 'Product' }} title={t('jdCentral.orders')} />
                <DocumentCardDetails>
                  <DocumentCardTitle title={t('jdCentral.orders')} styles={cardStyles.header} />
                  <Text styles={cardStyles.amount}>
                    <CountUp end={totalDocuments} separator="," duration={1} /> {t('jdCentral.orderUnit')}
                  </Text>
                </DocumentCardDetails>
              </DocumentCard>

              {registerVat && (
                <DocumentCard aria-label={t('jdCentral.vat')} styles={cardStyles.cardStyles} type={DocumentCardType.compact}>
                  <DocumentCardLogo {...{ logoIcon: 'Bank' }} title={t('jdCentral.vat')} />
                  <DocumentCardDetails>
                    <DocumentCardTitle title={t('jdCentral.vat')} styles={cardStyles.header} />
                    <Text styles={cardStyles.amount}>
                      <CountUp end={totalVat} separator="," decimals={2} decimal="." duration={1} /> {t('jdCentral.thb')}
                    </Text>
                  </DocumentCardDetails>
                </DocumentCard>
              )}

              <DocumentCard
                aria-label={registerVat ? t('jdCentral.saleIncludeVat') : t('jdCentral.sale')}
                styles={cardStyles.cardStyles}
                type={DocumentCardType.compact}
              >
                <DocumentCardLogo {...{ logoIcon: 'Money' }} title={registerVat ? t('jdCentral.saleIncludeVat') : t('jdCentral.sale')} />
                <DocumentCardDetails>
                  <DocumentCardTitle title={registerVat ? t('jdCentral.saleIncludeVat') : t('jdCentral.sale')} styles={cardStyles.header} />
                  <Text styles={cardStyles.amount}>
                    <CountUp end={totalGrand} separator="," decimals={2} decimal="." duration={1} /> {t('jdCentral.thb')}
                  </Text>
                </DocumentCardDetails>
              </DocumentCard>
            </Stack>
          </Stack>

          <br />
          <Stack horizontal horizontalAlign="center">
            <Toggle
              label={t('jdCentral.displayShipCost')}
              inlineLabel
              onText={t('jdCentral.on')}
              offText={t('jdCentral.off')}
              checked={showShippingFee}
              disabled={!loadDataComplete || isCreateDocument}
              onChange={() => {
                setShowShippingFee(!showShippingFee);

                if (!showShippingFee) {
                  localStorage.setItem('showShippingFee', 'yes');
                } else {
                  localStorage.setItem('showShippingFee', 'no');
                }

                setLoadDataComplete(false);
                setDocuments([]);
                setItems([]);
                setGetNewDocuments(true);

                setTotalDocuments(0);
              }}
            />
          </Stack>

          {(selectedItems.length > 0 || isCreateDocument || isCreateDocumentComplete) && (
            <Stack vertical tokens={{ childrenGap: '10px' }}>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <h3>{t('jdCentral.subHeader')}</h3>
              </Stack>
              <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                <TextField
                  name="prefixNumber"
                  label={t('jdCentral.prefixNumber')}
                  description={t('jdCentral.prefixNumberDescription')}
                  styles={{ root: { width: 200 } }}
                  onChange={(e) => {
                    setPrefixNumber(e.target.value);
                  }}
                  value={prefixNumber}
                  disabled={isCreateDocument}
                />
                <TextField
                  name="countingNumber"
                  label={t('jdCentral.countingNumber')}
                  description={countingNumber.length === 0 ? '' : t('jdCentral.countingNumberDesciption')}
                  styles={{ root: { width: 150 } }}
                  onChange={(e) => {
                    if (!/\D/.test(e.target.value)) {
                      setCountingNumber(e.target.value);
                    }
                  }}
                  value={countingNumber}
                  errorMessage={countingNumber.length === 0 ? t('jdCentral.countingNumberErrorMessage') : ''}
                  disabled={isCreateDocument}
                  required
                />
              </Stack>

              {countingNumber.length > 0 ? (
                <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
                  {!isCreateDocument ? (
                    <h4>
                      {t('jdCentral.startDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  ) : (
                    <h4>
                      {t('jdCentral.endDocumentNumber')} {prefixNumber}
                      {countingNumber}
                    </h4>
                  )}
                </Stack>
              ) : (
                <br />
              )}
            </Stack>
          )}

          {selectedItems.length > 0 && !isCreateDocument && (
            <Stack horizontal horizontalAlign="center" tokens={{ childrenGap: '10px' }}>
              <PrimaryButton
                text={t('jdCentral.createDocument')}
                iconProps={{ iconName: 'Add' }}
                menuProps={{
                  items: [
                    {
                      key: 'RECEIPT-TAXINVOICE',
                      text: t('jdCentral.receipt-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('RECEIPT-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT-TAXINVOICE-ABB',
                      text: t('jdCentral.receipt-taxinvoice-abb'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('RECEIPT-TAXINVOICE-ABB');
                        }
                      },
                    },
                    {
                      key: 'RECEIPT',
                      text: t('jdCentral.receipt'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('RECEIPT');
                        }
                      },
                    },
                    {
                      key: 'INVOICE-TAXINVOICE',
                      text: t('jdCentral.invoice-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('INVOICE-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'DELIVERYORDER-TAXINVOICE',
                      text: t('jdCentral.deliveryorder-taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('DELIVERYORDER-TAXINVOICE');
                        }
                      },
                    },
                    {
                      key: 'TAXINVOICE',
                      text: t('jdCentral.taxinvoice'),
                      iconProps: { iconName: 'PageAdd' },
                      onClick: async (event) => {
                        if (countingNumber.length > 0) {
                          setCreateDocumentStatus('Creating');
                          await createSelectedDocuments('TAXINVOICE');
                        }
                      },
                    },
                  ],
                  shouldFocusOnMount: true,
                  directionalHintFixed: true,
                }}
                // Optional callback to customize menu rendering
                menuAs={(props) => <ContextualMenu {...props} />}
              />
              <DefaultButton
                text={t('jdCentral.cancel')}
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  let _items = selection.getItems();
                  let _selectedItems = selection.getSelection();
                  console.log('selection: ', selection.getSelection());

                  for (let i = 0; i < _selectedItems.length; i++) {
                    console.log('select item key: ', _selectedItems[i].key);
                    _selectedItems[i].status = '';

                    for (let j = 0; j < _items.length; j++) {
                      if (_items[j].key === _selectedItems[i].key) {
                        console.log('item key: ', _items[j].key);
                        _items[j].status = '';
                        _selectedItems[i].status = '';
                      }
                    }
                  }

                  selection.setAllSelected(false);

                  setItems(_items);
                  console.log('selection: ', selection.getSelection());
                }}
              />
              <br />
            </Stack>
          )}

          {isCreateDocument && (
            <div>
              <Spinner label={t('jdCentral.signProcessing')} size={SpinnerSize.large} />
            </div>
          )}

          {isCreateDocumentComplete && (
            <center>
              <PrimaryButton
                text={t('jdCentral.createdDocuments')}
                iconProps={{ iconName: 'Documentation' }}
                onClick={async () => {
                  console.log(selectedItems);
                  history.push({
                    pathname: '/etax/documents/all',
                    state: {},
                  });
                }}
              />
            </center>
          )}

          <br />
          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: '98%',
                minWidth: '800px',
                maxWidth: '1350px',
              }}
            >
              <Stack 
                horizontal 
                styles={{
                  root: {
                    backgroundColor: 'white',
                  },
                }}
                horizontalAlign="space-between"
              >
                <CommandBar
                  items={commandBarItems}
                  overflowItems={overflowItems}
                  styles={{
                    root: {
                      width: 'calc(100vw - 700px)',
                      minWidth: '700px',
                      maxWidth: '900px',
                    },
                  }}
                />
                <Stack
                  horizontal
                  styles={{
                    root: {
                      height: 44,
                    },
                  }}
                  tokens={{ childrenGap: '10px' }}
                >
                  <SearchBox
                    className="ms-borderColor-themePrimary"
                    styles={{
                      root: {
                        marginTop: 6,
                        marginLeft: 6,
                        marginRight: 10,
                        width: 300,
                        fontSize: 13,
                        fontWeight: 0,
                      },
                    }}
                    disabled={!loadDataComplete}
                    placeholder={t('jdCentral.searchBoxPlaceholder')}
                    onChange={(e) => setSearch(e ? e.target.value : '')}
                    onClear={() => setSearch('')}
                    value={search}
                  />
                </Stack>
              </Stack>
              <ShimmeredDetailsList
                items={items || []}
                enableShimmer={!loadDataComplete}
                compact={isCompactMode}
                columns={registerVat ? registerVatColumns : columns}
                selection={selection}
                selectionMode={!loadDataComplete || isCreateDocument ? SelectionMode.none : SelectionMode.multiple}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                checkboxVisibility={CheckboxVisibility.always}
                selectionPreservedOnEmptyClick={true}
              />
            </Stack>
          </Stack>
          <br />
          <center>
            {!(documents.length === 0 || documents.length < pageSize) && (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages}
                onPageChange={onPageChange}
                format
                firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
                previousPageIconProps={{ iconName: 'ChevronLeft' }}
                nextPageIconProps={{ iconName: 'ChevronRight' }}
                lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
              />
            )}

            <br />
            <br />
          </center>
        </div>
      ) : (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner label={t('jdCentral.loading')} labelPosition="down" styles={spinnerStyles} />
            </div>
            <br />
          </Stack>
        </Stack>
      )}
      <Dialog
        hidden={!dialogConfirm.isClick}
        onDismiss={dialogConfirm.onCancel}
        styles={{ main: { minHeight: 0 } }}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: dialogConfirm.header,
          styles: { header: { textAlign: 'center' }, title: { paddingRight: 24 }, subText: { textAlign: 'center' } },
          subText: dialogConfirm.message,
        }}
      >
        <DialogFooter styles={{ actionsRight: { textAlign: 'center' } }}>
          <PrimaryButton onClick={dialogConfirm.onConfirm} text={t('documents.confirm')} />
          <DefaultButton onClick={dialogConfirm.onCancel} text={t('documents.cancel')} />
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default JdCentral;
